import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

function NotFound() {
  return (
    <>
        <Header />
        <section className='page-notFound'>
            <div className='container'>
                <h2>404</h2>
                <p>Page Not Found</p>
            </div>
        </section>
        <Footer />
    </>
  )
}

export default NotFound