import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { editAds, ImageHolder, notFound, offer1, offer2 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import { GetAllFavourite, PostFavouriteDelete } from "../../network/Network";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function Wishlist() {
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [fvtData, setFvtData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  // Get Fvt Data
  useEffect(() => {
    setSpinLoad(true);
    GetAllFavourite(currentPage, Token)
      .then((res) => {
        console.log(res);
        setFvtData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteWishListHandler = (id, index) => {
    console.log(id);
    setSpinLoad(true);
    PostFavouriteDelete(id, Token)
      .then((res) => {
        console.log(res?.data?.data);
        setSpinLoad(false);
        setFvtData((preval) => preval.filter((val) => val?.id != id));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  };
  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
              {/* <p className="subtitle text-white">Profile</p> */}
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>Favourite</h5>
                    </div>
                  </div>

                  {spinLoad ? (
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        {fvtData?.length > 0 ? (
                          <>
                            <table className="table wishlist_table">
                              <tr className="topbar">
                                <th className="sno">S.NO</th>
                                <th className="product">PRODUCT</th>
                                <th className="offers">Price</th>
                                <th className="add_cart">Remove</th>
                              </tr>

                              {fvtData?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="sno">{index + 1}</td>
                                    <td className="product">
                                      <div className="order_product">
                                        <div className="img_box">
                                          <Link
                                            to={
                                              item?.user_post?.is_add_on == "1"
                                                ? `/instant-offer-details/${item?.id}`
                                                : `/product-details/${item?.id}`
                                            }
                                            state={{ data: item?.user_post }}
                                          >
                                            <figure>
                                              {item?.user_post?.images.length >
                                              0 ? (
                                                <img
                                                  src={`${ImageUrl?.car_listing}/${item?.user_post?.images[0]?.image}`}
                                                  alt=""
                                                />
                                              ) : (
                                                <img src={ImageHolder} alt="" />
                                              )}
                                            </figure>
                                          </Link>
                                        </div>
                                        <div className="order_content">
                                          <h5 className="order-name">
                                            {item?.user_post?.car_make?.title}
                                            {""}
                                            {item?.user_post?.model?.title}
                                          </h5>
                                          {item?.user_post?.is_add_on == "1" ? (
                                            <p className="offer_type">
                                              Offer Type:{" "}
                                              <span className="type">
                                                Instant Offer
                                              </span>
                                            </p>
                                          ) : null}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="offers">
                                      £ {item?.user_post?.price}
                                    </td>
                                    <td className="add_cart">
                                      <div
                                        className="deleteBtn"
                                        onClick={() =>
                                          DeleteWishListHandler(item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}

                              {/* <tr>
                                    <td className="sno">2</td>
                                    <td className="product">
                                        <div className="order_product">
                                            <div className="img_box">
                                                <figure><img src={offer2} alt="" /></figure>
                                            </div>
                                            <div className="order_content">
                                                <h5 className="order-name">Black Shirt</h5>
                                                <p className="order_type">Offer Type: <span className="type">Instant
                                                        Offer</span></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="offers">£ 60</td>
                                    <td className="add_cart">
                                        <a href="#" className="cart_btn">Add to Cart</a>
                                    </td>
                                </tr> */}
                            </table>
                          </>
                        ) : (
                          <div className="notFound-container">
                            <img
                              src={notFound}
                              alt="not found"
                              className="image-fluid"
                            />
                            <p className="mt-4">No Record Found</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="pagination-container mt-5">
                    <ReactPaginate
                      previousLabel="<<"
                      nextLabel=">>"
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Wishlist;
