import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { banner, plybtn, privacy } from '../../constant'
import '../../assets/css/PrivacyPolicy.css'
import { useSelector } from 'react-redux'

function PrivacyPolicy() {
    const PrivacyData = useSelector((state) => state.AuthReducer.pagesData);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
   <>
    <Header />
    <section className="Slidebanner">
    <div className="banner">
        <div className="slides">
            <div className="img-box">
                <figure><img src={privacy } alt="123" /></figure>
            </div>
            <div className="ply-btn">
                <figure><a href="#"><img src={plybtn} alt="123" /></a></figure>
            </div>
            <div className="title">
                <h2>Lamborghini<br /> Showcase</h2>
            </div>
        </div>
    </div>
</section>

<section className="privacy-policy py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-sm-12">
                <h1 className="text-center pb-5">Privacy Policy</h1>
                {
                PrivacyData?.map((item,index)=>{
                    return(
                        <>
                            {
                                item?.type === "privacy_policy" ? (
                                    <>
                                        <p 
                                          dangerouslySetInnerHTML={{ __html: item?.value }}
                                            key={index}
                                        />
                                   
                                    </>
                                ) : (null)
                            }
                        </>
                    )
                })
            }
               
            </div>
        </div>
    </div>
</section>
    <Footer />
   </>
  )
}

export default PrivacyPolicy