import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { banner, plybtn } from "../../constant";
import "../../assets/css/Merch.css";
import { Link } from "react-router-dom";
import { GetAllCategories } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { useSelector } from "react-redux";

function Merchandise() {
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [spinLoad, setSpinLoad] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  // Get Categories
  useEffect(() => {
    setSpinLoad(true);
    GetAllCategories()
      .then((res) => {
        console.log(res);
        setSpinLoad(false);
        setCategoriesData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  console.log(categoriesData);

  return (
    <>
      <Header />

      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={banner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="merch py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10 text-center">
              <h2>Check our merchandise</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes. Lorem ipsum dolor sit
                amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
                parturient montes.
              </p>
            </div>
            <div className="col-sm-1"></div>
          </div>

          {spinLoad ? (
            <div className="loader-container">
              <SpinnerCircular size="80px" color="#d60012" />
            </div>
          ) : (
            <>
              <div className="row pt-5">
                {categoriesData?.map((item, index) => {
                  return (
                    <>
                      {index < 2 ? (
                        <div className="col-lg-6 col-md-6" key={index}>
                          <div className="merchandise">
                            <figure>
                              <Link to="/merchandise-products">
                                <img
                                  src={`${ImageUrl?.category_image_url}/${item?.icon}`}
                                  className="img-fluid merchandise-img ii"
                                  alt=""
                                />
                                <figcaption className="text-white">
                                  {item?.name}
                                </figcaption>
                              </Link>
                            </figure>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
              <div className="row pt-5">
                {categoriesData?.map((item, index) => {
                  return (
                    <>
                      {index >= 2 ? (
                        <div className="col-lg-4 col-md-6" key={index}>
                          <div className="merchandise">
                            <figure>
                              <Link to="/merchandise-products">
                                <img
                                  src={`${ImageUrl?.category_image_url}/${item?.icon}`}
                                  className="img-fluid merchandise-img"
                                  alt=""
                                />
                                <figcaption className="text-white">
                                  {item?.name}
                                </figcaption>
                              </Link>
                            </figure>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Merchandise;
