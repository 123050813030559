import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../assets/css/Forgot.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { forgotPassword } from '../../redux/actions/AuthActions'

function ForgotPassword() {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [ email, setEmail ] = useState("")
    const [ loading, setLoading ] = useState("")
    const [checked, setChecked] = useState("");

    const ForgetPasswordHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if(!email){
          toast.error("please enter Your email");
          setLoading(false);
        }
        if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
          )
        ) {
          toast.error("please enter correct email");
          setLoading(false);
          return;
        } else {
          let data = { email: email };
          setTimeout(async () => {
            setLoading(false);
            let send = await dispatch(forgotPassword(data));
            // Navigate("/forgot-password-Otp", { state: email });
            // Navigate("/newpassword");
            // window.location.href = "/newpassword";
          }, 600);
        }
      };

  return (
   <>
    <Header />
    <section className="forgot-password py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="forgot-form-content">
                    <h2 className="text-center pb-4">Forgot Password</h2>
                    <p className="text-center">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div className="forgot-pass-form pt-4">
                    <form action="">
                        <div className="form-group">
                            <input 
                                type="email" 
                                className="form-control" 
                                id="Email" aria-describedby="emailHelp" placeholder="Email"
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group row pt-4">
                          <button
                            className="form-control offset-3 form-submit col-sm-6" 
                            disabled={loading}
                            onClick={(e)=>ForgetPasswordHandler(e)}
                          >
                            {loading? "Loading.." : "Next"}
                          </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
    </div>
</section>
    <Footer />
   </>
  )
}

export default ForgotPassword