import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import LogoSlider from "../../components/Slider/LogoSlider";
import ProductImageSlider from "../../components/Slider/ProductImageSlider";
import {
  banner,
  brand1,
  brand10,
  brand11,
  brand12,
  brand13,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  plybtn,
  productDetail,
  tbanner,
} from "../../constant";
import "../../assets/css/ProductDetail.css";
import { AddWishList, MerchanProductDeatils } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AddToCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";
import Heart from "react-heart";
import { Rating } from "react-simple-star-rating";
import moment from "moment"; 
import { CounterData } from "../../redux/actions/AuthActions";

function MerchandiseProductDetails() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const allStates = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);

  let currentUrl = window.location.href.split("/");
  // console.log(currentUrl[4]);
  const [paramData, setParamData] = useState(null);
  const [sizeData, setSizeData] = useState("");
  const [type, setType] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState("");
  const [active, setActive] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);
  
  const [timerDays, setTimerDays] = useState('00')
  const [timerHours, setTimerHours] = useState('00')
  const [timerMinutes, setTimerMinutes] = useState('00')
  const [timerSeconds, setTimerSeconds] = useState('00')

  let interval = useRef()
  const StartTimer = () => {
    const countdownDate = new Date(paramData?.expiry_date).getTime();
    interval = setInterval(()=> {
      const now = new Date().getTime()
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
      const minutes = Math.floor((distance % (1000 * 60 * 60 ) / (1000 * 60)))
      const seconds = Math.floor((distance % (1000 * 60))/1000)

      if ( distance < 0 ) {
        clearInterval(interval.current)
      } else {
        setTimerDays(days)
        setTimerHours(hours)
        setTimerMinutes(minutes)
        setTimerSeconds(seconds)
      }
    }, 1000)
  }
  
  useEffect(()=> {
    StartTimer()
    return () => {
      clearInterval(interval.current)
    }
  })

  


  const filterVariationQty = () => {
    let varient = color + "-" + sizeData;
    if (JSON.parse(paramData?.variation)) {
      // console.log(
      //   "filterVariationQty Array",
      //   JSON.parse(paramData?.variation).filter((val) => val.type === varient)
      // );
      let newVarient = JSON.parse(paramData?.variation).filter(
        (val) => val.type === varient
      );
      if (newVarient.length > 0) {
        return newVarient[0].qty || 1;
      }
      return 1;
    }
    return 1;
  };


  useEffect(() => {
    MerchanProductDeatils(currentUrl[4])
      .then((res) => {
        // console.log(res);
        setParamData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUrl[4]]);

  const AddToCartHandler = (item) => {
    let checkItemAlreadyExist = allStates.filter((val) => val?.id === item?.id);
    if (!sizeData || !color) {
      toast.error("Please Select Tshirt Color and Size");
      return;
    }
    if (checkItemAlreadyExist?.length > 0) {
      alert("Item Already Exist in Cart!!!");
    } else {
      let colorData = JSON.parse(paramData?.colors);
      let data = {
        id: item?.id,
        price: item?.unit_price,
        quantity: quantity,
        color: color ? color : colorData,
        size: sizeData,
        productitem: item,
        type: type,
      };
      dispatch(AddToCart(data));
      toast.success("Product Successfully Add ");
      Navigate("/cart");
    }
  };

  const Increment = (id) => {
    setQuantity(quantity + 1);
  };
  const Decrement = (id) => {
    setQuantity(quantity - 1);
  };

  const AddWishListHandler = () => {
    // e.preventDefault();
    if (!Token) {
      toast.error("Please Login");
      return;
    }
    setActive(!active);
    let data = {
      product_id: paramData?.id,
    };
    // console.log(data);
    AddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        console.log(res);
        toast.success("Product Add to your Wishlist");
      })
      .catch((err) => {
        setWishLoading(false);
        console.log(err?.response);
        toast.error(err?.response?.data?.message);
      });
  };

  if (paramData == null) {
    return (
      <div className="loader-container">
        <SpinnerCircular size="80px" color="#d60012" />
      </div>
    );
  }

  // console.log(JSON.parse(paramData?.choice_options));

  return (
    <>
      <Header />
      <section className="Slidebanner">
        <div className="banner">
          <div classNameName="slides">
            <div className="img-box">
              <figure>
                <img src={tbanner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      {/* <LogoSlider /> */}

      <section className="product-detail">
        <div className="container">
          {/* <ul className="breadcrumbs">
            <li>
              <a href="">Home</a>
            </li>
            <li></li>
            <li>Product</li>
            <li></li>
            <li className="active">Product Detail</li>
          </ul> */}
          <div className="row">
            <div className="col-md-5">
              <ProductImageSlider
                ParamData={JSON.parse(paramData?.images) || []}
                page={"merchandise"}
              />
            </div>
            <div className="col-md-7">
              <div className="product-contents">
                <div className="topbar">
                  <div className="headings">
                    <h4 className="name">{paramData?.name}</h4>
                  </div>
                  <Heart
                    inactiveColor={"black"}
                    isActive={active}
                    onClick={() => AddWishListHandler()}
                    animationScale={1.25}
                    style={{ marginBottom: "1rem" }}
                    className="heart"
                  />
                </div>
                <h4 className="price">${paramData?.unit_price}</h4>
                <div className="descriptions">
                  <p
                    className="fw-400"
                    dangerouslySetInnerHTML={{ __html: paramData?.details }}
                  />
                </div>

                <p className="count d-flex">
                 COUNTDOWN...
                  {/* {" "}{moment(paramData?.expiry_date).endOf('day').fromNow()} */}
                </p>
               
                {/* <p>{ timerDays }{timerHours} {timerMinutes} {timerSeconds}</p> */}
                <div className="timer-main">
                  <div className="timer-inner">
                    <div>Days</div>
                    <div>{timerDays ? timerDays : null}</div>
                  </div>
                  <div className="timer-inner">
                    <div>Hrs</div>
                    <div>{timerHours ? timerHours : null}</div>
                  </div>
                  <div className="timer-inner">
                    <div>Min</div>
                    <div>{timerMinutes ? timerMinutes : null}</div>
                  </div>
                  <div className="timer-inner">
                    <div>Sec</div>
                    <div>{timerSeconds ? timerSeconds : null}</div>
                  </div>
                </div>
                <div className="mt-3 mb-3">
                  <Link to="/term-condition-off-sale" >Sales Conditions</Link>
                </div>
                <div className="rating">
                  {paramData?.rating.length > 0 ? (
                    <>
                      {paramData?.rating?.map((items, index) => {
                        return (
                          <Rating
                            size={22}
                            readonly={true}
                            ratingValue={
                              Math.round(items?.average) === 1
                                ? "20"
                                : Math.round(items?.average) === 2
                                ? "40"
                                : Math.round(items?.average) === 3
                                ? "60"
                                : Math.round(items?.average) == 4
                                ? "80"
                                : Math.round(items?.average) === 5
                                ? "100"
                                : null
                            }
                          />
                        );
                      })}
                    </>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="sizes_variations ">
                      <div className="color_variations mt-4">
                        <div className="form-group">
                          <label for="color">color</label>

                          {JSON.parse(paramData?.choice_options).map(
                            (item, index) => {
                              return (
                                <>
                                  {item?.title == "Color" ? (
                                    <>
                                      <select
                                        id="color"
                                        className="form-control"
                                        onChange={(e) => {
                                          setColor(e.target.value);
                                          setType(
                                            `${e.target.value}-${sizeData}`
                                          );
                                        }}
                                      >
                                        <option>Select Color</option>
                                        {item?.options?.map((item2, index) => {
                                          return (
                                            <option value={item2} key={index}>
                                              {item2}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </>
                                  ) : null}
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="form-group sizes mt-4 ml-2">
                        <label for="size">Size</label>
                        {JSON.parse(paramData?.choice_options).map(
                          (item, index) => {
                            return (
                              <>
                                {item?.title == "Size" ? (
                                  <>
                                    <select
                                      id="size"
                                      className="form-control"
                                      onChange={(e) => {
                                        setSizeData(e.target.value);
                                        setType(`${color}-${e.target.value}`);
                                        filterVariationQty();
                                      }}
                                    >
                                      <option>Select Size</option>
                                      {item?.options?.map((item2, index) => {
                                        return (
                                          <option value={item2} key={index}>
                                            {item2}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : null}
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="form-group quantity mt-4">
                      <label for="qntyt">QUANTITY</label>
                      <div className="qty-contaner">
                        <button
                          type="button"
                          className="btn qty-btn"
                          onClick={() => Decrement(paramData?.id)}
                          disabled={quantity < 2}
                        >
                          -
                        </button>
                        <p className="qty-num">{quantity}</p>
                        <button
                          type="button"
                          className={`btn qty-btn ${filterVariationQty()}`}
                          onClick={() => Increment(paramData?.id)}
                          disabled={filterVariationQty() == quantity}
                        >
                          +
                        </button>
                      </div>
                      {/* <input type="text" className="form-control" /> */}
                      {/* <input type="number" id="qntyt" value="1" className="form-control" />  */}
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                <div className="button-group">
                  <button
                    className="add_cart"
                    onClick={() => AddToCartHandler(paramData)}
                  >
                    Add to Cart
                  </button>
                </div>
               
              </div>
            </div>
          </div>
          <p className="offer-text">
          A NEW LIMITED RUN GARAGE T DESIGN IS INTRODUCED EACH MONTH FOR A LIMITED TIME ONLY.<br />
          ONCE THE DESIGN IS RETIRED, IT WON'T BE COMING BACK. DON'T DELAY, GET YOURS TODAY!<br />
          2XL AND 3XL SHIRTS ADD $2.00 TO FINAL PRICE   
        </p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default MerchandiseProductDetails;
