import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ViewOrderModal from "../../components/Modal/ViewOrderModal";
import SideBar from "../../components/SideBar/SideBar";
import { notFound, order1, order2 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import { GetMyOrders } from "../../network/Network";

function MyOrders() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [pageCount, setPageCount] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [myOrders, setMyOrders] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // Get My Orders
  useEffect(() => {
    setSpinLoad(true);
    GetMyOrders(currentPage, Token)
      .then((res) => {
        setMyOrders(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const viewOrderHandler = (item) => {
    console.log(item)
    setIsOpenModal(true);
    setSelectedData(item);
  };

  console.log("orders", myOrders)

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
              {/* <p className="subtitle text-white">Profile</p> */}
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>My Orders</h5>
                    </div>
                  </div>
                  {spinLoad ? (
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        {myOrders?.length > 0 ? (
                          <table className="table orders_table">
                            <tr className="topbar">
                              <th className="sno">S.NO</th>
                              <th className="product pl-4">Order Id</th>
                              <th className="price">Price</th>
                              <th className="order-status">ORDER STATUS</th>
                              <th className="order-status">View</th>
                            </tr>
                            {myOrders?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="sno">{index + 1}</td>
                                  <td className="product">
                                    <div className="order_product">
                                      {/* <div className="img_box">
                                                  <figure>
                                                    <img src={order1} alt="" />
                                                  </figure>
                                                </div> */}
                                      <div className="order_content">
                                        <h5 className="order-name">
                                          Order Id {""}
                                          {item?.id}
                                        </h5>
                                        {/* <p className="order_type">
                                                    Order Id:{" "}
                                                    <span className="type">{item?.id}</span>
                                                  </p> */}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="price">
                                    £ {item?.order_amount}
                                  </td>
                                  <td className="order-status">
                                    {item?.order_status}
                                  </td>
                                  <td className="order-status">
                                    <button
                                      className="btn ViewDetails"
                                      onClick={() => viewOrderHandler(item)}
                                    >
                                      View Details
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        ) : (
                          <div className="notFound-container">
                            <img
                              src={notFound}
                              alt="not found"
                              className="image-fluid"
                            />
                            <p className="mt-4">No Record Found</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="pagination-container mt-5">
                  <ReactPaginate
                    previousLabel="<<"
                    nextLabel=">>"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
      <ViewOrderModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
    </>
  );
}

export default MyOrders;
