import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../assets/css/Forgot.css'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from "query-string";
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { SetResetPassword } from '../../redux/actions/AuthActions'

function NewPassword() {
    const location = useLocation();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [reNewPassword, setReNewPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const value = queryString.parse(location.search);
    const token = value.token;

    const NewPasswordHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!newPassword || !reNewPassword) {
          toast.error("Please Fill all fields");
          setLoading(false);
          return;
        }
        if(newPassword.length < 8){
            toast.error("The password must be at least 8 characters.")
            setLoading(false);
            return;
        }
        if (newPassword !== reNewPassword) {
          toast.error("Please Enter Same password and Confirm Password");
          setLoading(false);
          return;
        }
        let data = {
          password: newPassword,
          confirm_password: reNewPassword,
          reset_token: token,
          email: email 
        };
        setTimeout(async () => {
          let send = await dispatch(SetResetPassword(data, Navigate,setLoading));
          setLoading(false);
          //   Navigate("/Signin");
        }, 600);
      };
    
  return (
    <>
        <Header />
        <section className="forgot-pass-newpass py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="new-password-section-content text-center">
                    <h2 className="pb-4">Enter new password</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div className="new-password-form">
                <form action="">
                    <div className="form-group">
                            <input type="text" 
                                className="form-control" 
                                id="Password" aria-describedby="emailHelp" 
                                placeholder="Email"
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <input type="password" 
                                className="form-control" 
                                id="Password" aria-describedby="emailHelp" 
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e)=>setNewPassword(e.target.value)}
                            />
                        </div>

                        <div className="form-group mt-2">
                            <input type="password" 
                                className="form-control" id="confirm-Password" 
                                aria-describedby="emailHelp" placeholder="Retype New Password"
                                value={reNewPassword}
                                onChange={(e)=>setReNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group row pt-4">
                            <button 
                             className="form-control offset-3 form-submit col-sm-6" 
                                disabled={loading}
                                onClick={(e)=>NewPasswordHandler(e)}
                             >
                                {loading? "Loading.." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
    </div>
</section>
        <Footer />
    </>
  )
}

export default NewPassword