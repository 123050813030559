import { faCheckCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import StripeForm from "../../components/Stripe/StripeForm";
import { cardVisa1, editAds, offer1, offer2 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import {
  DeletePaymentMethodsApi,
  GetAllCard,
  PostPaymentDefaultCard,
  PostSaveCard,
} from "../../network/Network";
import { SpinnerCircular } from "spinners-react";

function PaymentMethod() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [cardToken, setCardToken] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(true);

  const PaymentHandler = async (e, visaToken) => {
    console.log("st");
    e.preventDefault();
    setSpinLoad(true);
    let waitToken = await visaToken;
    if (waitToken) {
      let data = {
        source: visaToken,
      };
      console.log(data);
      PostSaveCard(data, Token)
        .then((res) => {
          setSpinLoad(false);
          console.log(res);
          setPaymentData(res?.data?.response?.data);
          toast.success("Successfully card Add");
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    }
  };

  // Get All Card
  useEffect(() => {
    setSpinLoad(true);
    GetAllCard(Token)
      .then((res) => {
        console.log(res);
        setPaymentData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  // == Remove card ==
  const DeletePaymentMethod = (id, index) => {
    setSpinLoad(true);
    console.log(id);
    console.log(Token);
    let data = { id: id };
    DeletePaymentMethodsApi(data, Token)
      .then((res) => {
        console.log(res?.data?.response?.data);
        paymentData?.splice(index, 1);
        setPaymentData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  };

  const DefaultCardHandler = (e, id) => {
    e.preventDefault();
    setSpinLoad(true);
    // setPaymentLoading(true);
    let data = { id: id };
    PostPaymentDefaultCard(data, Token)
      .then((res) => {
        setPaymentData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  };

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
              {/* <p className="subtitle text-white">Profile</p> */}
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      <section className="profile_sec card-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>Payment Method</h5>
                    </div>
                  </div>
                  {spinLoad ? (
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  ) : (
                    <>
                      <div className="pay-card">
                        {paymentData?.map((item, index) => {
                          return (
                            <div className="row mt-5" key={index}>
                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-md-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className={`${
                                        item?.is_default == "1"
                                          ? "check-Icon"
                                          : "check-Icon2"
                                      }`}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      src={cardVisa1}
                                      alt=""
                                      className="image-fluid"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <p className="paymentCard m-0">
                                      My Personal Card
                                    </p>
                                    <p className="paymentCard-num m-0">
                                      **********{item?.card_end_number}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="flexBox-space">
                                  <div className="ml-4">
                                    <button
                                      type="button"
                                      className="btn btn-primary paymentDefault"
                                      style={{ width: "unset" }}
                                      onClick={(e) =>
                                        DefaultCardHandler(e, item?.id)
                                      }
                                      disabled={item?.is_default != 0}
                                    >
                                      Set as Default
                                    </button>
                                  </div>
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      className="icon-delete"
                                      onClick={() =>
                                        DeletePaymentMethod(item?.id)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}

                  <div className="mt-5">
                    <StripeForm
                      PaymentHandler={PaymentHandler}
                      setCardToken={setCardToken}
                      cardToken={cardToken}
                      setPaymentMethod={setPaymentMethod}
                      page={"paymentMethod"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PaymentMethod;
