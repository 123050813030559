import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/Signup.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signUp } from "../../redux/actions/AuthActions";

function Signup() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [userState, setUserState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const SignUpHandler = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !address ||
      !userState ||
      !zipCode ||
      !password
    ) {
      toast.error("Please Enter All Fields");
      setBtnLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setBtnLoading(false);
      return;
    }
      if(password.length < 8){
        toast.error("The password must be at least 8 characters.")
        setBtnLoading(false);
        return;
    }

    else {
      let data = {
        f_name: firstName,
        l_name: lastName,
        email: email,
        phone_no: phone,
        password: password,
        address: address,
        state: userState,
        zip: zipCode,
      };
      // setBtnLoading(false);
      let send = await dispatch(signUp(data, Navigate,setBtnLoading));
      //   Navigate("/login");
    }
  };

  return (
    <>
      <Header />
      <section className="signup py-5">
        <div className="container py-5">
          {/* <!-- sign up form content start --> */}
          <div className="row">
            <div className="col-sm-12">
              <h2 className="text-center pb-4">Sign Up</h2>
              <p className="text-center">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <div className="offset-2"></div>
          </div>
          {/* <!-- sign up form content end --> */}
          {/* <!-- sign up form start --> */}
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="signup-form pt-5">
                <form>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      aria-describedby="emailHelp"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      aria-describedby="emailHelp"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="Email"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="Address"
                      aria-describedby="emailHelp"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>

                  <div className="form-group mt-2">
                    <input
                      type="number"
                      className="form-control"
                      id="Zip"
                      aria-describedby="emailHelp"
                      placeholder="Zip Code"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      aria-describedby="emailHelp"
                      placeholder="State"
                      value={userState}
                      onChange={(e) => setUserState(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="number"
                      className="form-control"
                      id="Phone"
                      aria-describedby="emailHelp"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="password"
                      className="form-control"
                      id="Password"
                      aria-describedby="emailHelp"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="row pt-4 justify-content-center">
                    <button
                      className="form-control offset-3 offset-sm-0 form-submit col-sm-6 btn"
                      onClick={(e) => SignUpHandler(e)}
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Loading.." : "Signup"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Signup;
