import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/CheckOut.css";
import StripeForm from "../../components/Stripe/StripeForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PlaceOrder } from "../../network/Network";
import { ClearCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";

function CheckOut() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const CartData = useSelector((state) => state.CartReducer.cartData);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [cardToken, setCardToken] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [firstName, setFirstName] = useState(userData?.f_name);
  const [lastName, setLastName] = useState(userData?.l_name);
  const [phone, setPhone] = useState(userData?.phone);
  const [email, setEmail] = useState(userData?.email);
  const [address, setAddress] = useState(userData?.address);
  const [city, setCity] = useState(userData?.state);
  const [country, setCountry] = useState(userData?.country);
  // const [ userState, setUserState ] = useState(userData?.state)
  const [postalCode, setPostalCode] = useState(userData?.zip);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState("");
  const [subTotal, setSubTotal] = useState(0);

  const Location = useLocation();
  const CheckOutData = Location?.state?.data;

  const PaymentHandler = (e) => {
    e.preventDefault();
    if (!cardToken) {
      // toast.error("Invalid Card Details");
      return;
    }
  };

  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < CheckOutData?.CartData.length; i++) {
      total =
        total +
        CheckOutData?.CartData[i]?.price * CheckOutData?.CartData[i]?.quantity;
    }
    setSubTotal(total);
  };

  useEffect(() => {
    TotalAmount();
  }, [CartData]);

  const PlaceOrderHandler = () => {
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !country ||
      !postalCode ||
      !city ||
      !address
    ) {
      toast.error("Please Enter all Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    let userData = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      region: country,
      postalCode: postalCode,
      townCity: city,
      address: address,
    };
    let data = {
      cart: CheckOutData?.CartData,
      // discount: 0,
      customer_info: userData,
      stripe_token: cardToken,
      role: "customer",
    };
    PlaceOrder(data, Token)
      .then((res) => {
        setLoading(false);
        dispatch(ClearCart());
        toast.success("Order placed successfully!");
        setTimeout(() => {
          Navigate(`/my-orders`);
          //   Navigate(`/my-orders`, { state: { data: res?.data?.data?.order } });
        }, 600);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.errors[0]?.code == "stock") {
          toast?.error(err?.response?.data?.errors[0]?.message);
        }
      });
  };

  const LoginHandler = (e) => {
    e.preventDefault();
    toast.error("Please Login First");
  };

  return (
    <>
      <Header />
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">Checkout</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="checkout-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-md-1">
              <div className="for-back-color">
                <form className="needs-validation" novalidate>
                  <div className="row for-align">
                    <div className="col-md-2 mb-3">
                      <label for="firstName">First name</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label for="firstName">Last name</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label for="firstName">Email</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row for-align">
                    <div className="col-md-2 mb-3">
                      <label for="firstName">Postal Code</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="Postal Code"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label for="firstName">Phone</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="lastName"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label for="firstName">City</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label for="firstName">Country</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label for="firstName">Address</label>
                    </div>
                    <div className="col-md-10 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </form>
              </div>
              <h4 className="mb-3 your-credit-card">YOUR CREDIT CARD</h4>
              <div className="credit-card">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <StripeForm
                      PaymentHandler={PaymentHandler}
                      setCardToken={setCardToken}
                      cardToken={cardToken}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <button
                      className="btn btn-primary btn-lg btn-block for-color"
                      type="submit"
                      onClick={() => Navigate(-1)}
                    >
                      BACK TO CART
                    </button>
                  </div>
                  <div className="col-md-6 mb-3">
                    <button
                      className="btn btn-primary btn-lg btn-block for-color"
                      type="submit"
                      onClick={(e) => {
                        Token ? PlaceOrderHandler(e) : LoginHandler(e);
                      }}
                      disabled={!cardToken || loading}
                    >
                      {loading ? "Loading.." : "Confirm Order"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 order-md-2 mb-4">
              <div className="form-for-back">
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                      <h2 className="my-0">ORDER SUMMARY</h2>
                    </div>
                  </li>
                  {CheckOutData?.CartData?.map((item, index) => {
                    return (
                      <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 className="my-0">
                            {item?.productitem?.name} {item?.quantity}X
                          </h6>
                        </div>
                        <span className="text-muted">
                          ${item?.productitem?.unit_price}
                        </span>
                      </li>
                    );
                  })}

                  <li className="list-group-item d-flex justify-content-between">
                    <span>Total Cost</span>
                    <strong>${subTotal}</strong>
                  </li>
                </ul>
              </div>
              <div className="col-md-12 order-md-2 mb-4 p-0">
                <div className="form-for-back">
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                      <div>
                        <h2 className="my-0">IN YOUR CART</h2>
                      </div>
                    </li>
                    {CheckOutData?.CartData?.map((item, index) => {
                      return (
                        <li
                          className="list-group-item d-flex justify-content-between lh-condensed"
                          key={index}
                        >
                          <div>
                            <h6 className="my-0">{item?.productitem?.name}</h6>
                          </div>
                          <span className="text-muted">
                            {item?.productitem?.unit_price}
                          </span>
                        </li>
                      );
                    })}

                    <li className="list-group-item d-flex justify-content-between for-line">
                      <span>Amount</span>
                      <strong>${subTotal}</strong>
                    </li>

                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Cost</span>
                      <strong>${subTotal}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default CheckOut;
