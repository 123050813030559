import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { courtesyLogo, heroPlate, videoPlate, youtube } from "../../constant";

function CshVideo(props) {
  const { CshVideosRedux, setSelectVideo, selectVideo } = props;
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [cshData, setCshData] = useState([]);
  const [feature, setFeature] = useState();

  useEffect(() => {
    if (CshVideosRedux?.length > 0) {
      setCshData(CshVideosRedux.filter((item, index) => item?.show_on !== "2"));
      setFeature(CshVideosRedux.filter((item, index) => item?.feature === "1"));
    }
  }, [CshVideosRedux]);

  // console.log("index", cshData);
  // console.log(feature);

  // console.log(selectVideo);

  return (
    <>
      <section className="carsVideos single-video">
        <div className="container">
          <div className="heading">
         
            {/* <h2>
              <span style={{ color: "#D80909" }}>Feature</span> Video
            </h2>
            <div className="courtesy-cont">
              <a href="https://www.youtube.com/c/DroptopMedia" target="_blank">
                <img src={courtesyLogo} alt="" className="image-fluid" />
              </a>
            </div> */}
            <div className="linktag">
              <div className="img-cont">
                <Link to="/video">
                <img src={videoPlate} className="image-fluid" alt="" />
                </Link>
              </div>
              <div className="img-cont">
              <a href="https://www.youtube.com/channel/UCKRTVcJQ8YXau0ucjP3k38g" target="_blank" >
                <img src={youtube} className="image-fluid" alt="" />
                </a>
              </div>
            </div>
            <h2>
              Check out our Channel.
            </h2>
          </div>

          <div className="row">
            <div className="col-md-12">
              {selectVideo ? (
                <>
                  <div className="video-box one wow animate__animated animate__fadeIn">
                    <div className="img-box">
                      <video
                        className="videoTag"
                        playsinline
                        autoPlay
                        loop
                        muted 
                        controls={false}
                        src={`${ImageUrl?.csh_video}/${selectVideo?.video}`}
                      >
                      </video>
                    </div>

                    <div className="videoTitle">
                      <h3>{selectVideo?.title}</h3>
                    </div>
                  </div>
                  {/* <p className="video-desc">{selectVideo?.description}</p> */}
                </>
              ) : (
                <>
                  {feature?.length > 0 ? (
                    <>
                      <div className="video-box one wow animate__animated animate__fadeIn">
                        <div className="img-box">
                          <video className="videoTag" playsinline autoPlay loop muted controls={false} >
                            <source
                              src={`${ImageUrl?.csh_video}/${feature[0]?.video}`}
                              type="video/mp4"
                            />
                          </video>
                        </div>

                        <div className="videoTitle">
                          <h3>{feature[0]?.title}</h3>
                        </div>
                      </div>
                      {/* <p className="video-desc">{feature[0]?.description}</p> */}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Cars Video Start here --> */}
      <section className="carsVideos">
        <div className="container">
          <div className="heading">
            {/* <h2>
              <span style={{ color: "#D80909" }}>CSH</span> Video
            </h2> */}
          </div>
          <div className="row">
            <div className="col-md-4">
              {cshData?.length > 0 ? (
                <div
                  className="video-box one wow animate__animated animate__fadeIn "
                  onClick={() => setSelectVideo(cshData[0])}
                >
                  <div className="img-box">
                    <video className="videoTag" playsinline autoPlay loop muted controls={false}>
                      <source
                        src={`${ImageUrl?.csh_video}/${cshData[0]?.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="videoTitle">
                    <h3>{cshData[0]?.title}</h3>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-md-4">
              {cshData?.length > 1 ? (
                <div
                  className="video-box one wow animate__animated animate__fadeIn "
                  onClick={() => setSelectVideo(cshData[1])}
                >
                  <div className="img-box">
                    <video className="videoTag" playsinline autoPlay loop muted controls={false}>
                      <source
                        src={`${ImageUrl?.csh_video}/${cshData[1]?.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="videoTitle">
                    <h3>{cshData[1]?.title}</h3>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-md-4">
              {cshData?.length > 2 ? (
                <div
                  className="video-box one wow animate__animated animate__fadeIn "
                  onClick={() => setSelectVideo(cshData[2])}
                >
                  <div className="img-box">
                    <video className="videoTag" playsinline autoPlay loop muted controls={false}>
                      <source
                        src={`${ImageUrl?.csh_video}/${cshData[2]?.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="videoTitle">
                    <h3>{cshData[2]?.title}</h3>
                  </div>
                </div>
              ) : null}
            </div>

            {/* <div className="col-md-3">
              {cshData?.length > 0 ? (
                <div className="video-box wow animate__animated animate__fadeIn">
                  <div className="img-box">
                    <video className="videoTag" muted controls>
                      <source
                        src={`${ImageUrl?.csh_video}/${cshData[0]?.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="videoTitle">
                    <h3>{cshData[0]?.title}</h3>
                  </div>
                </div>
              ) : null}

              {cshData?.length > 1 ? (
                <div className="video-box wow animate__animated animate__fadeIn ">
                  <div className="img-box">
                    <video className="videoTag" muted controls>
                      <source
                        src={`${ImageUrl?.csh_video}/${cshData[1]?.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="videoTitle">
                    <h3>{cshData[1]?.title}</h3>
                  </div>
                </div>
              ) : null}

              {cshData?.length > 2 ? (
                <div className="video-box wow animate__animated animate__fadeIn">
                  <div className="img-box">
                    <video className="videoTag" muted controls>
                      <source
                        src={`${ImageUrl?.csh_video}/${cshData[2]?.video}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="videoTitle">
                    <h3>{cshData[2]?.title}</h3>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6">
              {cshData?.length > 3 ? (
                <>
                  <div className="video-box same wow animate__animated animate__fadeIn">
                    <div className="img-box">
                      <video className="videoTag" muted controls>
                        <source
                          src={`${ImageUrl?.csh_video}/${cshData[3]?.video}`}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-md-6">
              {cshData?.length > 4 ? (
                <>
                  <div className="video-box same wow animate__animated animate__fadeIn">
                    <div className="img-box">
                      <video className="videoTag" muted controls>
                        <source
                          src={`${ImageUrl?.csh_video}/${cshData[4]?.video}`}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </>
              ) : null}
            </div> */}
          </div>

          <div className="button-group blog-more-cont">
          {/* <Link to="/video">
            <div className="plate-container">
              <img src={heroPlate} alt="" className="image-fluid" />
            </div>
          </Link>  */}
            {/* <Link to="/blogs" className="form-submit blogs-more">
              View All
            </Link> */}
          </div>
            <div className="mt-2 mb-5">
              <p className="video_bottom_text">Come Along With Us To The</p>
              <p className="video_bottom_text">Best Automotive Events</p>
              {/* <p className="video_bottom_text">Come along with us to some of the best</p>
              <p className="video_bottom_text">automotive events anywhere.</p> */}

            {/* <p className="video_bottom_text">
            Car show hero is always pleased to feature automotive videos created by some of the very best content creators.
          </p>
          <p className="video_bottom_text">If you are interested in potentially getting one of your videos posted here, you can reach us through the contact link at the bottom of the homepage</p> */}
            </div>
        </div>
      </section>
      {/* <!-- Cars Video End Here --> */}
    </>
  );
}

export default CshVideo;
