import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { footerLogo } from "../../constant";
import { SocialLinks } from "../../network/Network";

function Footer() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const SocialLinks = useSelector((state) => state.AuthReducer.socialMedia);
  const PageDataRedux = useSelector((state) => state.AuthReducer.pagesData);

  return (
    <footer className="text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="footerlogo">
              <figure>
                <img src={footerLogo} alt="" />
              </figure>
            </div>
            <div className="txt">
              {/* <p style={{ color: "#9A9DA5" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
                lconsequat.
              </p> */}

              {PageDataRedux?.map((item, index) => {
                return (
                  <>
                    {item?.type === "footer_content" ? (
                      <>
                        <div className="txt" key={index}>
                          <p
                            dangerouslySetInnerHTML={{ __html: item?.value }}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                );
              })}
              {PageDataRedux?.length > 0 ? (
                <>
                  {PageDataRedux?.map((item, index) => {
                    return (
                      <>
                        {item?.type == "company_copyright_text" ? (
                          <p className="copyright" key={index}>
                            {item?.value} 2022 - Car Show Hero
                          </p>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-4">
                <div className="head">
                  <h5>CATEGORIES</h5>
                </div>

                <ul className="footer-menu">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  {/* <li><Link to="/merchandise">Merchandise</Link></li> */}
                  <li>
                    <Link to="/all-cars">Classifieds</Link>
                  </li>

                  <li>
                    <a href="/car-show">Car Shows</a>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <div className="head op">
                  <h5>COMMUNITY</h5>
                </div>
                <ul className="footer-menu">
                  {/* <li>
                    <Link to="/leader-board">Leader Board</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/our-videos">Our Videos</Link>
                  </li> */}

                  <li>
                    <Link to="/loan-calculator">Loan Calculator</Link>
                  </li>
                  {/* <li>
                    <Link to="/Sponsors">Sponsors</Link>
                  </li> */}
                  <li>
                    <Link to="/term-condition-off-sale">Sale Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/term-condition">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <div className="head">
                  <h5>FOLLOW US</h5>
                </div>
                <ul className="footer-social">
                  {SocialLinks?.length > 0 ? (
                    <>
                      {SocialLinks?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "twitter" ? (
                              <li key={index}>
                                <a href={item?.link} target="_blank">
                                  <i
                                    className="fa fa-twitter"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : null}

                  {SocialLinks?.length > 0 ? (
                    <>
                      {SocialLinks?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "facebook" ? (
                              <li key={index}>
                                <a href={item?.link} target="_blank">
                                  <i
                                    className="fa fa-facebook"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : null}

                  {SocialLinks?.length > 0 ? (
                    <>
                      {SocialLinks?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "google-plus" ? (
                              <li key={index}>
                                <a href={item?.link} target="_blank">
                                  <i
                                    className="fa fa-google"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : null}

                  {SocialLinks?.length > 0 ? (
                    <>
                      {SocialLinks?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "instagram" ? (
                              <li key={index}>
                                <a href={item?.link} target="_blank">
                                  <i
                                    className="fa fa-instagram"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
