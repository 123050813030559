import React from "react";
import { online } from "../../constant";
import { useSelector } from "react-redux";
import moment from "moment";
import { ramdomImage } from "../../constant/ConstantFunction";

function ChatUserSideBar(props) {
  const {
    item,
    allItem,
    index,
    SelectedUserMesaagesHandler,
    setSelectChatPerson,
  } = props;
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);

  return (
    <>
      {userData?.id != item?.id ? (
        <div
          className="online_chats"
          onClick={(e) => {
            setSelectChatPerson(allItem);
          }}
          key={index}
        >
          <div className="img_box">
            <figure>
              {item?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(`${item?.f_name}{""}${item?.l_name}`)}`}
                  className="image-fluid image-width"
                  // style={{ borderRadius: "50%" }}
                  alt=""
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${item?.image}`}
                  alt=""
                />
              )}
            </figure>
          </div>
          <div className="contentDv">
            <div className="name">
              <h4 className="user_name">
                {item?.f_name} {item?.l_name}
              </h4>
              <p className="status">Online</p>
            </div>
            <div className="date">
              <p>{moment(item?.updated_at).format("h:mm a")}</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ChatUserSideBar;
