import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { about, about_banner, banner, plybtn, sideab } from "../../constant";

function About() {
  const AboutData = useSelector((state) => state.AuthReducer.pagesData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={about_banner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mb-5">
              <h2 className="text-center">About Us</h2>
            </div>
            {AboutData?.map((item, index) => {
              return (
                <>
                  {item?.type === "about_us" ? (
                    <>
                      <div className="col-lg-6" key={index}>
                        <p dangerouslySetInnerHTML={{ __html: item?.value }} />
                      </div>
                      <div className="col-lg-6">
                        <img src={sideab} className="img-fluid" alt="" />
                      </div>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
