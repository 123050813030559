import {
    AUTH,
    SIGNUP,
    // end 
    LOGIN,
    CATEGORIES,
    CONTACTUS,
    COMPANY_INFORMATION,
    CUSTOMER,
    UPDATE_PROFILE,
    PRODUCTS,
    LATEST,
    STATUS,
    WISHLIST,
    REMOVE,
    ORDER,
    LIST,
    MAGAZINE,
    // ---
    ADD,
    PLACE,
    CUSTOMER_ID,
    SOCIAL_MEDIA,
    LINKS,
    COUPON,
    APPLY,
    CODE,
    PRODUCT_ID,
    SEARCH,
    PAGE,
    PRICE,
    START,
    END,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    CONTACT,
    STORE,
    SPONSORS,
    EVENT,
    EVENTS,
    PACKAGE,
    CONDITION,
    CAR,
    MAKE,
    OPTIONS,
    COLOR,
    CATEGORY,
    INSTANTOFFER,
    ALL_LISTING,
    CAR_CATEGORY_ID,
    MAKE_ID,
    MODEL_ID,
    SHOWCASE_LISTING,
    POST,
    CONFIG,
    ADD_LISTING,
    BUSINESS_SETTINGS,
    PAYMENT_METHOD,
    SHOW_CARD,
    REMOVE_CARD,
    UPDATE_DEFAULT_CARD,
    CSH_VIDEO,
    VIDEOS,
    CHAT,
    CREATE,
    GET,
    MESSAGE,
    SEND,
    GETINSTANTOFFER,
    VIEW_CAR,
    UPDATE_STATUS,
    GET_EVENT,
    FAVOURITE,
    DETAILS,
    LOAN,
    CALCULATOR,
    STORE_EVENT,
    EDIT,
    DELETE,
    SHOW_SINGLE,
    BLOGS,
    SHOW,
    VIDEO,
  } from "../network/Endpoint";
  
  import {
    doGet,
    doPost,
    doPatch,
    doDelete,
    doPostProfilePictureUpload,
  } from "./Config";
  
  export const postSignUp = (data) => {
    return doPost(`${AUTH}${SIGNUP}`, data);
  };
  
  export const postSignIn = (data) => {
    return doPost(`${AUTH}${LOGIN}`, { ...data });
  };

  export const forgotPasswordApi = (data) => {
    return doPost(`${AUTH}${FORGOT_PASSWORD}`, data);
  };

  export const ResetPasswordApi = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };

  export const PostContactUs = (data) => {
    return doPost(`${CONTACT}${STORE}`, data);
  };

  export const GetAllSponsors = () => {
    return doGet(`${SPONSORS}`);
  };

  export const GetAllEvents = (selectState,currentPage) => {
    return doGet(`${EVENT}${selectState ? "/" : ""}${selectState}${PAGE}=${currentPage}`);
  };
  
  export const PostEventReservation = (data,token) => {
    return doPost(`${CUSTOMER}${EVENTS}${STORE}`, data, token);
  };

  export const GetAllCategories = () => {
    return doGet(`${CATEGORIES}`);
  };

  export const CarListingPackages = () => {
    return doGet(`${PACKAGE}`);
  };

  export const GetCarConditions = () => {
    return doGet(`${CAR}${CONDITION}`);
  };

  export const GetCarMakers = (id = null) => {
    return doGet(`${CAR}${MAKE}${id ? '/'+id : ''}` );
  };

  export const GetCarOptions = () => {
    return doGet(`${CAR}${OPTIONS}`);
  };

  export const GetCarColors = () => {
    return doGet(`${CAR}${COLOR}`);
  };

  export const GetCarCategory = () => {
    return doGet(`${CAR}${CATEGORY}`);
  };
  
  export const GetCarListingParam = (Data) => {
    return doGet(`${CAR}${ALL_LISTING}${CAR_CATEGORY_ID}=${Data?.cate}${MAKE_ID}=${Data?.make}${MODEL_ID}=${Data?.model}`);
  };

  export const GetCarShowcaseList = () => {
    return doGet(`${CAR}${SHOWCASE_LISTING}`);
  };
 


  export const PostCarListing = (data,token) => {
    return doPostProfilePictureUpload(`${CUSTOMER}${POST}${STORE}`, data, token);
  };

  export const EditPostCarListing = (data,token) => {
    return doPostProfilePictureUpload(`${CUSTOMER}${POST}${EDIT}`, data, token);
  };

  export const GetMyCarListing = (token,currentPage) => {
    return doGet(`${CUSTOMER}${POST}${PAGE}=${currentPage}`,token);
  };
// ---test 
// ${`?`}${`page`}=${currentPage}


  export const UpdateProfile = (data, token) => {
    return doPostProfilePictureUpload(
      `${CUSTOMER}${UPDATE_PROFILE}`,
      data,
      token
    );
  };

  export const GetAddListPrice = () => {
    return doGet(`${CONFIG}${ADD_LISTING}`);
  };

  export const GetPagesApi = () => {
    return doGet(`${BUSINESS_SETTINGS}`);
  };

  export const PostSaveCard = (data,token) => {
    return doPost(`${CUSTOMER}${PAYMENT_METHOD}${STORE}`, data, token);
  };

  export const GetAllCard = (token) => {
    return doGet(`${CUSTOMER}${PAYMENT_METHOD}${SHOW_CARD}`, token);
  };
  
  export const GetImageUrls = () => {
    return doGet(`${CONFIG}` );
  };

  export const DeletePaymentMethodsApi = (data, token) => {
    return doPost(`${CUSTOMER}${PAYMENT_METHOD}${REMOVE_CARD}`, data, token);
  };

  export const PostPaymentDefaultCard = (data, token) => {
    return doPost(`${CUSTOMER}${PAYMENT_METHOD}${UPDATE_DEFAULT_CARD}`, data, token);
  };
  
  export const GetFilterCar = (currentPage = null,data) => {
    let link = `${CAR}${ALL_LISTING}`;
    if(data){
      link += '?';
      link += (data.color_code)? `${"color_code"}=${data?.color_code}&` : '';  
      link += (data.mileage)? `${"mileage"}=${data?.mileage}&` : '';  
      link += (data.car_category_id)? `${"car_category_id"}=${data?.car_category_id}&` : '';  
      link += (data.make_id)? `${"make_id"}=${data?.make_id}&` : '';  
      link += (data.model_id)? `${"model_id"}=${data?.model_id}&` : '';  
      link += (data.condition_id)? `${"condition_id"}=${data?.condition_id}&` : '';  
      link += (data.drive_type_id)? `${"drive_type_id"}=${data?.drive_type_id}&` : '';  
      link += (data.transmission_id)? `${"transmission_id"}=${data?.transmission_id}&` : '';  
      link += (data.fuel_type_id)? `${"fuel_type_id"}=${data?.fuel_type_id}&` : '';  
      link += (data.min_year)? `${"min_year"}=${data?.min_year}&` : '';  
      link += (data.max_year)? `${"max_year"}=${data?.max_year}&` : '';  
      link += (data.min_price)? `${"min_price"}=${data?.min_price}&` : '';  
      link += (data.max_price)? `${"max_price"}=${data?.max_price}&` : '';
      link += (currentPage)? `${"page"}=${currentPage}` : ''
    }
    console.log(link);
    return doGet(link,data?.car_category_id);
  };

  export const GetCarListingInstantOffer = (currentPage = null,data) => {
    let link = `${CAR}${INSTANTOFFER}`;
    if(data){
      link += '?';
      link += (data.color_code)? `${"color_code"}=${data?.color_code}&` : '';  
      link += (data.mileage)? `${"mileage"}=${data?.mileage}&` : '';  
      link += (data.car_category_id)? `${"car_category_id"}=${data?.car_category_id}&` : '';  
      link += (data.make_id)? `${"make_id"}=${data?.make_id}&` : '';  
      link += (data.model_id)? `${"model_id"}=${data?.model_id}&` : '';  
      link += (data.condition_id)? `${"condition_id"}=${data?.condition_id}&` : '';  
      link += (data.drive_type_id)? `${"drive_type_id"}=${data?.drive_type_id}&` : '';  
      link += (data.transmission_id)? `${"transmission_id"}=${data?.transmission_id}&` : '';  
      link += (data.fuel_type_id)? `${"fuel_type_id"}=${data?.fuel_type_id}&` : '';  
      link += (data.min_year)? `${"min_year"}=${data?.min_year}&` : '';  
      link += (data.max_year)? `${"max_year"}=${data?.max_year}&` : '';  
      link += (data.min_price)? `${"min_price"}=${data?.min_price}&` : '';  
      link += (data.max_price)? `${"max_price"}=${data?.max_price}&` : '';
      link += (currentPage)? `${"page"}=${currentPage}` : ''
    }
    console.log(link);
    return doGet(link,data?.car_category_id);
    // return doGet(`${CAR}${INSTANTOFFER}`);
  };

  export const GetCSHVideo = () => {
    return doGet(`${CONFIG}${VIDEOS}` );
  };

  export const PostChangePassword = (data, token) => {
    return doPost(`${CUSTOMER}${CHANGE_PASSWORD}`, data, token);
  };

  export const PostCreateChat = (data, token) => {
    return doPost(`${CUSTOMER}${CHAT}${CREATE}`, data, token);
  };
  
  export const GetChatUserList = (user,token) => {
    return doGet(`${CUSTOMER}${CHAT}${GET}${LIST}${`?`}${`user`}=${user}`, token);
  };

  export const PostSelectedUserMessage = (data, token) => {
    return doPost(`${CUSTOMER}${CHAT}${GET}${MESSAGE}`,data, token);
  };

  export const SendMessage = (data, token) => {
    return doPost(`${CUSTOMER}${CHAT}${SEND}${MESSAGE}`, data, token);
  };

  export const SocialLinks = () => {
    return doGet(`${SOCIAL_MEDIA}`);
  };
  
  export const GetMyOffers = (currentPage,token) => {
    return doGet(`${CUSTOMER}${POST}${GETINSTANTOFFER}${`?`}${`page`}=${currentPage}`, token);
  };

  export const PostCarViews = (data, token) => {
    return doPost(`${VIEW_CAR}${STORE}`, data, token);
  };

  export const GetCarViews = () => {
    return doGet(`${VIEW_CAR}`, );
  };

  export const DeactivatePost = (id, token) => {
    return doGet(`${CUSTOMER}${POST}${UPDATE_STATUS}/${id}`, token);
  };

  export const GetReservedEvent = (currentPage, token) => {
    return doGet(`${CUSTOMER}${GET_EVENT}${`?`}${`page`}=${currentPage}`, token);
  };

  export const PostFavourite = (data, token) => {
    return doPost(`${CUSTOMER}${FAVOURITE}${STORE}`, data, token);
  };

  export const PostFavouriteDelete = (id, token) => {
    return doDelete(`${CUSTOMER}${FAVOURITE}${REMOVE}/${id}`, token);
  };

  export const GetAllFavourite = (currentPage, token) => {
    return doGet(`${CUSTOMER}${FAVOURITE}${`?`}${`page`}=${currentPage}`, token);
  };

  export const GetAllProducts = (currentPage, data) => {
    return doGet(
      `${PRODUCTS}${LATEST}${STATUS}=${data?.status}${`&`}${`start`}=${data?.start}${`&`}${`end`}=${data?.end}${`&`}${`page`}=${currentPage}`
    );
  };

  export const MerchanProductDeatils = (id) => {
    return doGet(`${PRODUCTS}${DETAILS}/${id}`,);
  };

  export const ApplyCoupons = (data) => {
    return doGet(`${COUPON}${APPLY}${CODE}=${data}`);
  };

  export const PlaceOrder = (data, token) => {
    return doPost(`${CUSTOMER}${ORDER}${PLACE}`, data, token);
  };
 
  export const GetMyOrders = (currentPage, token) => {
    return doGet(`${CUSTOMER}${ORDER}${LIST}${PAGE}=${currentPage}`,token);
  };

  export const LoanCalculator = (data) => {
    return doPost(`${LOAN}${CALCULATOR}`, data);
  };

  // export const PostEvents = (data, token) => {
  //   return doPost(`${CUSTOMER}${EVENTS}${STORE_EVENT}`, data, token);
  // };

  export const PostEvents = (data) => {
    return doPost(`${EVENTS}${STORE_EVENT}`, data);
  };

  export const AddWishList = (data, token) => {
    return doPost(`${CUSTOMER}${WISHLIST}${ADD}`, data, token);
  };

  export const GetWishList = (currentPage, token) => {
    return doGet(`${CUSTOMER}${WISHLIST}${PAGE}=${currentPage}`, token);
  };

  export const DeleteWishList = (id, token) => {
    return doDelete(`${CUSTOMER}${WISHLIST}${REMOVE}${PRODUCT_ID}=${id}`, token);
  };

  export const EventDelete = (id, token) => {
    return doGet(`${CUSTOMER}${EVENTS}${DELETE}/${id}`, token);
  };

  export const GetMagazine = () => {
    return doGet(`${MAGAZINE}`, );
  };
  

  // end CAR

  export const CompanyInfo = () => {
    return doGet(`${BUSINESS_SETTINGS}`);
  };

  // export const AllBlogs = () => {
  //   return doGet(`${BLOGS}${SHOW}`);
  // };

  // export const BlogDetails = (id) => {
  //   return doGet(`${BLOGS}${SHOW_SINGLE}/${id}`);
  // };

  export const GetCSHVideoById = (id) => {
    return doGet(`${CONFIG}${VIDEO}/${id}` );
  };



  // ------ end

  export const PostPriceFilter = (currentPage, data) => {
    return doGet(
      `${PRODUCTS}${SEARCH}${PRICE}${START}=${data?.start}&${END}=${
        data?.end
      }${`&category`}=${data?.id}`
    );
  };
  
  export const forgotPasswordOtp = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };


  
  
  // ---END --
  