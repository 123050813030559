import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { banner, call, contact, emails, location2, plybtn } from "../../constant";
import "../../assets/css/Contact.css";
import { toast } from "react-toastify";
import { PostContactUs } from "../../network/Network";
import { useSelector } from "react-redux";

function Contact() {
  const ContactData = useSelector((state) => state.AuthReducer.pagesData);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const PageDataRedux = useSelector((state) => state.AuthReducer.pagesData);

  const ContactUsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!firstName || !lastName || !email || !phone || !subject || !message) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (message.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile_number: phone,
      subject: subject,
      message: message,
    };
    // console.log(data);
    PostContactUs(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={contact} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="contactus" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2>Contact Us</h2>

              {ContactData?.map((item, index) => {
                return (
                  <>
                    {item?.type === "contact_us" ? (
                      <>
                        <p
                          dangerouslySetInnerHTML={{ __html: item?.value }}
                          key={index}
                        />
                      </>
                    ) : null}
                  </>
                );
              })}

              {/* <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes. Lorem ipsum dolor sit
                amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
                parturient montes.
              </p> */}
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-2"></div>
            <div className="col-lg-8 pt-lg-0 pt-md-5 pt-sm-5 pt-5">
              <form>
                <div className="form-group row">
                  <label for="FirstName" className="col-sm-2 col-form-label">
                    First Name
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="First Name"
                      placeholder="First Name*"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="Last Name" className="col-sm-2 col-form-label">
                    Last Name
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="FirstName"
                      placeholder="Last Name*"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="Your Email" className="col-sm-2 col-form-label">
                    Your Email
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="email"
                      className="form-control"
                      id="YourEmail"
                      placeholder="Last Name*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="Phone" className="col-sm-2 col-form-label">
                    Phone
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="number"
                      className="form-control"
                      id="Phone"
                      placeholder="Phone*"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label for="Phone" className="col-sm-2 col-form-label">
                    Subject
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="Phone"
                      placeholder="Subject*"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row pb-3">
                  <label for="Message" className="col-sm-2 col-form-label">
                    Message
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      name="Message"
                      className="form-control"
                      id="Message"
                      cols="30"
                      rows="10"
                      placeholder="Message*"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row pb-3">
                  <label
                    for="Message"
                    className="col-sm-2 col-form-label"
                  ></label>
                  <div className="col-sm-12">
                    <button
                      className="form-submit btn"
                      type="submit"
                      onClick={(e) => ContactUsHandler(e)}
                      disabled={loading}
                    >
                      {loading ? "Loading.." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-2"></div>
            <div className="col-lg-12">
              <div className="google-map">
                {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1002.1871024344003!2d-73.43632282854239!3d44.72750547395654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cca38d620e75c9b%3A0x5730d0fbc5f359f9!2sGreyhound%3A%20Bus%20Station!5e0!3m2!1sen!2s!4v1638385829735!5m2!1sen!2s"
                  width="100%"
                  height="606"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe> */}
              </div>
              <div className="row pt-5">
                <div className="col-lg-4">
                  <div className="contact-info p-3">
                    <a href="tel:+11235444588">
                      <img src={call} className="img-fluid" alt="" /> <br />
                      {PageDataRedux.map((item, index) => {
                        return (
                          <>
                            {item?.type == "company_phone" ? (
                              <span>{item?.value}</span>
                            ) : null}
                          </>
                        );
                      })}
                    </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-info p-3">
                    <a href="mailto:info@chs.com">
                      <img src={emails} className="img-fluid" alt="" /> <br />
                      {PageDataRedux.map((item, index) => {
                        return (
                          <>
                            {item?.type == "company_email" ? (
                              <span>{item?.value}</span>
                            ) : null}
                          </>
                        );
                      })}
                    </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-info p-3">
                    <a href="www.chs.com">
                      <img src={location2} className="img-fluid" alt="" />{" "}
                      <br />
                      www.carshowhero.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
