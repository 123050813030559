import React, { useState } from "react";
import Heart from "react-heart";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { merchan1 } from "../../constant";
import { Rating } from "react-simple-star-rating";
import { AddWishList } from "../../network/Network";
import { toast } from "react-toastify";

function MerchandiseProductCard(props) {
  const { index, item } = props;
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [active, setActive] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);
  const [productReview, setProductReview] = useState([]);

  const AddWishListHandler = () => {
    // e.preventDefault();
    if(!Token){
      toast.error("Please Login")
      return
    }
    setActive(!active);
    let data = {
      product_id: item?.id,
    };
    console.log(data);
    AddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        console.log(res);
        toast.success("Product Add to your Wishlist");
      })
      .catch((err) => {
        setWishLoading(false);
        console.log(err?.response);
        toast.error(err?.response?.data?.message);
      });
  };

  console.log(item)
  //   console.log(JSON.parse(item?.choice_options))

  return (
    <>
      <div className="col-md-6" key={index}>
        <div className="merchan-all">
          <div className="merchan-img">
            <Link to={`/merchandise-product-details/${item?.id}`}>
              <img
                className="img-fluid"
                src={`${ImageUrl?.product_thumbnail_url}/${item?.thumbnail}`}
                alt=""
              />
            </Link>
          </div>
          <div className="merchan-stars">
            {item?.rating.length > 0 ? (
              <>
                {item?.rating?.map((items, index) => {
                  return (
                    <Rating
                      size={22}
                      readonly={true}
                      ratingValue={
                        Math.round(items?.average) === 1
                          ? "20"
                          : Math.round(items?.average) === 2
                          ? "40"
                          : Math.round(items?.average) === 3
                          ? "60"
                          : Math.round(items?.average) == 4
                          ? "80"
                          : Math.round(items?.average) === 5
                          ? "100"
                          : null
                      }
                    />
                  );
                })}
              </>
            ) : null}
          </div>
          <div className="merchan-head merchan-line">
            <h3>{item?.name}</h3>
            <Heart
              inactiveColor={"black"}
              isActive={active}
              onClick={() => AddWishListHandler()}
              animationScale={1.25}
              style={{ marginBottom: "1rem" }}
              className="heart"
            />
          </div>
          <div className="merchan-para">
            <p
              className="fw-400"
              dangerouslySetInnerHTML={{ __html: item?.details }}
            />
          </div>

          <div className="merchan-head">
            <h4>${item?.unit_price}</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default MerchandiseProductCard;
