import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  buycarbanner,
  instant,
  instantOffer,
  plybtn,
} from "../../constant";
import "../../assets/css/BuyCar.css";
import ProductCard from "../../components/Card/ProductCard";
import ShowCaseCard from "../../components/ShowCase/ShowCaseCard";
import FindYourCar from "../../components/FindYourCar/FindYourCar";
import Slider from "react-slick/lib/slider";
import LogoSlider from "../../components/Slider/LogoSlider";
import MuiFeatures from "../../components/Accordian/MuiFeatures";
import { useSelector } from "react-redux";
import { GetCarListingInstantOffer, GetFilterCar } from "../../network/Network";
import { toast } from "react-toastify";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function InstantOffer() {
  const CarConditionRedux = useSelector(
    (state) => state.AuthReducer.carConditionData
  );
  const CarMakeModelRedux = useSelector(
    (state) => state.AuthReducer.carMakeModelData
  );
  const CarOptionDataRedux = useSelector(
    (state) => state.AuthReducer.carOptionData
  );
  const CarCategoryDataRedux = useSelector(
    (state) => state.AuthReducer.carCategoryData
  );
  const CarColorDataRedux = useSelector(
    (state) => state.AuthReducer.carColorData
  );
  const ShowCaseListDataRedux = useSelector(
    (state) => state.AuthReducer.showCaseListData
  );
  const [carConditionId, setCarConditionId] = useState("");
  const [carMakerId, setCarMakerId] = useState("");
  const [carModel, setCarModel] = useState("");
  const [selectMakerModel, setSelectMakerModel] = useState([]);
  const [carTransmission, setCarTransmission] = useState("");
  const [carDriveType, setCarDriveType] = useState("");
  const [carFuelType, setCarFuelType] = useState("");
  const [carStyle, setCarStyle] = useState("");
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [mileage, setMileage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [carInstantOfferData, setCarInstantOfferData] = useState([]);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // Get car Instant Offer -- not set
  const PostInstantOfferSearchHandler = () => {
    setSpinLoad(true);
    setLoading(true);
    let colorCode = color.replace("#", "");
    let data = {
      car_category_id: carStyle,
      make_id: carMakerId,
      model_id: carModel,
      condition_id: carConditionId,
      drive_type_id: carDriveType,
      transmission_id: carTransmission,
      color_code: colorCode,
      fuel_type_id: carFuelType,
      min_year: minYear,
      max_year: maxYear,
      min_price: minPrice,
      max_price: maxPrice,
      mileage: mileage,
    };
    GetCarListingInstantOffer(currentPage, data)
      .then((res) => {
        setLoading(false);
        setSpinLoad(false);
        setCarInstantOfferData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        setLoading(false);
        setSpinLoad(false);
      });
  };

  useEffect(() => {
    PostInstantOfferSearchHandler();
  }, [currentPage]);


  return (
    <>
      <Header />
      {/* <!-- Banner Start Here --> */}
      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={instantOffer} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                MusCLES
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Banner Start Here --> */}

      <div className="instant-top">
        <div className="container">
          <h2 className="title">CAR SHOW HERO INSTANT OFFER VEHICLES</h2>
          <p className="para">
            An INSTANT OFFER is an offer to buy a vehicle with no long term payment structure necessary.<br/>
            Full payment for the vehicle will be made once both the seller and the buyer come to an agreement on the final Price.<br/>
            All of the vehicle listed here are open for instant offers from auto dealerships or private individuals.
          </p>
        </div>
      </div>

      {/* <!-- Car Listing Section --> */}
      <section className="carlist py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="new-searchform mb-5" style={{ height: "auto" }}>
                <h3 className="text-white">Show results by:</h3>

                {/* <!-- tab content start here --> */}
                <div className="conditionDv">
                  <div className="title">
                    <h4>Make</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarMakerId(e.target.value);
                        setSelectMakerModel(
                          CarMakeModelRedux.find(
                            (val) => val?.id == e.target.value
                          ).car_model || []
                        );
                      }}
                    >
                      <option selected>Car Maker</option>
                      {CarMakeModelRedux?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="conditionDv">
                  <div className="title">
                    <h4>Model</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarModel(e.target.value);
                      }}
                    >
                      <option selected>Car Model</option>
                      {selectMakerModel?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="conditionDv">
                  <div className="title">
                    <h4>Condition</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarConditionId(e.target.value);
                      }}
                    >
                      <option selected>Car Condition</option>
                      {CarConditionRedux?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="conditionDv">
                  <div className="title">
                    <h4>Transmission</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarTransmission(e.target.value);
                      }}
                    >
                      <option selected>Car Transmission</option>
                      {CarOptionDataRedux?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "Transmission" ? (
                              <>
                                {item?.options?.map((item, index) => {
                                  return (
                                    <option value={item?.id} key={index}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="conditionDv">
                  <div className="title">
                    <h4>Car Category</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarStyle(e.target.value);
                      }}
                    >
                      <option selected>Car Category</option>
                      {CarCategoryDataRedux?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="conditionDv">
                  <div className="title">
                    <h4>Drive Type</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarDriveType(e.target.value);
                      }}
                    >
                      <option selected>Select Drive Type</option>
                      {CarOptionDataRedux?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "Drive Type" ? (
                              <>
                                {item?.options?.map((item, index) => {
                                  return (
                                    <option value={item?.id} key={index}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="conditionDv">
                  <div className="title">
                    <h4>Fuel Type</h4>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setCarFuelType(e.target.value);
                      }}
                    >
                      <option selected>Select Fuel Type</option>
                      {CarOptionDataRedux?.map((item, index) => {
                        return (
                          <>
                            {item?.name === "Fuel Type" ? (
                              <>
                                {item?.options?.map((item, index) => {
                                  return (
                                    <option value={item?.id} key={index}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : null}
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="yearsDv mt-2">
                  <div className="title">
                    <h4 className="mb-2 pb-0">Year</h4>
                  </div>
                  <div className="input-group">
                    <div className="form-group">
                      <label for="minyear">Minimum Year</label>
                      <input
                        type="number"
                        maxlength="4"
                        pattern="\d{4}"
                        className="form-control mr-1"
                        id="distance"
                        placeholder="Min Year"
                        value={minYear}
                        onChange={(e) => setMinYear(e.target.value)}
                      />
                    </div>
                    <div className="spacer"></div>
                    <div className="form-group">
                      <label for="maxyear">Maximum Year</label>
                      <input
                        type="number"
                        className="form-control mr-1"
                        id="distance"
                        placeholder="Max Year"
                        maxlength="4"
                        pattern="\d{4}"
                        value={maxYear}
                        onChange={(e) => setMaxYear(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="priceDv mt-2">
                  <div className="title">
                    <h4 className="mb-2 pb-0">Price</h4>
                  </div>
                  <div className="input-group">
                    <div className="form-group">
                      <label for="minyear">Min Price</label>
                      <input
                        type="number"
                        placeholder="Any"
                        className="form-control"
                        id="minyear"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                      />
                    </div>
                    <div className="spacer"></div>
                    <div className="form-group">
                      <label for="maxyear">Max Price</label>
                      <input
                        type="number"
                        placeholder="Any"
                        className="form-control"
                        id="maxyear"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mileageDv">
                  <div className="title">
                    <h4>Mileage</h4>
                  </div>
                  <div className="form-group">
                    <input
                      type="number"
                      placeholder="Mileage"
                      className="form-control"
                      id="maxyear"
                      value={mileage}
                      onChange={(e) => setMileage(e.target.value)}
                    />
                  </div>
                </div>

                <div className="exteriro_color">
                  <div className="title pt-2">
                    <h4>Colors</h4>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Color"
                      className="form-control"
                      id="maxyear"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />
                  </div>
                </div>

                <div className="button-group">
                  <button
                    className="btn"
                    onClick={(e) => PostInstantOfferSearchHandler(e)}
                  >
                    Submit
                  </button>
                </div>
                {/* <!-- tab content end here --> */}
              </div>
              {/* <!-- New Search form end here --> */}
            </div>
            <div className="col-lg-9 col-md-12">
              <div
                className="tab-pane fade show active"
                id="CARS"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {spinLoad ? (
                  <div className="loader-container">
                    <SpinnerCircular size="80px" color="#d60012" />
                  </div>
                ) : (
                  <>
                    {carInstantOfferData.length > 0 ? (
                      <>
                        {carInstantOfferData?.map((item, index) => {
                          return (
                            <ProductCard
                              item={item}
                              index={index}
                              page={"instant"}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <p>No Record Found</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <p className="inst-note">"All transactions are strictly between the buyer and seller - we are not responsible <br /> once the two parties make contact and come to an agreement on a price."</p>
          <div className="row paggination">
            <div className="col-sm-12 text-right">
              <div className="pagination-container mt-5">
                <ReactPaginate
                  previousLabel="<<"
                  nextLabel=">>"
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
       
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Car Listing Section --> */}
      <Footer />
    </>
  );
}

export default InstantOffer;
