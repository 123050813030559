import React from 'react'
import { useSelector } from 'react-redux';
import Slider from "react-slick";


function ActiveCarshowSlider(props) {
    const {item,index} = props;
    const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);

  return (
    <>
           <div className="img-box" key={index}>
                {/* <figure><img src={`${ImageUrl?.event}/${item?.icon_full_url}`} alt="" /></figure> */}
                <figure><img src={item?.icon_full_url} alt="" /></figure>
            </div>
    </>
  )
}

export default ActiveCarshowSlider