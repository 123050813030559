import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { PostSaveCard } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import StripeForm from "../Stripe/StripeForm";

function PaymentModal(props) {
  const { setIsOpenModal, isOpenModal } = props;
  const Token = useSelector((state) => state.AuthReducer.token);
  const [cardToken, setCardToken] = useState("")
  const [ paymentMethod, setPaymentMethod] = useState("")

  const PaymentHandler = async (e,visaToken) => {
    e.preventDefault();
    let waitToken = await visaToken;
    if(waitToken) {
        let data = {
            source: visaToken,
        };
         PostSaveCard(data,Token)
          .then((res) => {
            toast.success("Successfully card Add");
            setIsOpenModal(false)
          })
          .catch((err) => {
            console.log(err);
          });
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className=" orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <div className="inner-content">
          <h2 style={{ textAlign: "center" }}>Add Payment Card</h2>
          <div className="view_about_query_modal">
            <div className='mt-5'>
               <StripeForm 
                    PaymentHandler={PaymentHandler}
                    setCardToken={setCardToken}
                    cardToken={cardToken}
                    setPaymentMethod={setPaymentMethod}
                    page={"paymentMethod"}
                />
            </div>
                                                  
            </div>
        </div>
      </Modal>
    </>
  );
}

export default PaymentModal;
