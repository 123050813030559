import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { multiImg, Rolls } from "../../constant";
import Home from "../../pages/Home/Home";

function FindYourCar(props) {
  let navigate = useNavigate();
  const {
    CarMakeModelRedux,
    selectMakerModel,
    setSelectMakerModel,
    carMakerId,
    setCarMakerId,
    carModel,
    setCarModel,
    PostCarSearchHandler,
    CarSearchHandler,
    page,
    ParamData2,
  } = props;

  const CarCategoryDataRedux = useSelector(
    (state) => state.AuthReducer.carCategoryData
  );
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const location = useLocation();
  const ParamData = location?.state?.data;
  // console.log(ParamData);
  return (
    <>
      {/* <!-- find your dream car today --> */}
      <section className="dreamcar py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-4">
              {page == "Home" ? (
                <h2 className="wow animate__animated animate__fadeInLeft">
                  Find Your Dream Ride Today
                </h2>
              ) : (
                <h2
                  className="wow animate__animated animate__fadeInLeft"
                  style={{ fontSize: "24px" }}
                >
                  YOUR SEARCH BEGINS HERE
                </h2>
              )}

              <div className="car-search-form pt-5">
                <form action="">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-6">
                          <select
                            className="countries form-control"
                            onChange={(e) => {
                              setCarMakerId(e.target.value);
                              setSelectMakerModel(
                                CarMakeModelRedux.find(
                                  (val) => val?.id == e.target.value
                                ).car_model || []
                              );
                            }}
                          >
                            <option selected>MAKE</option>
                            {CarMakeModelRedux?.map((item, index) => {
                              return (
                                <option value={item?.id} key={index}>
                                  {item?.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <div className="option">
                            <select
                              className="FERRARI form-control"
                              onChange={(e) => {
                                setCarModel(e.target.value);
                              }}
                            >
                              <option selected>MODEL</option>
                              {selectMakerModel?.map((item, index) => {
                                return (
                                  <option value={item?.id} key={index}>
                                    {item?.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="form-submit"
                        onClick={(e) => {
                          page == "Home"
                            ? CarSearchHandler(e)
                            : PostCarSearchHandler(e);
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="manufacture mt-5">
                <div className="row align-items-center">
                  <div className="col-lg-8 align-self-center">
                    <div className="heading">
                      <h3>SHOP BY MANUFACTURER...</h3>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="button-group">
                      <button
                        onClick={() => navigate("/brands")}
                        class="form-submit"
                      >
                        Click Here
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div>
                      <img
                        src={multiImg}
                        alt=""
                        className="image-fluid"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="showcase-listing pt-5">
                {page == "Home" ? <h3>SHOP BY CATEGORY...</h3> : null}
                <ul>
                  {CarCategoryDataRedux?.map((item, index) => {
                    return (
                      <>
                        {index < 6 && ParamData2?.name != item?.name ? (
                          <li key={index}>
                            <div className="find_box">
                              <Link
                                // to={
                                //   page === "Home"
                                //     ? `/brands/${item?.id}`
                                //     : "/all-cars"
                                // }
                                // state={{ carStyle: item }}

                                to={`/all-cars`}
                                state={{ carStyle: item }}
                                
                                onClick={()=>{page != "Home" ? window.location.reload() : console.log("run")}}
                              >
                                <div className="img_box">
                                  <figure
                                    style={{
                                      height: "auto",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={`${ImageUrl?.category_image_url}/${item?.icon}`}
                                      alt=""
                                    />
                                  </figure>
                                </div>
                              </Link>
                            </div>
                          </li>
                        ) : null}
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- find your dream car today --> */}
    </>
  );
}

export default FindYourCar;
