import React from 'react'
import { Link } from 'react-router-dom';

function SideBar() {
    let currentUrl = window.location.href.split("/");
  return (
    <>
    <div className="sidebar">
        <ul>
          <li className={`${currentUrl?.[3] === "profile" ? "active" : null}`}>
            <Link to="/profile">My Profile</Link>
          </li>
          <li className={`${currentUrl?.[3] === "payment-method" ? "active" : null}`}>
            <Link to="/payment-method">Payment Card</Link>
          </li>
          <li className={`${currentUrl?.[3] === "my-orders" ? "active" : null}`}>
            <Link to="/my-orders">My Orders</Link>
          </li>
          <li className={`${currentUrl?.[3] === "my-offers" ? "active" : null}`}>
            <Link to="/my-offers">My Offers</Link>
          </li>
          {/* <li className={`${currentUrl?.[3] === "post-ads" ? "active" : null}`}>
            <Link to="/post-ads"> Post An Ads</Link>
          </li> */}
          <li className={`${currentUrl?.[3] === "my-ads" ? "active" : null}`}>
            <Link to="/my-ads"> Ads Listing</Link>
          </li>
          <li className={`${currentUrl?.[3] === "favourite" ? "active" : null}`}>
            <Link to="/favourite">Favourite Cars</Link>
          </li>
          <li className={`${currentUrl?.[3] === "favourite-tshirt" ? "active" : null}`}>
            <Link to="/favourite-tshirt">Favourite T-shirt</Link>
          </li>
          {/* <li className={`${currentUrl?.[3] === "my-events" ? "active" : null}`}>
            <Link to="/my-events"> My Events</Link>
          </li> */}
          {/* <li className={`${currentUrl?.[3] === "create-events" ? "active" : null}`}>
            <Link to="/create-events"> Create Events</Link>
          </li> */}
          {/* <li className={`${currentUrl?.[3] === "event-quries" ? "active" : null}`}>
            <Link to="/event-quries">Event Quries</Link>
          </li> */}
          <li className={`${currentUrl?.[3] === "change-password" ? "active" : null}`}>
            <Link to="/change-password">Change Password</Link>
          </li>
         
        </ul>
      </div>


    </>
  )
}

export default SideBar