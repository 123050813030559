import { faCamera, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { car1 } from "../../constant";
import { GetAllCard } from "../../network/Network";

function SubscriptionCard(props) {
  const {
    item,
    index,
    selectPackage,
    setSelectPackages,
    isOpenModal,
    setIsOpenModal,
    setNumImg,
  } = props;
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [paymentData, setPaymentData] = useState([]);

  // Get All Card
  useEffect(() => {
    GetAllCard(Token)
      .then((res) => {
        setPaymentData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isOpenModal]);

  const SubscriptionHandler = (e, item) => {
    e.preventDefault();
    setSelectPackages(item?.id);
    setNumImg(item?.total_image);
    if (paymentData.length < 1) {
      setIsOpenModal(true);
      return;
    }
  };

  return (
    <div className="col-lg-3" key={index}>
      <label
        className={
          selectPackage == item?.id
            ? "subscription_container boxShadow"
            : "subscription_container"
        }
        onClick={(e) => SubscriptionHandler(e, item)}
      >
        <div className="checkmark">
          <div className="subscription_box">
            <div
              className={
                selectPackage == item?.id
                  ? "subscription_name-green"
                  : "subscription_name"
              }
            >
              <h4>{item?.title}</h4>
            </div>
            {/* <div className="plan_img">
              <figure>
                <img src={`${ImageUrl?.package}/${item?.image}`} alt="" />
              </figure>
            </div> */}
            <div className="susbcription_content">
              <div className="pricing">
                <h4>
                  {
                    item?.price > 0 ? (
                      <span className="price">${item?.price}</span>
                    ) : (
                      <span className="price">FREE</span>
                    )
                  }
                  
                </h4>
              </div>
              <div className="description">
                {/* <p>{item?.description}</p> */}
                <p
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
              </div>
              <div className="button-group">
                <button
                  className={
                    selectPackage == item?.id ? "btn greenTick" : "btn red-btn"
                  }
                  onClick={(e) => SubscriptionHandler(e, item)}
                >
                  {" "}
                  {selectPackage == item?.id ? (
                    <FontAwesomeIcon icon={faCheck} className="edit-pen-icon" />
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
}

export default SubscriptionCard;
