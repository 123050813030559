// Define api end points here


export const AUTH = "/auth";
export const SIGNUP = "/register";
export const CONTACT = "/contact";
export const STORE = "/store";
export const SPONSORS = "/sponsors";
export const EVENT = "/event";
export const EVENTS = "/events";
export const CATEGORIES = "/categories";

export const FORGOT_PASSWORD = "/forgot-password";

export const PACKAGE = "/package";
export const CAR = "/car";
export const CONDITION = "/condition";
export const MAKE = "/make";

export const OPTIONS = "/options";
export const COLOR = "/color";
export const CATEGORY = "/category";
export const INSTANTOFFER = "/instant-offer";

export const ALL_LISTING = "/all-listing";
export const CAR_CATEGORY_ID = "?car_category_id";
export const MAKE_ID = "&make_id";
export const MODEL_ID = "&model_id";

export const SHOWCASE_LISTING = "/showcase-listing";

export const CUSTOMER = "/customer";
export const ORDER = "/order";
export const LIST = "/list";

export const POST = "/post";

export const UPDATE_PROFILE = "/update-profile";

export const ADD_LISTING = "/add-listing";
export const CONFIG = "/config";

export const BUSINESS_SETTINGS = "/business-settings";

export const PAYMENT_METHOD = "/payment-method";
export const SHOW_CARD = "/showCard";

export const REMOVE_CARD = "/removeCard";
export const UPDATE_DEFAULT_CARD = "/updateDefaultCard";

export const VIDEOS = "/videos"; 
export const CHANGE_PASSWORD = "/change-password";
export const CHAT = "/chat";
export const CREATE = "/create";
export const MESSAGE = "/message";
export const SEND = "/send";
export const GET = "/get";
export const SOCIAL_MEDIA = "/social-media";

export const GETINSTANTOFFER = "/getInstantOffer";

export const VIEW_CAR = "/view-car";
export const UPDATE_STATUS = "/updateStatus";
export const GET_EVENT = "/getEvent";

export const FAVOURITE = "/favourite";
export const PRODUCTS = "/products";
export const LATEST = "/latest";
export const STATUS = "?status";
export const DETAILS = "/details";
export const COUPON = "/coupon";
export const APPLY = "/apply";
export const CODE = "?code";
export const PLACE = "/place";
export const LOAN = "/loan";
export const CALCULATOR = "/calculator";
export const STORE_EVENT = "/store-event";

export const WISHLIST = "/wish-list";
export const ADD = "/add";
export const REMOVE = "/remove";
export const EDIT = "/edit";
export const DELETE = "/delete";

export const BLOGS = "/blogs";
export const SHOW = "/show";
export const SHOW_SINGLE = "/show_single";
export const VIDEO = "/video";

export const MAGAZINE = "/magazine";


// end 

export const LOGIN = "/login";
export const FAQ = "/faq";
export const CONTACTUS = "/contactUs";
export const NEWSLETTER = "/newsletter";
export const COMPANY_INFORMATION = "/company-information";



export const PRODUCT_ID = "?product_id";
export const REVIEWS = "/reviews";
export const SUBMIT = "/submit";
export const HOTDEALS = "/hot-deals";
export const RELATED_PRODUCTS = "/related-products";
export const TRADING = "/trading";

export const TRACK = "/track";
export const REMOVE_ALL = "/remove_all";
export const CUSTOMER_ID = "?customer_id";
export const LINKS = "/links";

export const SEARCH = "/search";
export const NAME = "?name";
export const BRANDS = "/brands";
export const PRICE = "/price";
export const START = "?start";
export const END = "end";

// export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const TOP_PRODUCTS = "/top-products";
export const PAGE = "?page";
export const GET_PAGE = "/get_pages";
export const TYPE = "?type";
export const QUESTION_STORE = "/QuestionStore";
export const STORE_USER_ADDRESS = "/storeUserAddress";



