import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { ramdomImage } from "../../constant/ConstantFunction";
import { UpdateProfile } from "../../network/Network";
import { EditProfileData } from "../../redux/actions/AuthActions";

function Profile() {
  const userData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const dispatch = useDispatch();
  const [editState, setEditState] = useState(false);
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const [firstName, setFirstName] = useState(userData?.f_name);
  const [lastName, setLastName] = useState(userData?.l_name);
  const [address, setAddress] = useState(userData?.address);
  const [country, setCountry] = useState(userData?.country);
  const [userState, setUserState] = useState(userData?.state);
  const [phone, setPhone] = useState(userData?.phone);
  const [zipCode, setZipCode] = useState(userData?.zip);

  const [loading, setLoading] = useState(false);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  const EditProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !phone ||
      !address ||
      !country ||
      !userState ||
      !zipCode
    ) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    let data = new FormData();
    data.append("f_name", firstName);
    data.append("l_name", lastName);
    data.append("phone", phone);
    data.append("address", address);
    data.append("country", country);
    data.append("state", userState);
    data.append("zip", zipCode);
    data.append("image", newFile);
    UpdateProfile(data, Token)
      .then((res) => {
        setLoading(false);
        setUploadLoading(false);
        toast.success(res?.data?.message);
        dispatch(EditProfileData(res?.data?.response?.data));
        setEditState(!editState);
      })
      .catch((err) => {
        setLoading(false);
        setUploadLoading(false);
      });
  };

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner profile-custom">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
            </div>
            <div className="flexBox-style">
              <div className="banner-img">
                {uploadLoading ? (
                  <img
                    src={fileupload}
                    className="image-fluid image-width"
                    style={{ height: "100%" }}
                    alt=""
                  />
                ) : (
                  <>
                    {userData?.image == "def.png" ? (
                      <img
                        src={`${ramdomImage(
                          `${userData?.f_name}{""}${userData?.l_name}`
                        )}`}
                        className="image-fluid image-width"
                        style={{ borderRadius: "50%" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={`${
                          fileupload
                            ? fileupload
                            : `${ImageUrl?.customer_image_url}/${userData?.image}`
                        }`}
                        className="image-fluid image-width"
                        style={{ height: "100%" }}
                        alt=""
                      />
                    )}
                  </>
                )}
              </div>
              {
                editState ? (
                  <div className="edit-container">
                  <FontAwesomeIcon icon={faCamera} className="edit-pen-icon" />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
                ) : (null)
              }
            
            </div>
          </div>
        </div>
      </section>

      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>Profile</h5>
                    </div>
                    {editState ? (
                      <div className="edit_btn">
                        <h5
                          onClick={(e) => EditProfile(e)}
                          style={{ cursor: "pointer" }}
                        >
                          {loading ? "Loading.." : "Save"}
                        </h5>
                      </div>
                    ) : (
                      <div className="edit_btn">
                        <h5
                          onClick={() => setEditState(true)}
                          style={{ cursor: "pointer" }}
                        >
                          Edit
                        </h5>
                      </div>
                    )}
                  </div>
                  {editState ? (
                    <>
                      <div className="user_details">
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="name details">
                              <h5 className="property">First Name</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="name details">
                              <h5 className="property">Last Name</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="address details">
                              <h5 className="property">Address</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="Address"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="city details">
                              <h5 className="property">Country</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="Country"
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="city details">
                              <h5 className="property">State</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="State"
                                  value={userState}
                                  onChange={(e) => setUserState(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="city details">
                              <h5 className="property">Phone</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="Phone"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="city details">
                              <h5 className="property">Zip</h5>
                              <h5 className="value">
                                <input
                                  type="text"
                                  placeholder="Phone"
                                  value={zipCode}
                                  onChange={(e) => setZipCode(e.target.value)}
                                />
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="user_details">
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="name details">
                              <h5 className="property">User </h5>
                              <h5 className="value">
                                {userData?.f_name} {userData?.l_name}
                              </h5>
                            </div>
                            <div className="email details">
                              <h5 className="property">Email*</h5>
                              <h5 className="value">{userData?.email}</h5>
                            </div>
                            <div className="address details">
                              <h5 className="property">Address</h5>
                              <h5 className="value">{userData?.address}</h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="city details">
                              <h5 className="property">Country</h5>
                              <h5 className="value">{userData?.country}</h5>
                            </div>
                            <div className="city details">
                              <h5 className="property">State</h5>
                              <h5 className="value">{userData?.state}</h5>
                            </div>
                            <div className="city details">
                              <h5 className="property">Phone</h5>
                              <h5 className="value">{userData?.phone}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
    </>
  );
}

export default Profile;
