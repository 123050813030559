import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { plybtn, WebBanner } from "../../constant";
import { GetCarMakers } from "../../network/Network";
import { CarMakeModel } from "../../redux/actions/AuthActions";

const Brands = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const makes = useSelector((state) => state?.AuthReducer?.carMakeModelData);
  const [make, setMake] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetCarMakers(id ? id : null)
      .then((res) => {
        setMake(res?.data?.response?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header />

      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="video-wrapper">
              <video className="videoTag" autoPlay loop muted>
                <source src={WebBanner} type="video/mp4" />
              </video>
            </div>
            <div className="ply-btn">
              <figure>
                <Link to="#">
                  <img src={plybtn} alt="123" />
                </Link>
              </figure>
            </div>
            <div className="title">
              <h2 className="wow animate__animated animate__bounce">
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="all_brands my-5">
        <div className="container">
          <div className="row align-items-center">
            {!loading && make.length > 0 ? (
              <>
                {make?.map((item, index) => {
                  return (
                    <div key={index} className="col-md-3 my-3">
                      <div className="brands-mainCont">
                        <Link
                          to={"/all-cars"}
                          state={{ brand: item, car_category_id: id }}
                        >
                          <div className="brandImg text-center">
                            <figure>
                              <img
                                className="img-fluid"
                                src={item?.icon_url}
                                width={100}
                                alt=""
                              />
                            </figure>
                            <div>
                              <p className="brands-name">{item?.title}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div>
                {loading ? (
                  <p className="text-center">Loading...</p>
                ) : (
                  <p className="text-center">Makes not found!</p>
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Brands;
