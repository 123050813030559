import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

function DownloadGame() {
  return (
    <>
    <Header />
    <section className="download-Game">
   
   </section>
    <Footer />
     
    </>
  )
}

export default DownloadGame