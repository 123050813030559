import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { banner, bannerShrt, buycar1, customizeRide, finCarShow, garage, heroPlate, ImageHolder, pdcar1, plybtn, videoPlate, vincheck } from "../../constant";
import "../../assets/css/ProductDetail.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProductImageRightSlider from "../../components/Slider/ProductImageRightSlider";
import { useSelector } from "react-redux";
import {
  GetAllFavourite,
  GetAllProducts,
  GetFilterCar,
  PostCarViews,
  PostCreateChat,
  PostFavourite,
} from "../../network/Network";
import Heart from "react-heart";
import { toast } from "react-toastify";

function ProductDetails() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Navigate = useNavigate();
  const location = useLocation();
  const ParamData = location?.state?.data;
  const [active, setActive] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);
  const [fvtData, setFvtData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [highToLow, setHighToLow] = useState(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10000);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CreateChat = (e) => {
    e.preventDefault();
    let data = {
      sender_id: userData?.id,
      receiver_id: ParamData?.user_id,
    };
    PostCreateChat(data, Token)
      .then((res) => {
        Navigate(`/chat/${ParamData?.name}`, {
          state: { data: res?.data?.response?.data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PostViewHandler = () => {
    let data = {
      user_post_id: ParamData?.id,
    };
    PostCarViews(data, Token)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    PostViewHandler();
  }, []);

  const AddWishListHandler = (e) => {
    e.preventDefault();
    setWishLoading(true);
    let data = {
      user_post_id: ParamData?.id,
    };
    PostFavourite(data, Token)
      .then((res) => {
        setWishLoading(false);
        setActive(!active);
        setActive(!active);
        toast.success("Car Add to your Favourite");
        // dispatch(WishListData(res?.data?.data?.wishlist))
      })
      .catch((err) => {
        setWishLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    GetAllFavourite(1,Token)
      .then((res) => {
        // console.log(res);
        setFvtData(res?.data?.response?.data?.data);
        let filterHeartData = res?.data?.response?.data?.data?.filter(
          (val) => val?.user_post_id === ParamData?.id
        );
        // console.log(filterHeartData);
        if (filterHeartData[0]?.user_post_id === ParamData?.id) {
          setActive(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [active]);

  const wishToastHandler = (e) => {
    e.preventDefault();
    toast.success("Post Already In your Favourite List");
  };

  useEffect(() => {
    GetFilterCar()
      .then((res) => {
        setVehicleData(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const data = {
      status: highToLow,
      start: start,
      end: end,
    };
    GetAllProducts(currentPage, data)
      .then((res) => {
        setProductsData(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
}, [])

const LoginHandler = (e) =>{
  e.preventDefault()
  toast.error("Please Login")
  Navigate("/login")
}

  return (
    <>
      <Header />
      {/* <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={banner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2 className="wow animate__animated animate__bounce">
                {" "}
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section> */}

      <section className="sellmycar-sec">
        <div className="sellmycar-head">
          <h2>Car Details</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <ProductImageRightSlider ParamData={ParamData} />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Nav tab sections --> */}
      <section className="nav-tabs-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="tabs-stuff">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                    >
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-3"
                      role="tab"
                    >
                      Seller info
                    </a>
                  </li>
                </ul>
                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p>Body Type:</p>
                          <p className="ml-2">
                            {ParamData?.body_type
                              ? ParamData?.body_type?.name
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Car Make:</p>
                          <p className="ml-2">
                            {ParamData?.car_make
                              ? ParamData?.car_make?.title
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Car Condition:</p>
                          <p className="ml-2">
                            {ParamData?.condition
                              ? ParamData?.condition?.title
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Fuel Type:</p>
                          <p className="ml-2">
                            {ParamData?.fuel_type
                              ? ParamData?.fuel_type?.name
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Mileage:</p>
                          <p className="ml-2">
                            {ParamData?.mileage ? ParamData?.mileage : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Price: </p>
                          <p className="ml-2">
                            {ParamData?.price ? ParamData?.price : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p>Car Category:</p>
                          <p className="ml-2">
                            {ParamData?.car_category
                              ? ParamData?.car_category?.name
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Car Model:</p>
                          <p className="ml-2">
                            {ParamData?.model ? ParamData?.model?.title : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Car Color:</p>
                          <p className="ml-2">
                            {ParamData?.color_code
                              ? ParamData?.color_code
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Drive Type:</p>
                          <p className="ml-2">
                            {ParamData?.drive_type
                              ? ParamData?.drive_type?.name
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Transmission Type:</p>
                          <p className="ml-2">
                            {ParamData?.transmission_type
                              ? ParamData?.transmission_type?.name
                              : "--"}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p>Year:</p>
                          <p className="ml-2">
                            {ParamData?.year ? ParamData?.year : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <p>Description: </p>
                      <p className="ml-2">{ParamData?.description}</p>
                    </div>
                  </div>
                  <div className="tab-pane" id="tabs-2" role="tabpanel">
                    {ParamData?.features?.map((item, index) => {
                      return <p key={index}>{item?.options?.name}</p>;
                    })}
                  </div>
                  <div className="tab-pane" id="tabs-3" role="tabpanel">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p>Name: </p>
                          <p className="ml-2">{ParamData?.name}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p>Email: </p>
                          <p className="ml-2">{ParamData?.email}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p>Phone: </p>
                          <p className="ml-2">{ParamData?.phone_no}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex">
                          <p>Address: </p>
                          <p className="ml-2">{ParamData?.address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="selller-contact-btn d-flex">
                {userData?.id != ParamData?.user_id ? (
                  <>
                  {
                    Token ? (
                      <>
                        <button
                      className="contact-chat"
                      onClick={(e) => CreateChat(e)}
                      // disabled={userData?.id != ParamData?.user_id ? false : true}
                    >
                      Contact seller
                    </button>

                    <button
                      className="contact-chat ml-3"
                      onClick={(e) => {
                        active ? wishToastHandler(e) : AddWishListHandler(e);
                      }}
                      disabled={wishLoading}
                    >
                      {wishLoading ? "Loading..." : "Favourite"}
                    </button>
                      </>
                    ) : (
                      <>
                        <button
                      className="contact-chat"
                      onClick={(e) =>LoginHandler(e)}
                    >
                      Contact seller
                    </button>

                    <button
                      className="contact-chat ml-3"
                      onClick={(e) =>LoginHandler(e)}
                    >
                      Favourite
                    </button>
                      </>
                    )
                  }
                    
                  </>
                ) : null}
              </div>
            </div>

            <div className="col-md-4">
              <div className="rec-head">
                <h2>recommendations</h2>
              </div>
              <div className="recomdations">
                {vehicleData?.map((item, index) => {
                  return (
                    <>
                      {index < 3 ? (
                        <div className="rec-1" key={index}>
                          {item?.images.length > 0 ? (
                            <img
                              className="img-fluid"
                              src={`${ImageUrl?.car_listing}/${item?.images[0]?.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              src={ImageHolder}
                              alt=""
                            />
                          )}
                          <div className="rec-text-1">
                            <h3>
                              {item?.car_make?.title} {item?.model?.title}
                            </h3>
                            <h5>Build year: {item?.year}</h5>
                            <h4>${item?.price}</h4>
                            <h5 className="for-uppercase">
                              <Link
                                to={`/product-details/${item?.id}`}
                                state={{ data: item }}
                              >
                                View Details
                              </Link>
                            </h5>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
              <div className="col-md-4 mt-5 mb-5">
              <a href="https://vincheck.info/" target="_blank" className="detail-footer">
                          <img src={vincheck} alt="" className="image-fluid vin-img" />

                        </a>
                      </div>
            </div>
            <div className="row">
                      <div className="col-md-4">
                        <Link to="/video" className="detail-footer">
                          <img src={videoPlate} alt="" className="image-fluid" />
                        </Link>
                      </div>
                      <div className="col-md-4">
                       <Link to={`/merchandise-product-details/${productsData[0]?.id}`} className="detail-footer">
                          <img src={garage} alt="" className="image-fluid" />
                        </Link>
                      </div>
                      <div className="col-md-4">
                      <Link to="/car-show" className="detail-footer">
                          <img src={finCarShow} alt="" className="image-fluid" />
                        </Link>
                      </div>
              </div>
          </div>
        </div>
      </section>
      {/* <!-- End of Nav tab sections  --> */}

      <Footer />
    </>
  );
}

export default ProductDetails;
