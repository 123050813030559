import React, { useEffect, useState } from "react";
import Slider from "react-slick/lib/slider";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  banner,
  notFound,
  plybtn,
} from "../../constant";
import "../../assets/css/MerchandiseProducts.css";
import MerchandiseProductCard from "../../components/Card/MerchandiseProductCard";
import { GetAllCategories, GetAllProducts } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

function MerchandiseProducts() {
  const [highToLow, setHighToLow] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [productImages, setProductImages] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10000);
  const [total, setTotal] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  const ProductHandler = () => {
    setSpinLoad(true);
    const data = {
      status: highToLow,
      start: start,
      end: end,
    };
    GetAllProducts(currentPage, data)
      .then((res) => {
        console.log(res);
        setProductsData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setTotal(res?.data?.response?.data?.total);
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  };

  useEffect(() => {
    ProductHandler();
  }, [currentPage, highToLow]);

  // Get Categories
  useEffect(() => {
    GetAllCategories()
      .then((res) => {
        console.log(res);
        setCategoriesData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={banner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcumbs">
        <div className="container">
          {/* <!-- breadcrumbs starts here --> */}
          {/* <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Product</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Page <span className="pageshow">1</span> of
                                    <span className="totalpage">1</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div> */}
          {/* <!-- breadcrumbs ends here --> */}
        </div>
      </section>

      <section className="featured-products">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="ecommerce-sidebar">
                <h3 className="sidebar-heading">SHOP BY CATEGORIES</h3>
                <div className="product-categories">
                  <ul className="categories">
                      {
                        categoriesData?.map((item,index)=>{
                            return(
                                <li className="categories-item" key={index}>
                                <a className="categories-link" href="#">
                                  <i
                                    className="fa fa-long-arrow-right"
                                    aria-hidden="true"
                                  ></i>
                                  {item?.name}
                                </a>
                              </li>
                            )
                        })
                      }
                   
                  </ul>
                </div>
              </div>

              {/* <div className="side-bar-filter">
                        <h3 className="sidebar-heading">FILTER PRICE</h3>
                            <div className="multi-range-container">
                                <div className="multi-range">
                                    <input className="range" type="range" min="0" max="10" value="0" step="0.1" id="lower" />
                                    <span id="range-color" className="range-color"></span>
                                    <input className="range" type="range" min="0" max="10" value="10" step="0.1" id="upper" />
                                </div>
                            </div>
                            <h4>Price: $49 — $86</h4>

                        </div> */}

              <div className="side-bar-filter">
                <h3 className="sidebar-heading">FILTER PRICE</h3>
                <div className="price-container">
                  <input
                    type="number"
                    placeholder="start"
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="End"
                    className="ml-2"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                  />
                </div>
                <div className="button-group">
                  <button
                    className="btn price-submit"
                    onClick={() => ProductHandler()}
                  >
                    Submit
                  </button>
                </div>
                <h4>
                  Price: ${start} — ${end}
                </h4>
              </div>

              {/* <div className="sider-bar-colors">
                            <h3 className="sidebar-heading">COLOR</h3>
                            <div className="new">
                                <div className="form-check-color">
                                    <div className="form-group">
                                        <input type="checkbox" id="html" />
                                        <label for="html">BLACK</label>
                                    </div>
                                    <div className="div-color" style={{backgroundColor:"#262626"}}></div>
                                </div>

                                <div className="form-check-color">
                                    <div className="form-group">
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                        <label for="vehicle1">GREY</label>
                                    </div>
                                    <div className="div-color" style={{backgroundColor: "#707070"}}></div>
                                </div>
                                <div className="form-check-color">
                                    <div className="form-group">
                                        <input type="checkbox" id="red"  />
                                        <label for="red">RED</label>
                                    </div>
                                    <div className="div-color" style={{backgroundColor:"#A40016"}}></div>
                                </div>
                                <div className="form-check-color">
                                    <div className="form-group">
                                        <input type="checkbox" id="blue"  />
                                        <label for="blue">BLUE</label>
                                    </div>
                                    <div className="div-color" style={{backgroundColor: "#0654AE"}}></div>
                                </div>
                                <div className="form-check-color">
                                    <div className="form-group">
                                        <input type="checkbox" id="yellow"  />
                                        <label for="yellow">YELLOW</label>
                                    </div>
                                    <div className="div-color" style={{backgroundColor:"#E89E00"}}></div>
                                </div>
                                <div className="form-check-color">
                                    <div className="form-group">
                                        <input type="checkbox" id="green" />
                                        <label for="green">GREEN</label>
                                    </div>
                                    <div className="div-color" style={{backgroundColor: "#00944F"}}></div>
                                </div>
                            </div>
                        </div> */}

              {/* <div className="side-bar-color-btn">
                            <a href="#">FILTERS</a>
                        </div> */}

              {/* <div className="side-bar-tag-product">
                            <h3 className="sidebar-heading">TAG PRODUCT</h3>
                            <div className="tag-product-anchor">
                                <a href="#">Sports</a>
                                <a href="#">Trending</a>
                            </div>
                            <div className="tag-product-anchor">
                                <a href="#">Male</a>
                                <a href="#">Big</a>
                            </div>
                            <div className="tag-product-anchor">
                                <a href="#">Price</a>
                                <a href="#">Woman</a>
                            </div>
                        </div>
                        <div className="sider-bar-brand-div">
                            <div className="ecommerce-sidebar">
                                <h3 className="sidebar-heading">BRAND</h3>
                                <div className="product-categories">
                                    <ul className="categories">
                                        <li className="categories-item"><a className="categories-link" href="#"><i
                                                    className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                All</a></li>
                                        <li className="categories-item"><a className="categories-link" href="#"><i
                                                    className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                Adidas</a></li>
                                        <li className="categories-item"><a className="categories-link" href="#"><i
                                                    className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                Waterloo</a></li>
                                        <li className="categories-item"><a className="categories-link" href="#"><i
                                                    className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                Uniqlo</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
            </div>
            <div className="col-lg-9">
              <div className="featured-and-icon">
                <div className="icons-featured">
                  {!highToLow || highToLow === "low_to_high" ? (
                    <button
                      className="btn btn-highLow"
                      onClick={() => setHighToLow("high_to_low")}
                    >
                      Sort By: High To low
                    </button>
                  ) : (
                    <button
                      className="btn btn-highLow ml-3"
                      onClick={() => setHighToLow("low_to_high")}
                    >
                      Sort By: Low To High
                    </button>
                  )}
                  {/* <div className="dropdown show">
                                    <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Featured
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div> */}
                </div>

                <div className="icons-heading-feature">
                  <h3>
                    Showing 1–{productsData?.length} of {total} results
                  </h3>
                </div>
              </div>
              <div className="row">
                {spinLoad ? (
                  <>
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  </>
                ) : (
                  <>
                    {/* {
                                productsData?.map((item, index) => {
                                    return(
                                        <MerchandiseProductCard item={item} index={index}/>
                                    )
                                } )
                            } */}
                    {productsData?.length > 0 ? (
                      productsData?.map((item, index) => {
                        return (
                          <MerchandiseProductCard item={item} index={index} />
                        );
                      })
                    ) : (
                      <>
                      <div className="notFound-container">
                        <img src={notFound} alt="not found" className="image-fluid" />
                        <p className="mt-4">Product not found</p>
                      </div>
                        
                      </>
                    )}
                  </>
                )}

                <div className="pagination-container mt-5">
                  <ReactPaginate
                    previousLabel="<<"
                    nextLabel=">>"
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default MerchandiseProducts;
