import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../assets/css/Login.css'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login } from '../../redux/actions/AuthActions'
import { useDispatch } from 'react-redux'

function Login() {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [ loading, setLoading ] = useState(false)


    const SignInHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email || !password) {
          toast.error("Please Enter All Fields");
          setLoading(false);
          return;
        }
        if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              email
            )
          ) {
            toast.error("Invalid Email");
            setLoading(false);
            return;
          }
          else {
            let data = {
                email: email,
                password: password,
              };
              setTimeout(async () => {
                setLoading(false);
                let x = await dispatch(login(data,setLoading));
                if (x) {
                  window.location.href = "/profile";
                  // Navigate("/profile");
                }
              }, 600);
          }
     
      };

  return (
   <>
    <Header />
    {/* <!-- Login form start here --> */}
<section className="login py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="login-form-content text-center">
                    <h2 className="pb-4">Login</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout.</p>
                </div>
                <div className="login-form pt-5">
                    <form>
                        <div className="form-group mt-2">
                            <input 
                                type="email" 
                                className="form-control" id="Email" 
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <input 
                                type="password" 
                                className="form-control" id="Password" 
                                aria-describedby="emailHelp"
                                placeholder="Password"
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </div>
                        <div className="row pt-2">
                            <div className="newshow col-md-5">
                                <p>New on Car Show Hero, <Link to="/signup">Signup </Link></p>
                            </div>
                            <div className="forpass offset-2 col-md-5">
                                <p className="text-right"><Link to="/forgot-password" >Forgot Password</Link></p>
                            </div>
                        </div>
                        <div className="row pt-4 justify-content-center">
                            <div className="loginasuser col-sm-5">
                                <button 
                                    className='form-control form-submit'
                                    onClick={(e)=>SignInHandler(e)}
                                    disabled={loading}
                                >
                                    {loading ? "Loading.." : "Login"}    
                                </button>
                            </div>
                           
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
    </div>
</section>
{/* <!-- Login form end here --> */}
    <Footer />
   </>
  )
}

export default Login