import React, { useEffect } from 'react'
import { banner, plybtn, salesCondition, terms } from '../../constant'
import '../../assets/css/TermCondition.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { useSelector } from 'react-redux'

function TermConditionSale() {
    const TermData = useSelector((state) => state.AuthReducer.pagesData);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <Header />
        <section className="Slidebanner">
    <div className="banner">
        <div className="slides">
            <div className="img-box">
                <figure><img src={salesCondition } alt="123" /></figure>
            </div>
            <div className="ply-btn">
                <figure><a href="#"><img src={plybtn} alt="123" /></a></figure>
            </div>
            <div className="title">
                <h2>Lamborghini<br /> Showcase</h2>
            </div>
        </div>
    </div>
</section>

<section className="terms-condition py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-sm-12">
                <h1 className="text-center pb-5">Terms & Conditions Of Sale</h1>
            </div>
            {
                TermData?.map((item,index)=>{
                    return(
                        <>
                            {
                                item?.type === "terms_and_condition_off_sale" ? (
                                    <>
                                    <div className="col-lg-12" key={index}>
                                        <p 
                                          dangerouslySetInnerHTML={{ __html: item?.value }}
                                        />
                                    </div>
                                   
                                    </>
                                ) : (null)
                            }
                        </>
                    )
                })
            }
        </div>
    </div>
</section>
<Footer />
    </>
  )
}

export default TermConditionSale