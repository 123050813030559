import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { finalLogo, logo, logo2, TACarShowHeroFinalFiles } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  CarMakeModel,
  CshVideos,
  logout,
  ShowCaseListing,
} from "../../redux/actions/AuthActions";
import { ramdomImage } from "../../constant/ConstantFunction";
import { toast } from "react-toastify";
import {
  GetCarMakers,
  GetCarShowcaseList,
  GetCSHVideo,
} from "../../network/Network";

function Header() {
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const CounterRedux = useSelector((state) => state.AuthReducer.counterData);
  const CarCategoryDataRedux = useSelector(
    (state) => state.AuthReducer.carCategoryData
  );
  const CartReduxData = useSelector((state) => state.CartReducer.cartData);
  const dispatch = useDispatch();
  let currentUrl = window.location.href.split("/");
  const [mobView, setMobView] = useState(false);
  const [loading, setLoading] = useState(false);

  const NavBarHandler = () => {
    setMobView(!mobView);
  };

  const LogoutHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(logout());
      window.location.href = "/login";
    }, 600);
  };

  const ListHandler = (e) => {
    e.preventDefault();
    toast.error("Please Login");
    Navigate("/login");
  };

  // Get car Showcase list
  useEffect(() => {
    GetCarShowcaseList()
      .then((res) => {
        dispatch(ShowCaseListing(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car maker
  useEffect(() => {
    GetCarMakers()
      .then((res) => {
        dispatch(CarMakeModel(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car CSH vIDEO -- not set
  useEffect(() => {
    GetCSHVideo()
      .then((res) => {
        dispatch(CshVideos(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <header>
        <div className="secondaryHeader">
          <div className="container">
            <ul className="secondary-items">
              {/* <li className="mr-5">
                <Link to="/download-game" className="text-white downloads">
                  Download Game
                </Link>
              </li> */}

              <li className="mr-5">
                <Link to="/term-condition">Terms</Link>
              </li>
              <li className="cartItem">
                <Link to="/cart">
                  <i className="fa fa-shopping-cart" aria-hidden="true">
                    <span className="chat-count">{CartReduxData?.length}</span>
                  </i>
                </Link>
              </li>
              <li className="wishlist mx-3">
                {Token ? (
                  <Link to="/favourite">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </Link>
                ) : (
                  <a onClick={(e) => ListHandler(e)}>
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </a>
                )}
              </li>
              <li className="comments">
                {Token ? (
                  <Link to="/chat">
                    <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    <span className="chat-count">{CounterRedux}</span>
                  </Link>
                ) : (
                  <a onClick={(e) => ListHandler(e)}>
                    <i className="fa fa-commenting-o" aria-hidden="true"></i>
                  </a>
                )}
              </li>
              <li className="ml-5 flexBox-style">
                {isLogin ? (
                  <>
                    <Link to="/profile">
                      <div className="header-profile">
                        {userData?.image == "def.png" ? (
                          <img
                            src={`${ramdomImage(
                              `${userData?.f_name}{""}${userData?.l_name}`
                            )}`}
                            className="image-fluid image-width"
                            style={{ borderRadius: "50%" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                            className="image-fluid image-width"
                            style={{ height: "100%" }}
                            alt=""
                          />
                        )}
                      </div>
                    </Link>
                    <a
                      className="ml-2 log"
                      onClick={(e) => LogoutHandler(e)}
                      style={{ cursor: "pointer" }}
                    >
                      {loading ? "Loading.." : "Logout"}
                    </a>
                  </>
                ) : (
                  <Link to="/login" className="log">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="main-header">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-2 col-sm-6 col-6">
                <div className="logoDv">
                  <figure>
                    <Link to="/">
                      <img src={TACarShowHeroFinalFiles} className="img-fluid" alt="" />
                    </Link>
                  </figure>
                </div>
              </div>
              <div className="col-md-10 col-sm-6 col-6">
                <div className="navbarMenu">
                  <ul className="navItems">
                    <li className="menu-items">
                      <Link
                        to="/"
                        className={`${
                          currentUrl?.[3] === "" ? "active" : null
                        }`}
                      >
                        Home
                      </Link>
                    </li>
                    {/* <li className="menu-items"><Link to="/all-cars"  className={`${currentUrl?.[3] === "all-cars" ? "active" : null}`}>Find My Car</Link></li> */}

                    <li className="menu-items nav-item dropdown">
                      <a
                        className={`${
                          currentUrl?.[3] === "all-cars"
                            ? "nav-link dropdown-toggle active"
                            : "nav-link dropdown-toggle"
                        }`}
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Classifieds
                        {/* <i className="fa fa-angle-down primary"></i> */}
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {CarCategoryDataRedux?.map((item, index) => {
                          return (
                            <Link
                              to={`/all-cars`}
                              state={{ carStyle: item }}
                              className="dropdown-item header-drop header-drop-font"
                              key={index}
                            >
                              {item?.name}
                            </Link>
                          );
                        })}
                      </div>
                    </li>

                    <li className="menu-items">
                      <Link
                        to="/instant-offer"
                        className={`${
                          currentUrl?.[3] === "instant-offer" ? "active" : null
                        }`}
                      >
                        Instant Offer
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/loan-calculator"
                        className={`${
                          currentUrl?.[3] === "loan-calculator" ? "active" : null
                        }`}
                      >
                        Loan Calculator
                      </Link>
                    </li>
                    <li className="menu-items">
                      {/* <Link
                        to="/customize"
                        className={`${
                          currentUrl?.[3] === "customize" ? "active" : null
                        }`}
                      >
                        Customize
                      </Link> */}
                      <Link
                        to="/video"
                        className={`${
                          currentUrl?.[3] === "video" ? "active" : null
                        }`}
                      >
                        Video
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/car-show"
                        className={`${
                          currentUrl?.[3] === "car-show" ? "active" : null
                        }`}
                      >
                        Car Shows
                      </Link>
                    </li>
                    {/* <li className="menu-items"><Link to="/merchandise"  className={`${currentUrl?.[3] === "merchandise" ? "active" : null}`}>Merchandise</Link></li> */}
                    <li className="menu-items">
                      <Link
                        to="/about-us"
                        className={`${
                          currentUrl?.[3] === "about-us" ? "active" : null
                        }`}
                      >
                        About
                      </Link>
                    </li>
                    <li className="menu-items btn">
                      {Token ? (
                        <Link
                          to="/list-your-ads"
                          className={`${
                            currentUrl?.[3] === "list-your-ads"
                              ? "active"
                              : null
                          }`}
                        >
                          List Your Vehicle
                        </Link>
                      ) : (
                        <a
                          onClick={(e) => ListHandler(e)}
                          className={`${
                            currentUrl?.[3] === "list-your-ads"
                              ? "active"
                              : null
                          }`}
                        >
                          List Your Vehicle
                        </a>
                      )}
                    </li>
                    <li className="menu-items btn ml-2">
                      <Link
                          to="/"
                          className={`${
                            currentUrl?.[3] === "list-your-ads"
                              ? "active"
                              : null
                          }`}
                          onClick={()=>toast.success("Dealer Listing is under Progress")}
                        >
                         List As Dealer
                        </Link>
                    </li>
                  </ul>
                  <div
                    className="canvas-icon text-white"
                    onClick={() => NavBarHandler()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- mobile header start --> */}
            <div
              className={`${mobView ? "mobile-header show" : "mobile-header"}`}
            >
              <div className="cancel" onClick={() => NavBarHandler()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
              <ul className="mobile-nav">
                <li className="nav-item">
                  <Link
                    to="/"
                    className={`${currentUrl?.[3] === "" ? "active" : null}`}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/all-cars"
                    className={`${
                      currentUrl?.[3] === "all-cars" ? "active" : null
                    }`}
                  >
                    Classifieds
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instant-offer"
                    className={`${
                      currentUrl?.[3] === "instant-offer" ? "active" : null
                    }`}
                  >
                    Instant Offer
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/loan-calculator"
                    className={`${
                      currentUrl?.[3] === "loan-calculator" ? "active" : null
                    }`}
                  >
                    Loan Calculator
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <Link
                    to="/customize"
                    className={`${
                      currentUrl?.[3] === "customize" ? "active" : null
                    }`}
                  >
                    Customize
                  </Link> */}
                   <Link
                    to="/video"
                    className={`${
                      currentUrl?.[3] === "video" ? "active" : null
                    }`}
                  >
                    Video
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/car-show"
                    className={`${
                      currentUrl?.[3] === "car-show" ? "active" : null
                    }`}
                  >
                    Car Shows
                  </Link>
                </li>
                {/* <li className="nav-item"><Link to="/merchandise"  className={`${currentUrl?.[3] === "merchandise" ? "active" : null}`}>Merchandise</Link></li> */}
                <li className="nav-item">
                  <Link
                    to="/about-us"
                    className={`${
                      currentUrl?.[3] === "about-us" ? "active" : null
                    }`}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                {Token ? (
                        <Link
                          to="/list-your-ads"
                          className={`${
                            currentUrl?.[3] === "list-your-ads" ? "active" : null
                          }`}
                        >
                          List Your Vehicle
                        </Link>
                      ) : (
                        <a
                          onClick={(e) => ListHandler(e)}
                          className={`${
                            currentUrl?.[3] === "list-your-ads" ? "active" : null
                          }`}
                        >
                          List Your Vehicle
                        </a>
                      )}
                </li>
              </ul>
              {/* <!-- secondary header items --> */}
              <ul className="secondary-items">
                {/* <li>
                  <Link
                    to="/download-game"
                    className="text-white downloads"
                    href="#"
                  >
                    Download Game
                  </Link>
                </li> */}
                <li className="flexBox-style navP-mob">
                  {isLogin ? (
                    <>
                      <Link to="/profile">
                        <div className="header-profile">
                          {userData?.image == "def.png" ? (
                            <img
                              src={`${ramdomImage(
                                `${userData?.f_name}{""}${userData?.l_name}`
                              )}`}
                              className="image-fluid image-width"
                              style={{ borderRadius: "50%" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                              className="image-fluid image-width"
                              style={{ height: "100%" }}
                              alt=""
                            />
                          )}
                        </div>
                      </Link>
                      <a
                        className="ml-2 log"
                        onClick={(e) => LogoutHandler(e)}
                        style={{ cursor: "pointer" }}
                      >
                        {loading ? "Loading.." : "Logout"}
                      </a>
                    </>
                  ) : (
                    <Link to="/login" className="log">
                      Login
                    </Link>
                  )}
                </li>
                <li className="mr-5">
                  <Link to="/term-condition">Terms</Link>
                </li>
                <li>
                  {Token ? (
                    <Link to="/favourite">
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </Link>
                  ) : (
                    <a onClick={(e) => ListHandler(e)}>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  )}
                </li>
                <li className="cartItem">
                  <Link to="/cart">
                    <i className="fa fa-shopping-cart" aria-hidden="true">
                      <span className="chat-count">
                        {CartReduxData?.length}
                      </span>
                    </i>
                  </Link>
                </li>
                <li className="comments">
                  {Token ? (
                    <Link to="/chat">
                      <i className="fa fa-commenting-o" aria-hidden="true"></i>
                      <span className="chat-count">{CounterRedux}</span>
                    </Link>
                  ) : (
                    <a onClick={(e) => ListHandler(e)}>
                      <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
