import "./App.css";
import "./assets/css/Style.css";
import "./assets/css/Custom.css";
import PublicRoutes from "./routes/PublicRoutes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import {
  CompanyInfo,
  GetAllCard,
  GetAllEvents,
  GetCarCategory,
  GetCarColors,
  GetCarConditions,
  GetCarMakers,
  GetCarOptions,
  GetCarShowcaseList,
  GetCSHVideo,
  GetImageUrls,
  GetMyOffers,
  GetPagesApi,
  SocialLinks,
} from "./network/Network";
import { useDispatch, useSelector } from "react-redux";
import {
  AllEvents,
  CarCategory,
  CarColor,
  CarConditionData,
  CarMakeModel,
  CarOptions,
  CompanyInfoStore,
  CounterData,
  CshVideos,
  ImageUrlData,
  PagesData,
  ShowCaseListing,
  SocialMediaStore,
} from "./redux/actions/AuthActions";
import Unity, { UnityContext } from "react-unity-webgl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { pusher } from ".";

const unityContext = new UnityContext({
  // loaderUrl: "/Build/CSHWebGLPackage.loader.js",
  // dataUrl: "/Build/CSHWebGLPackage.data",
  // frameworkUrl: "/Build/CSHWebGLPackage.framework.js",
  // codeUrl: "/Build/CSHWebGLPackage.wasm",
  // loaderUrl: "/Build/CSHWebGLPackage2.loader.js",
  // dataUrl: "/Build/CSHWebGLPackage2.data",
  // frameworkUrl: "/Build/CSHWebGLPackage2.framework.js",
  // codeUrl: "/Build/CSHWebGLPackage2.wasm",
  // webGLContextAttributes: {
  //   failIfMajorPerformanceCaveat: true,
  //   powerPreference: "high-performance",
  // },
});

function App() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const CounterRedux = useSelector((state) => state.AuthReducer.counterData);
  const [counter, setCounter] = useState(CounterRedux);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectState, setSelectState] = useState("");

  // Get Pages Data
  useEffect(() => {
    GetPagesApi()
      .then((res) => {
        dispatch(PagesData(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get Images url
  useEffect(() => {
    GetImageUrls()
      .then((res) => {
        dispatch(ImageUrlData(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Conditions
  useEffect(() => {
    GetCarConditions()
      .then((res) => {
        dispatch(CarConditionData(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car maker
  useEffect(() => {
    GetCarMakers()
      .then((res) => {
        dispatch(CarMakeModel(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Options, Transmission
  useEffect(() => {
    GetCarOptions()
      .then((res) => {
        dispatch(CarOptions(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Category
  useEffect(() => {
    GetCarCategory()
      .then((res) => {
        dispatch(CarCategory(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Colors
  useEffect(() => {
    GetCarColors()
      .then((res) => {
        dispatch(CarColor(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Showcase list
  useEffect(() => {
    GetCarShowcaseList()
      .then((res) => {
        dispatch(ShowCaseListing(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car CSH vIDEO -- not set
  useEffect(() => {
    GetCSHVideo()
      .then((res) => {
        dispatch(CshVideos(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get Social Links
  useEffect(() => {
    SocialLinks()
      .then((res) => {
        dispatch(SocialMediaStore(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get Events
  useEffect(() => {
    GetAllEvents(selectState, currentPage)
      .then((res) => {
        dispatch(AllEvents(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  

  const channel = pusher.subscribe(`message.${userData?.id}`);
  useEffect(() => {
    channel.bind("App\\Events\\MessageEvent", (data) => {
      if (data?.msg) {
        // console.log("event called", data?.msg);
        let one = "1";
        let count = `${CounterRedux + one}`;
        // console.log("count", count);
        dispatch(CounterData());
      }
    });
  }, []);

  // console.log(process.env.REACT_APP_PUSHER_KEY)

  return (
    <>
      <PublicRoutes unityContext={unityContext} />
      <ToastContainer />
    </>
  );
}

export default App;
