import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/Cart.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DecrementProductQuantity,
  deleteCartItem,
  IncrementProductQuantity,
} from "../../redux/actions/CartActions";
import { ApplyCoupons } from "../../network/Network";
import { toast } from "react-toastify";
import { notFound } from "../../constant";

function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CartData = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);

  const [subTotal, setSubTotal] = useState(0);
  const [cartData, setCartData] = useState([CartData]);
  const [couponData, setCouponData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState("");

  const Increment = (id) => {
    dispatch(IncrementProductQuantity(id));
  };
  const Decrement = (id) => {
    dispatch(DecrementProductQuantity(id));
  };

  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < CartData.length; i++) {
      // console.log("cart", CartData[i]?.price);
      total = total + CartData[i]?.price * CartData[i]?.quantity;
    }
    setSubTotal(total);
  };

  useEffect(() => {
    TotalAmount();
  }, [CartData]);

  const RemoveCartItem = (id) => {
    const items = cartData;
    let currentItem = id;
    if (items.length > 0) {
      setCartData(
        items.filter((item, index) => item?.productitem?.id !== currentItem)
      );
      dispatch(deleteCartItem(currentItem));
      //   setPostList(items.filter((item, index) => index !== currentItem));
    }
  };

  const ApplyCouponsHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(coupons);
    ApplyCoupons(coupons)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setCouponData(res?.data?.data?.coupon);
        toast.success("Successfully Apply");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response?.data?.message);
        toast.error(err?.response?.data?.message);
      });
  };

  const loginHandler = (e) => {
    e.preventDefault();
    if (!isLogin) {
      toast.error("Please Login");
      navigate("/login");
      return;
    }
    if (CartData?.length < 1) {
      toast.error("Please Add Products ");
      return;
    }
  };

  const filterVariationQty = (type, variation) => {
    if (JSON.parse(variation)) {
      // console.log(
      //   "filterVariationQty Array",
      //   JSON.parse(variation).filter((val) => val.type === type)
      // );
      let newVarient = JSON.parse(variation).filter((val) => val.type === type);
      if (newVarient.length > 0) {
        return newVarient[0].qty || 1;
      }
      return 1;
    }
    return 1;
  };

  return (
    <>
      <Header />
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="heading">
            <h1 className="text-white">Cart</h1>
          </div>
        </div>
      </section>

      <section className="cart-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="CartTable">
                <table className="table-responsive">
                  <tr className="topBar">
                    <th className="pro-detail">Product Details</th>
                    <th className="price">Price</th>
                    <th className="price">Quantity</th>
                    <th className="price">Total</th>
                    <th className="price">Remove</th>
                  </tr>
                  {CartData?.length > 0 ? (
                    <>
                      {CartData?.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td className="pro-detail" key={index}>
                                <div className="proFlex">
                                  <div className="imgBox">
                                    <figure className="image-container">
                                      <img
                                        src={`${ImageUrl?.product_thumbnail_url}/${item?.productitem?.thumbnail}`}
                                        alt=""
                                        className="image-width"
                                      />
                                    </figure>
                                  </div>
                                  <div className="contentDv">
                                    <h3 className="name">
                                      {item?.productitem?.name}
                                    </h3>
                                    <div className="variation">
                                      <p>
                                        Color :{" "}
                                        <span className="color">
                                          {item?.color}
                                        </span>
                                      </p>
                                      <p>
                                        Size:{" "}
                                        <span className="code">
                                          {item?.size}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="price">
                                <span className="priceDv">
                                  ${item?.productitem?.unit_price}
                                </span>
                              </td>
                              <td className="price">
                                <span className="priceDv quantity-container">
                                  <button
                                    className="btn quantity-btn"
                                    onClick={() => Decrement(item?.id)}
                                  >
                                    -
                                  </button>
                                  <div className="qty-num">
                                    {item?.quantity}
                                  </div>
                                  <button
                                    className={`btn quantity-btn ${filterVariationQty(
                                      item?.type,
                                      item?.productitem?.variation
                                    )}`}
                                    onClick={() => Increment(item?.id)}
                                    disabled={
                                      filterVariationQty(
                                        item?.type,
                                        item?.productitem?.variation
                                      ) == item?.quantity
                                    }
                                  >
                                    +
                                  </button>
                                </span>
                              </td>
                              <td className="price">
                                <span className="totalPrice">
                                  $
                                  {item?.quantity *
                                    item?.productitem?.unit_price}
                                </span>
                              </td>
                              <td className="price">
                                <div
                                  className="ml-2 deleteBtn"
                                  onClick={() =>
                                    RemoveCartItem(item?.productitem?.id)
                                  }
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="notFound-container">
                    <img
                      src={notFound}
                      alt="not found"
                      className="image-fluid"
                    />
                    <p className="mt-4">No Record Found</p>
                  </div>
                  )}
                </table>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="orderSummary">
                <div className="head">
                  <h4>ORDER SUMMARY</h4>
                </div>
                {CartData?.map((item, index) => {
                  return (
                    <div className="items row" key={index}>
                      <div className="count col-md-6">
                        <h4>{item?.productitem?.name}</h4>
                      </div>
                      <div className="price col-md-3">
                        <h4>{item?.quantity}X</h4>
                      </div>
                      <div className="price col-md-3">
                        <h4>${item?.productitem?.unit_price}</h4>
                      </div>
                    </div>
                  );
                })}

                <div className="totalDv">
                  <div className="property">
                    <h4>Total Cost</h4>
                  </div>
                  <div className="value">
                    <h4>
                      $
                      {couponData?.discount
                        ? subTotal - couponData?.discount
                        : subTotal}
                    </h4>
                  </div>
                </div>
                <div className="button-group">
                  {isLogin && CartData?.length > 0 ? (
                    <Link
                      to={`/checkout`}
                      state={{
                        data: {
                          CartData: CartData,
                          total: subTotal,
                          couponData: couponData,
                        },
                      }}
                      className="btn"
                    >
                      Proceed to checkout
                    </Link>
                  ) : (
                    <>
                      <button
                        onClick={(e) => loginHandler(e)}
                        className="btn proceed-btn"
                      >
                        Proceed to checkout
                      </button>
                    </>
                  )}
                </div>

             
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Cart;
