import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

function AllMessages(props) {
  const { allMessages, MessageRedux , messagesEndRef} = props;
  const userData = useSelector((state) => state.AuthReducer.users);

  return (
    <>
      <div className="messages">
        {MessageRedux?.map((item, index) => {
          return (
            <>
              {item?.sender.id == userData?.id ? (
                <div className="your_message chatting" key={index}>
                  <div className="message_box">
                    <p className={item?.type != "message" ? "sms-red" : "sms"}>{item?.message}</p>
                    <p className="date">
                      {moment(item?.updated_at).format("h:mm a")}
                    </p>
                  </div>
                  <div ref={messagesEndRef} />
                </div>
              ) : (
                <div className="other_message chatting" key={index}>
                  <div className="message_box">
                    <p className={item?.type != "message" ? "sms-red" : "sms"} >{item?.message}</p>
                    <p className="date">
                      {moment(item?.updated_at).format("h:mm a")}
                    </p>
                  </div>
                  <div ref={messagesEndRef} />
                </div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}

export default AllMessages;
