import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ImageHolder, productDetail } from "../../constant";

function ProductImageSlider(props) {
  const { ParamData, page } = props;
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const DummyData = [
    {
      id: 1,
      img: ImageHolder,
    },
    {
      id: 2,
      img: ImageHolder,
    },
    {
      id: 3,
      img: ImageHolder,
    },
  ];

  return (
    <>
      {page === "merchandise" ? (
        <>
          {ParamData?.length > 0 ? (
            <div className="product-slide">
              <Slider asNavFor={nav2} ref={slider1} arrows={false}>
                {ParamData?.map((item, index) => {
                  return (
                    <div
                      className="product-images mainimage-container"
                      key={index}
                    >
                      <figure className="merch-mainImg">
                        <img
                          src={`${ImageUrl?.product_image_url}/${item}`}
                          alt=""
                          className="image-fluid image-width merch-pImg"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>

              <Slider
                asNavFor={nav1}
                ref={slider2}
                slidesToShow={ParamData?.length > 3 ? 3 : ParamData?.length}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                className="slick-custom"
              >
                {ParamData?.map((item, index) => {
                  return (
                    <div className="inner-image-container" key={index}>
                      <figure>
                        <img
                          src={`${ImageUrl?.product_image_url}/${item}`}
                          alt=""
                          className="image-fluid image-width slider-image-opt"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <div className="product-slide">
              <Slider asNavFor={nav2} ref={slider1} arrows={false}>
                {DummyData?.map((item, index) => {
                  return (
                    <div
                      className="product-images mainimage-container"
                      key={index}
                    >
                      <figure>
                        <img
                          src={item?.img}
                          alt=""
                          className="image-fluid image-width "
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>

              <Slider
                asNavFor={nav1}
                ref={slider2}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                className="slick-custom"
              >
                {DummyData?.map((item, index) => {
                  return (
                    <div className="inner-image-container" key={index}>
                      <figure>
                        <img
                          src={item?.img}
                          alt=""
                          className="image-fluid image-width"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
        </>
      ) : (
        <>
          {ParamData?.images?.length > 0 ? (
            <div className="product-slide">
              <Slider asNavFor={nav2} ref={slider1} arrows={false}>
                {ParamData?.images?.map((item, index) => {
                  return (
                    <div
                      className="product-images mainimage-container"
                      key={index}
                    >
                      <figure className="merch-mainImg">
                        <img
                          src={`${ImageUrl?.car_listing}/${item?.image}`}
                          alt=""
                          className="image-fluid image-width merch-pImg"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>

              <Slider
                asNavFor={nav1}
                ref={slider2}
                slidesToShow={
                  ParamData?.images?.length > 3 ? 3 : ParamData?.images?.length
                }
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                className="slick-custom"
              >
                {ParamData?.images?.map((item, index) => {
                  return (
                    <div className="inner-image-container" key={index}>
                      <figure>
                        <img
                          src={`${ImageUrl?.car_listing}/${item?.image}`}
                          alt=""
                          className="image-fluid image-width slider-image-opt"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <div className="product-slide">
              <Slider asNavFor={nav2} ref={slider1} arrows={false}>
                {DummyData?.map((item, index) => {
                  return (
                    <div
                      className="product-images mainimage-container"
                      key={index}
                    >
                      <figure>
                        <img
                          src={item?.img}
                          alt=""
                          className="image-fluid image-width"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>

              <Slider
                asNavFor={nav1}
                ref={slider2}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                className="slick-custom"
              >
                {DummyData?.map((item, index) => {
                  return (
                    <div className="inner-image-container" key={index}>
                      <figure>
                        <img
                          src={item?.img}
                          alt=""
                          className="image-fluid image-width"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ProductImageSlider;
