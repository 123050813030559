import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { event1, myevent, notFound } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import { EventDelete, GetReservedEvent } from "../../network/Network";
import moment from "moment";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function MyEvents() {
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [editEvent, setEditEvent] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [myEvents, setMyEvents] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // GET  EVENT
  useEffect(() => {
    setSpinLoad(true);
    GetReservedEvent(currentPage, Token)
      .then((res) => {
        setMyEvents(res?.data?.response?.data?.data);
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteEventHandler = (id, index) => {
    setSpinLoad(true);
    EventDelete(id, Token)
      .then((res) => {
        setSpinLoad(false);
        setMyEvents((preval) => preval.filter((val) => val?.id != id));
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  };

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>My Events</h5>
                    </div>
                  </div>
                  {editEvent ? (
                    <div className="edit_events_dv ">
                      <div className="events_picture">
                        <h5>Event Picture:</h5>
                      </div>
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <label for="imageUpload">Change Photos</label>
                          <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                        <div className="avatar-preview">
                          <div
                            id="imagePreview"
                            style={{ backgroundImage: `url(${myevent})` }}
                          ></div>
                        </div>
                      </div>
                      <div className="enter_events_details">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="evntname">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="evntname"
                                value="Spring Fest"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="evntdate">Date</label>
                              <input
                                type="date"
                                value="2022-02-20"
                                className="form-control"
                                id="evntdate"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="evntlocation">Location</label>
                              <input
                                type="text"
                                className="form-control"
                                id="evntlocation"
                                value="TC Energy Center, Downtown, Houston"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label for="evnttime">Time</label>
                              <input
                                type="time"
                                className="form-control"
                                id="evnttime"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label for="evntdesc">Discription</label>
                              <textarea id="evntdesc" className="form-control">
                                Contrary to popular belief, Lorem Ipsum is not
                                simply random text. It has roots in a piece of
                                classNameical Latin literature from 45 BC,
                                making it over 2000 years old. Richard
                                McClintock, a Latin professor at Hampden-Sydney
                                College in Virginia, looked up one of the more
                                obscure Latin words, consectetur, from a Lorem
                                Ipsum passage.
                              </textarea>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <button
                              className="contact_btn btn save_event_changes"
                              onClick={() => setEditEvent(false)}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {spinLoad ? (
                        <div className="loader-container">
                          <SpinnerCircular size="80px" color="#d60012" />
                        </div>
                      ) : (
                        <div className="table-responsive events_tables">
                          {myEvents?.length > 0 ? (
                            <table className="table events_table">
                              <tr className="topbar">
                                <th className="sno">S.NO</th>
                                <th className="event">Event Name</th>
                                <th className="date">Date</th>
                                <th className="time">Time</th>
                                <th className="location">Location</th>
                                <th className="location">Remove</th>
                                {/* <th className="view_queries">View Quries</th> */}
                              </tr>

                              {myEvents?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="sno">{index + 1}</td>
                                    <td className="event">
                                      <div className="order_product">
                                        <div className="img_box">
                                          <Link to="/car-show">
                                            <figure>
                                              <img
                                                src={
                                                  item?.icon_full_url
                                                }
                                                alt=""
                                              />
                                            </figure>
                                          </Link>
                                        </div>
                                        <div className="order_content">
                                          <h5 className="order-name">
                                            {item?.title}
                                          </h5>
                                          
                                        </div>
                                      </div>
                                    </td>
                                    <td className="date">{item?.date}</td>
                                    <td className="time">{moment(item?.time,"hh:mm:ss").format('LT')}</td>
                                    <td className="location">
                                      {item?.address}
                                    </td>
                                   
                                    <td>
                                        <div
                                            className="deleteBtn"
                                            onClick={() =>
                                                DeleteEventHandler(item?.id)
                                            }
                                        >
                                            <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                            ></i>
                                        </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          ) : (
                            <div className="notFound-container">
                              <img
                                src={notFound}
                                alt="not found"
                                className="image-fluid"
                              />
                              <p className="mt-4">No Record Found</p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}

                    <div className="pagination-container mt-5">
                        <ReactPaginate
                          previousLabel="<<"
                          nextLabel=">>"
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>

                  {/* <!-- Modal --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
    </>
  );
}

export default MyEvents;
