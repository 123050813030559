import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { editAds, ImageHolder, lisiting, notFound } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import {
  DeactivatePost,
  EditPostCarListing,
  GetMyCarListing,
} from "../../network/Network";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import EditCarPostModal from "../../components/Modal/EditCarPostModal";
import { toast } from "react-toastify";

function MyAds() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Navigate = useNavigate();
  const [spinLoad, setSpinLoad] = useState(false);
  const [myListingData, setMyListingData] = useState([]);
  const [deactLoad, setDeactLoad] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // Get My Car listing
  useEffect(() => {
    setSpinLoad(true);
    GetMyCarListing(Token,currentPage)
      .then((res) => {
        setMyListingData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage, deactLoad]);

  const DeactivateHandler = (e, id) => {
    e.preventDefault();
    setDeactLoad(true);
    DeactivatePost(id, Token)
      .then((res) => {
        setDeactLoad(false);
        // setMyListingData(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        setDeactLoad(false);
      });
  };

  const EditPostOpen = (e, item) => {
    e.preventDefault();
    // console.log(item);
    setTitle(item?.title);
    setDescription(item?.description);
    setPhone(item?.phone_no);
    setAddress(item?.address);
    setIsOpenModal(true);
    setSelectedData(item);
  };

  const EditPostCarListHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = new FormData();
    data.append("phone_no", phone);
    data.append("address", address);
    data.append("title", title);
    data.append("description", description);
    data.append("id", selectedData?.id);
    EditPostCarListing(data, Token)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        toast.success(res?.data?.message);
        setIsOpenModal(false);
        // Navigate("/my-ads");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="adds_listingss">
                    <div className="profile_strip">
                      <div className="heading_wrapper">
                        <h5>Ads Listing</h5>
                      </div>
                    </div>
                    {spinLoad ? ( 
                      <div className="loader-container">
                        <SpinnerCircular size="80px" color="#d60012" />
                      </div>
                    ) : (
                      <>
                        {myListingData?.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table ads_listing">
                              <tr className="topbar">
                                <th className="sno">S.NO</th>
                                <th className="selling">
                                  What are you selling?
                                </th>
                                {/* <th className="chat">Chat</th> */}
                                <th className="price">Year</th>
                                <th className="price">Price</th>
                              </tr>
                              {myListingData?.length > 0 ? (
                                <>
                                  {myListingData?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="sno">{index + 1}</td>
                                        <td className="selling">
                                          <div className="order_product">
                                            <div
                                              className="img_box"
                                              onClick={() =>
                                                Navigate(
                                                  `/my-ads/ads-details/${item?.id}`,
                                                  { state: { data: item } }
                                                )
                                              }
                                            >
                                              <figure>
                                                {item?.images.length > 0 ? (
                                                  <img
                                                    src={`${ImageUrl?.car_listing}/${item?.images[0]?.image}`}
                                                    alt=""
                                                  />
                                                ) : (
                                                  <img
                                                    src={ImageHolder}
                                                    alt=""
                                                  />
                                                )}
                                              </figure>
                                            </div>
                                            <div className="order_content">
                                              <h5
                                                className="name"
                                                onClick={() =>
                                                  Navigate(
                                                    `/my-ads/ads-details/${item?.id}`,
                                                    { state: { data: item } }
                                                  )
                                                }
                                              >
                                                {item?.car_make?.title}{" "}
                                                {item?.model?.title}
                                              </h5>
                                              <p className="desc">
                                                {moment(
                                                  item?.created_at
                                                ).format("MMMM Do YYYY, h:mm")}
                                              </p>
                                              <div className="actions btns">
                                                {item?.status == 1 ? (
                                                  <button
                                                    className="remove_add"
                                                    onClick={(e) =>
                                                      DeactivateHandler(
                                                        e,
                                                        item?.id
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-times mr-2"
                                                      aria-hidden="true"
                                                    ></i>
                                                    {deactLoad
                                                      ? "Loading.."
                                                      : "Deactivate Ad"}
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="remove_add"
                                                    onClick={(e) =>
                                                      DeactivateHandler(
                                                        e,
                                                        item?.id
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-times mr-2"
                                                      aria-hidden="true"
                                                    ></i>
                                                    {deactLoad
                                                      ? "Loading.."
                                                      : "Activate Ad"}
                                                  </button>
                                                )}

                                                <button
                                                  className="edit adds_edit"
                                                  onClick={(e) =>
                                                    EditPostOpen(e, item)
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-pencil mr-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  Edit
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        {/* <td className="chat">
                                          <p className="messages">
                                            <i
                                              className="fa fa-envelope-o"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {item?.count_chat_views.length}
                                          </p>
                                        </td> */}
                                        <td className="price">
                                          <p>{item?.year}</p>
                                        </td>
                                        <td className="price">
                                          <p>${item?.price}</p>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <p>No Record Found</p>
                              )}
                            </table>
                          </div>
                        ) : (
                          <>
                            <div className="notFound-container">
                              <img
                                src={notFound}
                                alt="not found"
                                className="image-fluid"
                              />
                              <p className="mt-4">No Reacord Found</p>
                            </div>
                          </>
                        )}

                      </>
                    )}
                  </div>
                  <div className="pagination-container mt-5">
                          <ReactPaginate
                            previousLabel="<<"
                            nextLabel=">>"
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-center"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
      <EditCarPostModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        phone={phone}
        setPhone={setPhone}
        address={address}
        setAddress={setAddress}
        description={description}
        setDescription={setDescription}
        title={title}
        setTitle={setTitle}
        loading={loading}
        setLoading={setLoading}
        EditPostCarListHandler={EditPostCarListHandler}
      />
    </>
  );
}

export default MyAds;
