import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  banner,
  blogPd5,
  david,
  pdCar,
  plybtn,
  videoBanner,
  WebBanner,
} from "../../constant";
import "../../assets/css/BlogDetails.css";
import { GetCSHVideoById } from "../../network/Network";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function BlogDetails() {
  const [blogDetails, setBlogDetails] = useState();
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  let currentUrl = window.location.href.split("/");
  const location = useLocation();
  // console.log(location?.state);
  const paramData = location?.state;

  useEffect(() => {
    GetCSHVideoById(currentUrl[4])
      .then((res) => {
        // console.log(res);
        setBlogDetails(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUrl[4]]);

  return (
    <>
      <Header />
      <section className="Slidebanner videoPage-banner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={videoBanner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2 className="wow animate__animated animate__bounce">
                {" "}
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="david-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <h2 className="main-head">Blog Details</h2> */}

              <h4 className="date">
                {" "}
                {moment(blogDetails?.created_at).format("MMMM Do YYYY")}
              </h4>
              <div className="david-head">
                <h2>{blogDetails?.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-title-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="video-container">
                <video className="videoTag" playsinline autoPlay loop muted controls={false}>
                  <source
                    src={`${ImageUrl?.csh_video}/${paramData?.video}`}
                    type="video/mp4"
                  />
                </video>
                
              </div>
            </div>

            <div className="col-md-12">
              <div className="blog-title-text">
                <p>{blogDetails?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default BlogDetails;
