import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { ramdomImage } from "../../constant/ConstantFunction";
import { PostChangePassword } from "../../network/Network";

function ChangePassword() {
  const userData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!currentPassword || !password || !confirmPassword) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    if (
      password?.length < 9 ||
      currentPassword?.length < 9 ||
      confirmPassword?.length < 9
    ) {
      toast.error("The password must be at least 8 characters.");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Please enter same password and confirm password");
      return;
    }
    let data = new FormData();
    data.append("current_password", currentPassword);
    data.append("password", password);
    data.append("confirm_password", confirmPassword);
    PostChangePassword(data, Token)
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.message);
        console.log(res);
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      <section className="profile_sec changepass">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>Change Password</h5>
                    </div>
                  </div>

                  <div className="main-dev ">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="name details">
                          <h5 className="property">Current Password</h5>
                          <h5 className="value">
                            <input
                              type="text"
                              placeholder="Current Password"
                              value={currentPassword}
                              onChange={(e) =>
                                setCurrentPassword(e.target.value)
                              }
                            />
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="name details">
                          <h5 className="property">Password</h5>
                          <h5 className="value">
                            <input
                              type="text"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </h5>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="address details">
                          <h5 className="property">Confirm Password</h5>
                          <h5 className="value">
                            <input
                              type="text"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3 btn-div">
                        <button
                          className="btn change-passBtn"
                          onClick={(e) => changePassword(e)}
                          disabled={loading}
                        >
                          {loading ? "Loading.." : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
    </>
  );
}

export default ChangePassword;
