import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { editAds, ImageHolder, lisiting } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import { GetMyCarListing } from "../../network/Network";
import { useLocation } from "react-router-dom";
import AdsImageSlider from "../../components/Slider/AdsImageSlider";

function AdsDetails() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Location = useLocation();
  const ParamData = Location?.state?.data;
  const [spinLoad, setSpinLoad] = useState(false);
  const [myListingData, setMyListingData] = useState([]);

  return (
    <>
      <Header />
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="adds_listingss">
                    <div className="profile_strip">
                      <div className="heading_wrapper">
                        <h5>Ads Details</h5>
                      </div>
                    </div>
                    <div className="ads-details-container">
                      <div className="image-Slider">
                        <AdsImageSlider ParamData={ParamData} />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex">
                            <p>Email:</p>
                            <p className="ml-2">
                              {ParamData?.email ? ParamData?.email : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Address:</p>
                            <p className="ml-2">
                              {ParamData?.address ? ParamData?.address : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Body Type:</p>
                            <p className="ml-2">
                              {ParamData?.body_type
                                ? ParamData?.body_type?.name
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Car Make:</p>
                            <p className="ml-2">
                              {ParamData?.car_make
                                ? ParamData?.car_make?.title
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Car Condition:</p>
                            <p className="ml-2">
                              {ParamData?.condition
                                ? ParamData?.condition?.title
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Fuel Type:</p>
                            <p className="ml-2">
                              {ParamData?.fuel_type
                                ? ParamData?.fuel_type?.name
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Mileage:</p>
                            <p className="ml-2">
                              {ParamData?.mileage ? ParamData?.mileage : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Price: </p>
                            <p className="ml-2">
                              {ParamData?.price ? ParamData?.price : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex">
                            <p>Phone:</p>
                            <p className="ml-2">
                              {ParamData?.phone_no ? ParamData?.phone_no : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Zip Code:</p>
                            <p className="ml-2">
                              {ParamData?.zip ? ParamData?.zip : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Car Category:</p>
                            <p className="ml-2">
                              {ParamData?.car_category
                                ? ParamData?.car_category?.name
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Car Modal:</p>
                            <p className="ml-2">
                              {ParamData?.model
                                ? ParamData?.model?.title
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Drive Type:</p>
                            <p className="ml-2">
                              {ParamData?.drive_type
                                ? ParamData?.drive_type?.name
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Transmission Type:</p>
                            <p className="ml-2">
                              {ParamData?.transmission_type
                                ? ParamData?.transmission_type?.name
                                : "--"}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p>Year:</p>
                            <p className="ml-2">
                              {ParamData?.year ? ParamData?.year : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <p className="featureTitle">Features</p>
                          <ul>
                            {ParamData?.features?.map((item, index) => {
                              return <li>{item?.options?.name}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
    </>
  );
}

export default AdsDetails;
