import React, { useState } from "react";
import Slider from "react-slick/lib/slider";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import LogoSlider from "../../components/Slider/LogoSlider";
import { calc, calc2, plybtn } from "../../constant";
import "../../assets/css/AutoLoan.css";
import { LoanCalculator } from "../../network/Network";
import { toast } from "react-toastify";

function AutoLoanCalculation() {
  const [loading, setLoading] = useState(false);
  const [totalLoan, setTotalLoan] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [totalMonth, setTotalMonth] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const [loanData, setLoanData] = useState();

  const LoanHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !totalLoan ||
      !downPayment ||
      !totalMonth ||
      !interestRate ||
      !salesTax
    ) {
      toast.error("Please Enter all Fields");
      setLoading(false);
      return;
    }
    let data = {
      total_loan: totalLoan,
      down_payment: downPayment,
      total_month: totalMonth,
      interest_rate: interestRate,
      sales_tax: salesTax,
    };
    console.log(data);
    LoanCalculator(data)
      .then((res) => {
        setLoading(false);
        setLoanData(res?.data?.response?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      {/* <!-- Banner Start Here --> */}
      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={calc2} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2 className="wow animate__animated animate__bounce">
                {" "}
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Banner End Here --> */}

      <div className="container">
        <div>
          <p className="mega-title">CSH AUTO LOAN CALCULATOR</p>
        </div>
      </div>

      {/* <!-- Auto Loan Sec Start Here --> */}
      <section className="auto_loan">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-6 loan-left">
              <div className="mb-5">
                <h3>Let's estimate your monthly car loan payment</h3>
              </div>
              <div className="row">
                <div className="col-md-3 mb-4">
                  <label for="AutoPrice">Total Amount</label>
                </div>
                <div className="col-md-9 mb-4">
                  <input
                    type="number"
                    className="inputClass ml-2"
                    value={totalLoan}
                    onChange={(e) => setTotalLoan(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <label for="AutoPrice">Down Payment</label>
                </div>
                <div className="col-md-9 mb-4">
                  <input
                    type="number"
                    className="inputClass ml-2"
                    value={downPayment}
                    onChange={(e) => setDownPayment(e.target.value)}
                  />
                </div>

                <div className="col-md-3 mb-4">
                  <label for="AutoPrice">Length of loan (in months)</label>
                </div>
                <div className="col-md-9 mb-4">
                  <select
                    id="Condition"
                    className="inputClass ml-2"
                    onChange={(e) => {
                      setTotalMonth(e.target.value);
                    }}
                  >
                    <option selected="">Select Condition</option>
                    <option value={12}>12 Months</option>
                    <option value={24}>24 Months</option>
                    <option value={36}>36 Months</option>
                    <option value={48}>48 Months</option>
                    <option value={60}>60 Months</option>
                    <option value={72}>72 Months</option>
                    <option value={84}>84 Months</option>
                  </select>
                </div>
                <div className="col-md-3 mb-4">
                  <label for="AutoPrice">Interest Rate</label>
                </div>
                <div className="col-md-9 mb-4">
                  <input
                    type="number"
                    className="inputClass ml-2"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                  />
                </div>

                <div className="col-md-3 mb-4">
                  <label for="AutoPrice">Sales Tax</label>
                </div>
                <div className="col-md-9 mb-4">
                  <input
                    type="number"
                    className="inputClass ml-2"
                    value={salesTax}
                    onChange={(e) => setSalesTax(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <button
                    className="calculate_btn btn"
                    onClick={(e) => LoanHandler(e)}
                    disabled={loading}
                  >
                    {loading ? "Loading.." : "Calculate"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6  loan-right">
              <div>
                <h3 style={{ textAlign: "center" }}>Your estimated payment</h3>
              </div>
              <div>
                <p className="rate-month">
                  ${loanData?.monthly_payment ? parseFloat(loanData?.monthly_payment).toFixed(2) : 0}
                  /mo.
                </p>
              </div>
              <div>
                <p className="breakdown mb-3">Breakdown</p>
              </div>
              <div className="content">
                <h5 className="gray-content">Car Price</h5>
                <h5 className="gray-content">
                  ${loanData?.car_price ? loanData?.car_price : 0}
                </h5>
              </div>
              <div className="content">
                <h5 className="gray-content">Down Payment</h5>
                <h5 className="gray-content">
                  $
                  {loanData?.total_down_payment
                    ? loanData?.total_down_payment
                    : 0}
                </h5>
              </div>
              <div className="content">
                <h5 className="gray-content">
                  Estimated sales tax ({salesTax ? parseFloat(salesTax).toFixed(2) : "0.00"})%
                </h5>
                <h5 className="gray-content">
                  $
                  {loanData?.estimated_sales_tax
                    ? loanData?.estimated_sales_tax
                    : 0}
                </h5>
              </div>
              <div className="content">
                <h5 className="gray-content">Other fees*</h5>
                <h5 className="gray-content">not included</h5>
              </div>
              <hr />
              <div className="content">
                <h5 className="gray-Bcontent">Total loan amount</h5>
                <h5 className="gray-Bcontent">
                  $
                  {loanData?.total_loan_amount
                    ? parseFloat(loanData?.total_loan_amount).toFixed(2) 
                    : 0}
                </h5>
              </div>
              <div className="content">
                <h5 className="gray-Bcontent">Total interest paid</h5>
                <h5 className="gray-Bcontent">
                  $
                  {loanData?.total_interest_paid
                    ? loanData?.total_interest_paid
                    : 0}
                </h5>
              </div>
              <div className="content">
                <h5 className="gray-Bcontent">Total loan & interest paid</h5>
                <h5 className="gray-Bcontent">
                  $
                  {loanData?.total_loan_and_interest_paid
                    ? loanData?.total_loan_and_interest_paid
                    : 0}
                </h5>
              </div>
              <div className="content">
                <h5 className="gray-Bcontent">Monthly payment</h5>
                <h5 className="gray-Bcontent">
                  ${loanData?.monthly_payment ? loanData?.monthly_payment : 0}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default AutoLoanCalculation;
