import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PostEvents } from "../../network/Network";
import { useNavigate } from "react-router-dom";

function EventFormModal(props) {
  const { setIsOpenModal, isOpenModal } = props;
  // create event
  const Navigate = useNavigate();
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [editEvent, setEditEvent] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [title, setTitle] = useState("");
  const [userState, setUserState] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [link, setLink] = useState("");
  const [mediaFile, setMediaFile] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState();
  const [email, setEmail] = useState("");

  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringTime, setRecurringTime] = useState("monthly");
  const [loading, setLoading] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setMediaFile(file);
      // console.log("media", file);
    };
    reader?.readAsDataURL(file);
  };

  const RecurringTimeHandler = (event) => {
    setRecurringTime(event.target.value);
    // console.log(event.target.value)
  }

  const PostEventsHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    if (
      !title ||
      !userState ||
      !address ||
      !description ||
      !mediaFile ||
      !time ||
      !startDate ||
      !phone
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    // console.log(mediaFile);
    let data = new FormData();
    data.append("title", title);
    data.append("state", userState);
    data.append("address", address);
    data.append("date", startDate);
    data.append("time", time);
    data.append("link", link);
    data.append("contact", phone);
    data.append("description", description);
    data.append("icon", mediaFile);
    data.append("email", email);
    data.append("is_recuring", isRecurring ? 1 : 0 );
    data.append("event_recuring", recurringTime );
    PostEvents(data)
      .then((res) => {
        toast.success("Event Successfully Created");
        Navigate("/car-show");
        setLoading(false);
        setMediaFile("");
        setTitle("");
        setPhone("")
        setAddress("");
        setUserState("");
        setDescription("");
        setEmail("");
        setIsOpenModal(false);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setLoading(false);
      });
  };

  const onTimeChange = (e) => {
    var timeSplit = e.target.value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = "PM";
    }
    setTime(hours + ":" + minutes + " " + meridian);
  };

  // console.log(isRecurring);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 style={{ textAlign: "center" }}>List Your Event</h2>
        <div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="reservation-form">
                <div className="reservation-form-content">
                  <form action="">
                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <input
                          type="text"
                          placeholder="Title"
                          className="form-control createEvent-input"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <input
                          type="text"
                          placeholder="Location"
                          className="form-control createEvent-input"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <input
                          type="text"
                          placeholder="State"
                          className="form-control createEvent-input"
                          value={userState}
                          onChange={(e) => setUserState(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <input
                          type="text"
                          placeholder="Email"
                          className="form-control createEvent-input"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <input
                          type="number"
                          placeholder="Contact"
                          className="form-control createEvent-input"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <input
                          type="text"
                          placeholder="Link"
                          className="form-control createEvent-input"
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 mt-2">
                        <textarea
                          type="text"
                          placeholder="Description"
                          className="form-control"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 mt-4 date-time-cont">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            className="forom-control mr-2"
                            value={isRecurring}
                            onChange={() => setIsRecurring(!isRecurring)}
                          />
                          <label>Recurring Event</label>
                        </div>
                      </div>
                      {
                        isRecurring ? (
                        <>
                            <div className="col-md-6 mt-4 date-time-cont">
                          <div className="form-group">
                            <input
                               type="radio"
                               name="selector"
                              className="forom-control mr-2"
                              value={"monthly"}
                              onChange={(e) => RecurringTimeHandler(e)}
                            />
                            <label>Monthly</label>
                          </div>
                        </div>
                        <div className="col-md-6 mt-4 date-time-cont">
                        <div className="form-group">
                          <input
                            type="radio"
                            name="selector"
                            className="forom-control mr-2"
                            value={"yearly"}
                            onChange={(e) => RecurringTimeHandler(e)}
                          />
                          <label>Yearly</label>
                        </div>
                      </div>
                        </>
                        ) : (null) 
                      }
                     

                      <div className="col-md-6 mt-4 date-time-cont">
                        <label>Date</label>
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="ml-2 date-time-input"
                        />
                      </div>
                      <div className="col-md-6 mt-4 date-time-cont">
                        <label>Time </label>
                        <input
                          type="time"
                          value={time}
                          // onChange={(e)=>onTimeChange(e)}
                          onChange={(e) => setTime(e.target.value)}
                          className="ml-2 date-time-input"
                        />
                      </div>

                      <div className="uploadContainer mt-3">
                        <button
                          type="button"
                          className="btn btn-primary eventIcon-btn"
                          // disabled={mediaFile}
                        >
                          {mediaFile ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="edit-pen-icon"
                            />
                          ) : (
                            "Upload image"
                          )}
                        </button>
                        <input
                          type="file"
                          onChange={handleImageUpload}
                          multiple="false"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <button
                          type="submit"
                          className="form-submit"
                          onClick={(e) => PostEventsHandler(e)}
                          disabled={loading}
                        >
                          {loading ? "Loading.." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EventFormModal;
