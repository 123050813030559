import React from 'react'
import Slider from 'react-slick/lib/slider'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import LogoSlider from '../../components/Slider/LogoSlider'
import { banner, brand1, brand10, brand11, brand12, brand13, brand2, brand3, brand4, brand5, brand6, brand7, brand8, brand9, leader1, plybtn } from '../../constant'
import '../../assets/css/LeaderBoard.css'

function LeaderBoard() {
  
  return (
    <>
        <Header />
        <section className="Slidebanner">
            <div className="banner">
                <div className="slides">
                    <div className="img-box">
                        <figure><img src={banner} alt="123" /></figure>
                    </div>
                    <div className="ply-btn">
                        <figure><a href="#"><img src={plybtn} alt="123" /></a></figure>
                    </div>
                    <div className="title">
                        <h2>Lamborghini<br /> Showcase</h2>
                    </div>
                </div>
            </div>
        </section>
        <LogoSlider />
                     
        <section className="leaderboard pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-xl-2"></div>
                    <div className="col-xl-8">
                        <div className="leaderboard-content text-center">
                            <h2>Leaderboard</h2>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes. Lorem ipsum dolor
                                sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                                sociis natoque penatibus et magnis dis parturient montes.</p>
                        </div>
                    </div>
                    <div className="col-xl-2"></div>
                </div>
            </div>
        </section>

        <section className="leaders py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <table>
                            <tbody>
                                <tr className="row1">
                                    <td className="rank">1<sup>st</sup></td>
                                    <td className="name-number">
                                        <h2>Manfred James</h2><br /><a className="leader-number" href="#">
                                            <img  src="./img/seller-call-icon.svg" className="img-fluid" alt="" /> 
                                                +1 234 567 890</a>
                                    </td>
                                    <td className="leader-img"><img src={leader1} className="img-fluid" alt="" /></td>
                                    <td className="point-and-rating">
                                        <h4 className="leader-points">12,000 points</h4><br />
                                        <div className="rating">
                                            <ul>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/empty-star.svg" className="star" alt="" /></li>
                                                <li><img src="./img/empty-star.svg" className="star" alt="" /></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <div className="white-space"></div>
                                <tr className="row2">
                                    <td className="rank">2<sup>nd</sup></td>
                                    <td className="name-number">
                                        <h2>Manfred James</h2><br /><a className="leader-number" href="#"><img
                                                src="./img/seller-call-icon.svg" className="img-fluid" alt="" /> +1 234 567 890</a>
                                    </td>
                                    <td className="leader-img"><img src={leader1} className="img-fluid" alt="" /></td>
                                    <td className="point-and-rating">
                                        <h4 className="leader-points">12,000 points</h4><br />
                                        <div className="rating">
                                            <ul>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/empty-star.svg" className="star" alt="" /></li>
                                                <li><img src="./img/empty-star.svg" className="star" alt="" /></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="row3">
                                    <td className="rank">3<sup>rd</sup></td>
                                    <td className="name-number">
                                        <h2>Manfred James</h2><br /><a className="leader-number" href="#"><img
                                                src="./img/seller-call-icon.svg" className="img-fluid" alt="" /> +1 234 567 890</a>
                                    </td>
                                    <td className="leader-img"><img src={leader1} className="img-fluid" alt="" /></td>
                                    <td className="point-and-rating">
                                        <h4 className="leader-points">12,000 points</h4><br />
                                        <div className="rating">
                                            <ul>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/yellorate.svg" className="star" alt="" /></li>
                                                <li><img src="./img/empty-star.svg" className="star" alt="" /></li>
                                                <li><img src="./img/empty-star.svg" className="star" alt="" /></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="load-more-button pt-5">
                            <button className="load-more">Load more</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}

export default LeaderBoard