import React, { useEffect, useState, Suspense } from "react";
import FindYourCar from "../../components/FindYourCar/FindYourCar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
	book1,
	book2,
	book3,
	plybtn,
	WebBanner,
	web_bannerCompress,
	web_bannerFinal,
	web_bannerCompressWebm,
	mag1,
	CarShowHero_SectionImage1,
	classifiedHero,
	digital_1,
} from "../../constant";
import ShowCaseCard from "../../components/ShowCase/ShowCaseCard";
import CustomizeBanner from "../../components/Banner/CustomizeBanner";
import MonthTopClickCard from "../../components/MonthTopClick/MonthTopClickCard";
import GarageBanner from "../../components/Banner/GarageBanner";
import CshVideo from "../../components/CshVideo/CshVideo";
import ActiveCarshowSlider from "../../components/Slider/ActiveCarshowSlider";
import Slider from "react-slick/lib/slider";
import LogoSlider from "../../components/Slider/LogoSlider";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
	GetAllProducts,
	GetCarViews,
	GetMagazine,
} from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import ReactPlayer from "react-player";

function Home() {
	const Navigate = useNavigate();
	const ShowCaseListDataRedux = useSelector(
		(state) => state.AuthReducer.showCaseListData,
	);
	const CarCategoryDataRedux = useSelector(
		(state) => state.AuthReducer.carCategoryData,
	);
	const CshVideosRedux = useSelector((state) => state.AuthReducer.cshVideoData);
	const CarMakeModelRedux = useSelector(
		(state) => state.AuthReducer.carMakeModelData,
	);
	const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
	const EventDatRedux = useSelector((state) => state.AuthReducer.eventData);
	const [selectMakerModel, setSelectMakerModel] = useState([]);
	const [carMakerId, setCarMakerId] = useState("");
	const [carModel, setCarModel] = useState("");
	const [viewData, setViewData] = useState([]);
	const [viewLoader, setViewLoader] = useState(false);

	const [productsData, setProductsData] = useState([]);
	const [highToLow, setHighToLow] = useState(null);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(10000);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectVideo, setSelectVideo] = useState();
	const [magazineData, setMagazineData] = useState();

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		// arrows: true,
	};

	const CarSearchHandler = (e) => {
		e.preventDefault();
		let data = {
			carMaker: carMakerId,
			carModel: carModel,
		};
		Navigate("/all-cars", { state: { data } });
	};

	// Get car View
	useEffect(() => {
		setViewLoader(true);
		GetCarViews()
			.then((res) => {
				// console.log(res);
				setViewData(res?.data?.response?.data);
				setViewLoader(false);
			})
			.catch((err) => {
				console.log(err);
				setViewLoader(false);
			});
	}, []);

	useEffect(() => {
		const data = {
			status: highToLow,
			start: start,
			end: end,
		};
		GetAllProducts(currentPage, data)
			.then((res) => {
				// console.log(res);
				setProductsData(res?.data?.response?.data?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		GetMagazine()
			.then((res) => {
				console.log("mag", res);
				setMagazineData(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// const handleVideoClick = () => {
	//   const video = document.getElementById("video-banner");
	//   video.play();
	// };

	return (
		<>
			<Header />

			{/* <!-- Banner Start Here --> */}
			<section className="Slidebanner">
				<div className="banner">
					<div className="slides">
						<div className="video-wrapper">
							{/* <video id="video-banner" className="videoTag" autoPlay loop muted 
                  onClick={handleVideoClick}
                <video id="video-banner" className="videoTag" autoPlay loop muted 
                playsinline 
                controls={false}
                // poster={WebBanner}
                  // onClick={handleVideoClick}
                >
                  <source src={web_bannerCompress} type="video/mp4" />
                </video> */}
							<ReactPlayer
								url={web_bannerCompress}
								width="100%"
								height="100vh"
								playing
								loop
								muted
								webkit-playsinline
							/>
							{/* web_bannerCompressWebm */}
							{/* <iframe 
                width="100%" height="100%"  src="https://www.youtube.com/embed/FqqmZtNg2o8?autoplay=1&controls=0&showinfo=0&modestbranding=1&autohide=0&loop=1" frameborder="0"  allowfullscreen></iframe> */}
							{/* allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" */}
						</div>
						{/* <div className="ply-btn">
              <figure>
                <Link to="#">
                  <img src={plybtn} alt="123" />
                </Link>
              </figure>
            </div> */}
						<div className="title">
							<h2 className="wow animate__animated animate__bounce">
								Lamborghini
								<br /> Showcase
							</h2>
						</div>
					</div>
				</div>
			</section>

			{/* <LogoSlider /> */}

			<section className="magazine">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="mag-img">
								<img
									src={magazineData?.image_url}
									alt="mag"
									className="image-fluid"
								/>
							</div>
						</div>
						<div className="col-md-8 right-side">
							<h3>Check It Out...</h3>
							<div className="mag-img2">
								<img src={digital_1} alt="" className="image-fluid" />
							</div>
							<div className="mt-4 mb-4">
								<h2>The Monthly Online Magazine That</h2>
								<h2>Takes You To The Show!</h2>
							</div>
							<div>
								<a
									href={magazineData?.link}
									target="_blank"
									className="magazine-View"
								>
									View
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <!-- Active Car Show Start here --> */}
			<section className="activeShow">
				<div className="container">
					<div className="heading">
						<h2>
							Find active <span style={{ color: "#D80909" }}>car shows</span>{" "}
							near you!
						</h2>
						{/* <h4>
              Browse the listings that we have here to find a show or event near
              you, or list your own car show or automotive event right here,
              totally for free.
            </h4> */}
					</div>
					<div className="activecars ">
						<div>
							<Slider {...settings}>
								{EventDatRedux?.slice(0, 5).map((item, index) => {
									return (
										<>
											<ActiveCarshowSlider item={item} index={index} />
										</>
									);
								})}
							</Slider>
						</div>
					</div>
					<div className="button-group">
						<Link to="/car-show" className="form-submit">
							View
						</Link>
					</div>
				</div>
			</section>
			{/* <!-- Active Car Show End here --> */}

			<div className="container">
				<div className="classified_img-cont">
					<img src={classifiedHero} className="image-fluid" alt="" />
				</div>
			</div>
			{/* <!-- Banner Start Here --> */}
			<FindYourCar
				CarMakeModelRedux={CarMakeModelRedux}
				selectMakerModel={selectMakerModel}
				setSelectMakerModel={setSelectMakerModel}
				carMakerId={carMakerId}
				setCarMakerId={setCarMakerId}
				carModel={carModel}
				setCarModel={setCarModel}
				CarSearchHandler={CarSearchHandler}
				page={"Home"}
			/>
			<section className="showcase">
				<div className="container">
					<div className="head">
						<h2 className="title">Showcase Listings</h2>
					</div>
					<div className="row align-items-start">
						<div className="col-md-12">
							<div className="row">
								{ShowCaseListDataRedux?.map((item, index) => {
									return (
										<>
											{index < 8 ? (
												<div className="col-md-3 mt-5" key={index}>
													<ShowCaseCard item={item} index={index} />
												</div>
											) : null}
										</>
									);
								})}
							</div>
							<div className="action-btn">
								<button onClick={(e) => Navigate("/all-cars")}>All Cars</button>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <CustomizeBanner /> */}

			{/* <!-- Month Pick Start Here --> */}
			<section className="monthPick">
				<div className="container">
					<div className="heading">
						<h2>
							Top <span className="redish">Clicks</span> of the month
						</h2>
						<h4>
							Each month CAR SHOW HERO compiles a list of vehicles that have the
							highest number of views from that time period. This is where you
							will find the most sought after cars, Jeeps and SUV's with the
							best option packages and the lowest miles. Check it out! Your
							Dream Ride may just be parked right here.
						</h4>
					</div>
					<div className="row">
						{viewLoader ? (
							<>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{viewData?.map((item, index) => {
									return (
										<>
											{index < 3 ? (
												<div className="col-md-4" key={index}>
													<MonthTopClickCard item={item} index={index} />
												</div>
											) : null}
										</>
									);
								})}
							</>
						)}
					</div>
				</div>
			</section>
			{/* <!-- Month Pick End Here --> */}

			{/* Temp lock */}
			<GarageBanner productsData={productsData} />

			{/* <!-- Ribbon Section  Temp Hide--> */}
			{/* <section className="ribbon text-white">
        <div className="container">
            <div className="heading">
                <h2>Get Your Closet Ready For<br /> <span className="redish">Car Show</span> Season!</h2>
                <h3>fin all of the <span className="redish">car show hero</span> merchandise<br /> you're looking for right here
                </h3>
                <div className="button-group">
                    <Link to="/merchandise-products" className="btn t-shirt-btn">View All Products 
                    <img src={lines} alt="" /></Link>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="merchandise btm wow animate__animated animate__lightSpeedInLeft">
                        <div className="title">
                            <a href="">
                                <h4>Men<br /> Apparel</h4>
                            </a>
                        </div>
                        <div className="img-box">
                            <figure><img src={merchandise1} alt="" /></figure>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="merchandise btm wow animate__animated animate__lightSpeedInLeft">
                        <div className="title">
                            <a href="">
                                <h4>Women<br /> Apparel</h4>
                            </a>
                        </div>
                        <div className="img-box">
                            <figure><img src={merchandise2} alt="" /></figure>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="merchandise topp wow animate__animated animate__lightSpeedInLeft">
                        <div className="img-box">
                            <figure><img src={merchandise3} alt="" /></figure>
                        </div>
                        <div className="title">
                            <a href="">
                                <h4>Caps</h4>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="merchandise topp wow animate__animated animate__lightSpeedInRight">
                        <div className="img-box">
                            <figure><img src={merchandise4} alt="" /></figure>
                        </div>
                        <div className="title">
                            <a href="">
                                <h4>Accessories</h4>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
			{/* <!-- Ribbon Section --> */}

			<CshVideo
				CshVideosRedux={CshVideosRedux}
				setSelectVideo={setSelectVideo}
				selectVideo={selectVideo}
			/>

			<Footer />
		</>
	);
}

export default Home;
