import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { banner, plybtn, sellerCallIcon, sellerimg, sellerVideo } from '../../constant'
import '../../assets/css/ContactSeller.css'

function ContactSeller() {
  return (
    <>
        <Header />
        <section className="Slidebanner">
    <div className="banner">
        <div className="slides">
            <div className="img-box">
                <figure><img src={banner} alt="123" /></figure>
            </div>
            <div className="ply-btn">
                <figure><a href="#"><img src={plybtn} alt="123" /></a></figure>
            </div>
            <div className="title">
                <h2>Lamborghini<br /> Showcase</h2>
            </div>
        </div>
    </div>
</section>
<section className="contact-seller pt-5">
    <div className="container">
        <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="contact-seller-content text-center">
                    <h2>Contact Seller</h2>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>
            <div className="col-lg-1"></div>
        </div>
    </div>
</section>

<section className="seller-car-sec">
    <div className="container">
        <div className="row">
            <div className="col-lg-9">
                <div className="row seller-bg">
                    <div className="col-xl-2">

                    </div>
                    <div className="col-xl-10">
                        <div className="black-side-sec">
                            <div className="black-side-sec-heading">
                                <div className="sellername">
                                    <h3 className="text-white">AMS Ha Voupa</h3>
                                </div>
                                <div className="sellercarprice">
                                    <h3 className="text-white">$16,000</h3>
                                </div>
                            </div>
                            <div className="black-side-sec-para">
                                <p className="text-white mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. <small><a href="#" className="readmore">Read More</a></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 my-auto">
                <div className="video-box">
                    <div className="img-box">
                        <figure><img src={sellerVideo} alt="" /></figure>
                    </div>
                    <div className="plyBtn tiny">
                        <figure><a href="#"><img src={plybtn} alt="" /></a></figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="seller-contact py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-lg-5">
                <div className="seller-info">
                    {/* <!-- seller img --> */}
                    <figure>
                        <img src={sellerimg} className="seller-img img-fluid" alt="" />
                    </figure>
                    {/* <!-- seller img --> */}
                    {/* <!-- seller contact info --> */}
                    <div className="seller-name">
                        <h3>Manfred James</h3>
                        <a className="seller-number" href="tel:+1234567890">
                            <img src={sellerCallIcon} className="img-fluid" alt="" /> +1 234 567 890</a>
                    </div>
                    {/* <!-- seller contact info --> */}
                </div>
            </div>
            <div className="col-lg-7 my-auto">
                <div className="seller-contact-form">
                    <form action="">
                        <div className="form-group row">
                            <label for="Title" className="col-sm-2 col-form-label">Title</label>
                            <div className="col-sm-10"> 
                            <input 
                                type="text" 
                                id="Title" placeholder="Text*" 
                                className="form-control" 
                                style={{width: `100%`,
                                    padding: `10px 20px`}}
                            />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="Message" className="col-sm-2 col-form-label">Message</label>
                            <div className="col-sm-10">
                                <textarea name="Message" id="Message" placeholder="Text*" className="form-control" cols="30" rows="5"></textarea>
                            </div>
                        </div>
                        <div className="form-group row pt-3">
                            <label for="submit" className="col-sm-2 col-form-label"></label>
                            <div className="col-sm-10">
                                <button id="submit form-submit" className="form-submit">Send message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

        <Footer />
    </>
  )
}

export default ContactSeller