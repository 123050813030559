import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "../../assets/css/Profile.css";
import { car1, car2, car3, car4 } from "../../constant";
import StripeForm from "../../components/Stripe/StripeForm";
import {
  CarListingPackages,
  GetAddListPrice,
  GetAllCard,
  GetCarCategory,
  GetCarColors,
  GetCarConditions,
  GetCarListingInstantOffer,
  GetCarListingParam,
  GetCarMakers,
  GetCarOptions,
  GetCarShowcaseList,
  PostCarListing,
} from "../../network/Network";
import SubscriptionCard from "../../components/Card/SubscriptionCard";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentModal from "../../components/Modal/PaymentModal";
import Skeleton from "react-loading-skeleton";
import { SpinnerCircular } from "spinners-react";

function ListAds() {
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const userData = useSelector((state) => state.AuthReducer.users);
  const CarConditionRedux = useSelector(
    (state) => state.AuthReducer.carConditionData
  );
  const CarOptionDataRedux = useSelector(
    (state) => state.AuthReducer.carOptionData
  );
  const CarMakeModelRedux = useSelector(
    (state) => state.AuthReducer.carMakeModelData
  );
  const CarCategoryDataRedux = useSelector(
    (state) => state.AuthReducer.carCategoryData
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [numImg, setNumImg] = useState("");
  const [instanOffer, setInstanOffer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardToken, setCardToken] = useState("");
  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [multiplePic, setMultiplePic] = useState([]);
  const [multiplePic2, setMultiplePic2] = useState([]);
  const [pkgSpinLoad, setPkgSpinLoad] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [carCondition, setCarCondition] = useState("");
  const [carMakerData, setCarMakerData] = useState([]);
  const [carMaker, setCarMaker] = useState("");
  const [carModel, setCarModel] = useState("");
  const [selectMakerModel, setSelectMakerModel] = useState([]);
  const [carOptionsData, setCarOptionsData] = useState([]);
  const [carColorData, setCarColorData] = useState([]);
  const [carStyle, setCarStyle] = useState("");
  const [carListingData, setCarListingData] = useState([]);
  const [phone, setPhone] = useState(userData?.phone ? userData?.phone : " ");
  const [address, setAddress] = useState(
    userData?.address ? userData?.address : " "
  );
  const [email, setEmail] = useState(userData?.email ? userData?.email : " ");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [price, setPrice] = useState("");
  const [name, setName] = useState(
    userData?.f_name ? `${userData?.f_name} ${userData?.l_name}` : " "
  );
  const [zip, setZip] = useState(userData?.zip ? userData?.zip : " ");
  const [color, setColor] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [carBodyType, setCarBodyType] = useState("");
  const [carDriveType, setCarDriveType] = useState("");
  const [carFuelType, setCarFuelType] = useState("");
  const [carTransmission, setCarTransmission] = useState("");
  const [selectPackage, setSelectPackages] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [addListPriceData, setAddListPriceData] = useState([]);
  const [addOnListId, setAddOnListId] = useState("");
  const [instantOffer, setInstantOffer] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);

  const PaymentHandler = (e, visaToken) => {
    e.preventDefault();
    if (!cardToken) {
      return;
    }
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
      Array.from(e.target.files).map((file) => {
        setMultiplePic2((preVal) => {
          return [...preVal, file];
        });
      });
    }
  };

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return (
        <li>
          {" "}
          <img
            src={photo}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </li>
      );
    });
  };

  // Get package listing
  useEffect(() => {
    setPkgSpinLoad(true);
    CarListingPackages()
      .then((res) => {
        setPackageData(res?.data?.response?.data);
        setPkgSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setPkgSpinLoad(false);
      });
  }, []);

  // Get car Options, Transmission
  useEffect(() => {
    GetCarOptions()
      .then((res) => {
        setCarOptionsData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Colors
  useEffect(() => {
    GetCarColors()
      .then((res) => {
        setCarColorData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get car Listing with params  -- not set
  useEffect(() => {
    const Data = {
      cate: carStyle,
      make: carMaker,
      model: carModel,
    };
    GetCarListingParam(Data)
      .then((res) => {
        setCarListingData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [carStyle, carMaker, carModel]);

  // Get add list price
  useEffect(() => {
    GetAddListPrice()
      .then((res) => {
        setAddListPriceData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const FeaturesHandler = (e) => {
    // e.preventDefault()
    setSelectedFeatures([...selectedFeatures, e.target.value]);
  };

  const PostCarListHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if(!title || !description || !carCondition || !carStyle || !carMaker || !carModel || !carBodyType || !year || !mileage || !carDriveType || !carTransmission || !carFuelType || !color || !selectedFeatures || !price 
      ){
      toast.error(`All field are required`);
      setLoading(false);
      return;
    }
  

    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if(!selectPackage){
      toast.error(`please subscribe your package`);
      setLoading(false);
      return;
    }
    if (multiplePic2?.length < numImg) {
      toast.error(`Add at least ${numImg} pictures`);
      setLoading(false);
      return;
    }
    
    var data = new FormData();
    data.append("condition_id", carCondition);
    data.append("make_id", carMaker);
    data.append("model_id", carModel);
    data.append("car_category_id", carStyle);
    data.append("car_body_option_id", carBodyType);
    data.append("year", year);
    data.append("mileage", mileage);
    data.append("car_drive_type_id", carDriveType);
    data.append("car_transmission_id", carTransmission);
    data.append("car_fuel_type_id", carFuelType);
    data.append("color", color);
    data.append("zip", zip);
    data.append("name", name);
    data.append("phone_no", phone);
    data.append("email", email);
    data.append("address", address);
    data.append("is_add_on", instanOffer ? 1 : 0);
    data.append("price", price);
    data.append("instant_price", instantOffer);
    selectedFeatures.map((singleFeature) => {
      data.append("features[]", singleFeature);
    });
    data.append("addon_listing_id", addListPriceData?.id);
    // addOnListId
    data.append("package_id", selectPackage);
    multiplePic2.map((singlepic) => {
      data.append("media[]", singlepic);
    });
    data.append("price", price);
    data.append("title", title);
    data.append("description", description);
    PostCarListing(data, Token)
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.message);
        Navigate("/my-ads");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("img2 ", multiplePic2);
  }, [multiplePic2]);

  // if (loading == true) {
  //   return (
  //     <div className="loader-container">
  //       <SpinnerCircular size="80px" color="#d60012" />
  //     </div>
  //   );
  // }


  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">List Your Add</h1>
            </div>
            {/* <!-- <div className="banner-img">
                <img src="img/profile-img.png" alt="">
            </div> --> */}
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      {/* <!-- Profile Sec Start Here --> */}
      <section className="profile_sec list_your_add">
        <div className="container">
          <div className="tab-content" id="v-pills-tabContent">
            <div className="profile_strip">
              <div className="heading_wrapper">
                <h5>Post an Ads</h5>
              </div>
            </div>
            <div className="car_informations">
              <div className="row">
                <div className="col-md-12 mt-2">
                  <div className="form-group">
                    <label for="address">Title</label>
                    <input
                      id="desc"
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      style={{ width: "100%" }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="form-group">
                    <label for="address">Description</label>
                    <textarea
                      id="desc"
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      style={{ width: "100%", height: "150px" }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="title_wrapper">
                <h4>1. Car Information</h4>
              </div>
              <form>
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="Condition">Condition</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarCondition(e.target.value);
                        }}
                      >
                        <option selected="">Select Condition</option>
                        {CarConditionRedux?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="Make">Make</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarMaker(e.target.value);
                          setSelectMakerModel(
                            CarMakeModelRedux.find(
                              (val) => val?.id == e.target.value
                            ).car_model || []
                          );
                        }}
                      >
                        <option selected="">Select Maker</option>
                        {CarMakeModelRedux?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="Style">Car Category</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarStyle(e.target.value);
                        }}
                      >
                        <option selected="">Select Category</option>
                        {CarCategoryDataRedux?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="Model">Model</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarModel(e.target.value);
                        }}
                      >
                        <option selected="">Select Model</option>
                        {selectMakerModel?.map((item, index) => {
                          return (
                            <option value={item?.id} key={index}>
                              {item?.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="btype">B.Type</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarBodyType(e.target.value);
                        }}
                      >
                        <option selected="">Select Body Type</option>
                        {carOptionsData?.map((item, index) => {
                          return (
                            <>
                              {item?.name == "Body Type" ? (
                                <>
                                  {item?.options?.map((item, index) => {
                                    return (
                                      <option value={item?.id} key={index}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="year">Year</label>
                      <input
                        type="number"
                        placeholder="Year"
                        className="form-control"
                        id="year"
                        maxlength="4"
                        pattern="\d{4}"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="mileage">Mileage</label>
                      <input
                        type="number"
                        placeholder="2000km"
                        id="mileage"
                        className="form-control"
                        value={mileage}
                        onChange={(e) => setMileage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="dtype">Drive Type</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarDriveType(e.target.value);
                        }}
                      >
                        <option selected="">Select Drive Type</option>
                        {carOptionsData?.map((item, index) => {
                          return (
                            <>
                              {item?.name === "Drive Type" ? (
                                <>
                                  {item?.options?.map((item, index) => {
                                    return (
                                      <option value={item?.id} key={index}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="transmission">Transmission</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarTransmission(e.target.value);
                        }}
                      >
                        <option selected="">Select Transmission</option>
                        {CarOptionDataRedux?.map((item, index) => {
                          return (
                            <>
                              {item?.name === "Transmission" ? (
                                <>
                                  {item?.options?.map((item, index) => {
                                    return (
                                      <option value={item?.id} key={index}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="fuelType">Fuel Type</label>
                      <select
                        id="Condition"
                        className="form-control"
                        onChange={(e) => {
                          setCarFuelType(e.target.value);
                        }}
                      >
                        <option selected="">Fuel Type</option>
                        {CarOptionDataRedux?.map((item, index) => {
                          return (
                            <>
                              {item?.name === "Fuel Type" ? (
                                <>
                                  {item?.options?.map((item, index) => {
                                    return (
                                      <option value={item?.id} key={index}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="form-group">
                      <label for="Color">Color</label>
                      {/* <ul className="colors_cariations">
                                    {
                                        carColorData?.map((item,index)=>{
                                            return(
                                                <>
                                                    {
                                                        index < 10 ? (
                                                            <li>
                                                            <label className="body_container custom_radios" key={index}
                                                                onClick={()=>setColor(item?.code)}
                                                            >
                                                                <input 
                                                                    type="radio" 
                                                                    name="color_variation" 
                                                                    checked={color === `${item?.code}`}
                                                                />
                                                                <span className="checkmark" style={{background: `${item?.code}` }}></span>
                                                            </label>
                                                        </li>
                                                        ) : (null)
                                                    }
                                                </>
                                               
                                            )
                                        })
                                    }
                                </ul> */}
                      <div className="form-group mt-2">
                        <input
                          type="text"
                          placeholder="Color"
                          className="form-control"
                          id="maxyear"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="featuresDv">
                  <div className="title">
                    <h4>Features</h4>
                  </div>
                  <div className="row">
                    {carOptionsData?.map((item, index) => {
                      return (
                        <>
                          {index < 4 ? (
                            <div className="col-lg-3" key={index}>
                              <h5>{item?.name}</h5>
                              {item?.options?.map((item, index) => {
                                return (
                                  <div className="form-group" key={index}>
                                    <input
                                      type="checkbox"
                                      id={item?.id}
                                      className="forom-control"
                                      value={item?.id}
                                      onChange={FeaturesHandler}
                                      // onChange={(e)=>FeaturesHandler(e,item)}
                                    />
                                    <label for="keyless">{item?.name}</label>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>

                {/* <!-- Subscription Plan Sec Start Here --> */}
                <section className="subscription_plan mt-4">
                  <div className="container">
                    <div className="row">
                      {pkgSpinLoad ? (
                        <>
                          <div className="col-md-3">
                            <Skeleton count={5} />
                          </div>
                          <div className="col-md-3">
                            <Skeleton count={5} />
                          </div>
                          <div className="col-md-3">
                            <Skeleton count={5} />
                          </div>
                          <div className="col-md-3">
                            <Skeleton count={5} />
                          </div>
                        </>
                      ) : (
                        <>
                          {packageData?.map((item, index) => {
                            return (
                              <SubscriptionCard
                                item={item}
                                index={index}
                                selectPackage={selectPackage}
                                setSelectPackages={setSelectPackages}
                                isOpenModal={isOpenModal}
                                setIsOpenModal={setIsOpenModal}
                                setNumImg={setNumImg}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </section>
                {/* <!-- Subscription Plan Sec End Here --> */}

                <div className="upload_photos_box">
                  <div className="title">
                    <h5>Upload Photos</h5>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="files_flex">
                        <div className="imgBox">
                          <figure>
                            <img src="img/upload-img.png" alt="" />
                          </figure>
                        </div>
                        <div className="custom-file">
                          <button
                            type="button"
                            disabled={
                              !selectPackage || multiplePic2?.length > numImg ? true : false
                            }
                          >
                            <img className="mr-2" src="img/plus.png" alt="" />
                            Add Photos
                          </button>
                          <br />
                          <span className="note">
                            (Max limit 5MB per image)
                            { !selectPackage ? (<p>Please scubscribe package then upload images</p>) : (null)}
                          </span>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            multiple={true}
                            className="custom-file-input"
                            id="attachments"
                            disabled={!selectPackage || multiplePic2?.length > numImg ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ul className="importants_points">
                        <li>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          Add at least {numImg} pictures
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          Add clear front, back and Interior pictures
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          Photos should be in "jpeg, jpg, png" Format only.
                        </li>
                      </ul>
                    </div>
                    <ul id="fileList" className="file-list">
                      {renderPhotos(selectedFiles)}
                    </ul>
                  </div>
                </div>
                <div className="ada_price">
                  <div className="title_wrapper">
                    <h4>2. Price</h4>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="price">Listing Price $</label>
                        <input
                          id="price"
                          type="number"
                          className="form-control listing-input"
                          placeholder="200"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <div className="form-group forcheck">
                        <input
                          type="checkbox"
                          id="checkbx"
                          onChange={() => setInstanOffer(!instanOffer)}
                        />
                        <label for="checkbx">Add instant Price</label>
                      </div>
                    </div>

                    {instanOffer ? (
                      <>
                        <div className="col-md-12 mt-2">
                          <div className="form-group instant_offer hide">
                            <label for="instant_offer">Instant Offer $</label>
                            <div className="input_flex">
                              <input
                                type="number"
                                className="form-control listing-input"
                                placeholder="Add Instant Price"
                                value={instantOffer}
                                onChange={(e) =>
                                  setInstantOffer(e.target.value)
                                }
                              />
                              {/* <select 
                                                    id="Condition" 
                                                    className="form-control ml-3"
                                                    onChange={(e) => {
                                                        setAddOnListId(e.target.value);
                                                    }}
                                                >
                                                    <option selected="">Select Value</option>
                                                    {
                                                        addListPriceData?.length > 1  ? (
                                                            <>
                                                                {addListPriceData?.map((item,index)=>{
                                                            return(
                                                                <option value={item?.id} key={index}>{item?.value}</option>
                                                            )
                                                        })}
                                                            </>
                                                        ) : (
                                                            <option value={addListPriceData?.id} >{addListPriceData?.value}</option>
                                                        )
                                                    }
                                                        
                                                </select> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="contact_informations">
                  <div className="title_wrapper">
                    <h4>3. Contact Information</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <div className="form-group">
                        <label for="user_id">User Name</label>
                        <input
                          id="user_id"
                          type="text"
                          className="form-control"
                          placeholder="Ipsum"
                          style={{ width: "100%" }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="form-group">
                        <label for="phone">Phone</label>
                        <input
                          id="phone"
                          type="number"
                          className="form-control"
                          placeholder="+1 234 567 890"
                          style={{ width: "100%" }}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="form-group">
                        <label for="email">Email</label>
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="name@email.com"
                          style={{ width: "100%" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="form-group">
                        <label for="address">Address</label>
                        <input
                          id="address"
                          type="text"
                          className="form-control"
                          placeholder="Lorem Ipsum dolar sit amet"
                          style={{ width: "100%" }}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <div className="form-group">
                        <label for="address">Zip</label>
                        <input
                          id="Zip"
                          type="number"
                          className="form-control"
                          placeholder="Zip"
                          style={{ width: "100%" }}
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-12 mt-5">
                      {Token ? (
                        <button
                          className="contact_btn btn"
                          onClick={(e) => PostCarListHandler(e)}
                          disabled={loading}
                        >
                          {loading ? (
                            <SpinnerCircular size="20px" color="#fff" />
                          ) : (
                            "Post"
                          )}
                        </button>
                      ) : (
                        <button
                          className="contact_btn btn"
                          onClick={(e) => Navigate("/payment-method")}
                        >
                          Post
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />

      <PaymentModal setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />
    </>
  );
}

export default ListAds;
