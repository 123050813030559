import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { car3, ImageHolder, type1, type2, type3 } from '../../constant';

function ShowCaseCard(props) {
    const { index ,item } = props;
    const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  return (
    <>
        <a href="">
            <div className="carBox wow animate__animated animate__flipInX">
                <div className="img-box">
                    <Link 
                        to={`/product-details/${item?.id}`} state={{data: item}} 
                    >
                    <figure
                        style={{
                            height: '208px',
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        {
                            item?.images.length > 0 ? (
                                <img src={`${ImageUrl?.car_listing}/${item?.images[1]?.image}`} alt="not found" />
                            ) : (
                                <img src={ImageHolder} alt="not found" />
                            )
                        }
                    </figure>
                    </Link>
                        <div className="BottomBorder"></div>
                                </div>
                                <div className="contentDv">
                                    <h5>{item?.car_make?.title}{" "}{item?.model?.title}</h5>
                                    <h6>Build year:<span className="year ml-1">{item?.year}</span></h6>
                                    <div className="price">
                                        <span>${item?.price}</span>
                                    </div>
                                    <div className="extras">
                                        <div className="type1 types">
                                            <span><img src={type1} alt="" /> {item?.mileage}</span>
                                        </div>
                                        <div className="type2 types">
                                            <span><img src={type2} alt="" /> {item?.transmission_type?.name}</span>
                                        </div>
                                        <div className="type3 types">
                                            <span><img src={type3} alt="" /> {item?.fuel_type?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
    </>
  )
}

export default ShowCaseCard;