import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// "pk_test_51KP83sD6WSEOwfbspjBhctxGvzJrZQUwJQ2TatJLWK2SZ2st5h3kTOs8Smc1ZLMcfBLp4PGVRwWKmXCi1OqaSaWP00oJmOfseX"

const CheckoutForm = (props) => {
  const { PaymentHandler, setCardToken, cardToken, setPaymentMethod, page } =
    props;
  const userData = useSelector((state) => state.AuthReducer.users);
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    setCardToken(payload?.token?.id);
    // console.log("check", payload);

    const cartMethod = stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: userData?.f_name,
          email: userData?.email,
          phone: userData?.phone,
        },
      })
      .then(function (result) {
        setPaymentMethod(result);
      });

    if (payload?.token?.id) {
      PaymentHandler(e, payload?.token?.id);
      return;
    } else {
      toast.error("Something Went Wrong");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />

        <ul className="list-inline">
          <li>
            <button
              type="submit"
              className="default-btn stripePay-Btn btn "
              disabled={!stripe || !elements || paybutton}
              onClick={(e) => handleSubmit(e)}
            >
              {page === "paymentMethod" ? "Add Payment Card" : "Payment"}
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
  const { PaymentHandler, setCardToken, cardToken, setPaymentMethod, page } =
    props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        PaymentHandler={PaymentHandler}
        setCardToken={setCardToken}
        cardToken={cardToken}
        setPaymentMethod={setPaymentMethod}
        page={page}
      />
    </Elements>
  );
};

export default StripeForm;
