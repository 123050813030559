import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { buycar1, engine, gas, ImageHolder, manual } from '../../constant'

function ProductCard(props) {
    const {item,index,page} = props
    const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);


  return (
    <>
           <div className="row py-4" key={index}>
                        <div className="col-md-5 my-auto">
                            <figure className="proimg">
                                <Link 

                                    to={ page === "instant" ? `/instant-offer-details/${item?.id}` : `/product-details/${item?.id}`} state={{data: item }} 
                                >
                                    {
                                        item?.images.length > 0 ? (
                                            <img src={`${ImageUrl?.car_listing}/${item?.images[0]?.image}`} className="car-img image img-fluid" alt="" />
                                        ) : (
                                            <img src={ImageHolder} className="car-img image img-fluid" alt="" />
                                        )
                                    }
                                  
                                    <div className="middle">
                                        <Link  
                                            to={ page === "instant" ? `/instant-offer-details/${item?.id}` : `/product-details/${item?.id}`} state={{data: item}} 
                                            className="text">View Details
                                        </Link>
                                    </div>
                                </Link>

                            </figure>
                        </div>
                        <div className="col-md-7 pt-sm-3 pt-xs-3">
                            <div className="car-content">
                                <div className="carnamenprice">
                                    {/* <h3 className="carname">{item?.car_make?.title}{" "}{item?.model?.title}</h3> */}
                                    <h3 className="carname">{item?.title}</h3>
                                    <h3 className="carprice">${item?.price}</h3>
                                </div>
                                <h5 className="caryear">Build year:{" "}{item?.year}</h5>
                                <p className="cardescription">{item?.description}</p>
                                {/* <!-- <div className="ratings">
                                    <ul>
                                        <li><img src="./img/star.svg" alt=""></li>
                                        <li><img src="./img/star.svg" alt=""></li>
                                        <li><img src="./img/star.svg" alt=""></li>
                                        <li><img src="./img/star.svg" alt=""></li>
                                        <li><img src="./img/star.svg" alt=""></li>
                                    </ul>
                                </div> --> */}
                                <div className="specs">
                                    <ul>
                                        {
                                            page === "instant" ? (
                                                <li className='instantOfferTag'>
                                                    <p className='m-0'> $ submit Offer</p>
                                                </li>
                                            ) : (null)
                                        }
                                        <li><img src={engine} alt="" />{item?.mileage}</li>
                                        <li><img src={manual} alt="" />{item?.transmission_type?.name}</li>
                                        <li><img src={gas} alt="" />{item?.fuel_type?.name}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
    </>
  )
}

export default ProductCard