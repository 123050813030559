import React, { useEffect, useState } from "react";
import {
  banner,
  blogBanner,
  notFound,
  plybtn,
  videoBanner,
  videoPlate,
  youtube,
} from "../../constant";
import "../../assets/css/Blogs.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { SpinnerCircular } from "spinners-react";
import { AllBlogs, GetCSHVideo, GetPagesApi } from "../../network/Network";
import moment from "moment";
import { useSelector } from "react-redux";

function Blogs() {
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const VideoPageData = useSelector((state) => state.AuthReducer.pagesData);
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [blogData, setBlogData] = useState([]);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetCSHVideo()
      .then((res) => {
        console.log(res);
        setSpinLoad(false);
        setBlogData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header />
      <section className="Slidebanner videoPage-banner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={videoBanner} alt="123" />
              </figure>
            </div>
            {/* <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div> */}
            <div className="title">
              <h2 className="wow animate__animated animate__bounce">
                {" "}
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-head-sec ">
        <div className="container">
            <div className="linktag">
              <div className="img-cont">
                <Link to="/video">
                  <img src={videoPlate} className="image-fluid" alt="" />
                </Link>
              </div>
              <div className="img-cont">
                <a
                  href="https://www.youtube.com/channel/UCKRTVcJQ8YXau0ucjP3k38g"
                  target="_blank"
                >
                  <img src={youtube} className="image-fluid" alt="" />
                </a>
              </div>
            </div>

            <h2 className="main-Title">Check out our Channel.</h2>

            {/* <div className="blog-head">
              <h2>HERO BLOG</h2>
              {VideoPageData?.map((item, index) => {
                return (
                  <>
                    {item?.type === "our_video" ? (
                      <>
                        <p className="video_bottom_text">
                          <p
                            dangerouslySetInnerHTML={{ __html: item?.value }}
                            key={index}
                          />
                        </p>
                      </>
                    ) : null}
                  </>
                );
              })}
            </div> */}

        </div>
      </section>

      <section className="blog-sec">
        <div className="container">
          <div className="row">
            {spinLoad ? (
              <div className="loader-container">
                <SpinnerCircular size="80px" color="#d60012" />
              </div>
            ) : (
              <>
                {blogData?.length > 0 ? (
                  <>
                    {blogData?.map((item, index) => {
                      return (
                        <>
                          {item?.show_on == 2 || item?.show_on == 3 ? (
                            <div className="col-md-3">
                              <div className="blog-all-stuff card" key={index}>
                                <Link
                                  to={`/video-details/${item?.id}`}
                                  state={{ video: item?.video }}
                                >
                                  <div className="blog-img">
                                    <video
                                      className="videoTag"
                                      playsinline
                                      autoPlay
                                      loop
                                      muted 
                                      controls={false}
                                    >
                                      <source
                                        src={`${ImageUrl?.csh_video}/${item?.video}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                  <div className="blog-card-text">
                                    <h3>{item?.title}</h3>

                                    <div className="blog-div-for-flex">
                                      <h4>
                                        {moment(item?.created_at).format(
                                          "MMMM Do YYYY"
                                        )}
                                      </h4>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="notFound-container">
                      <img
                        src={notFound}
                        alt="not found"
                        className="image-fluid"
                      />
                      <p className="mt-4">No Record Found</p>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="mt-2 mb-5">
              <p className="video_bottom_text">Come Along With Us To The</p>
              <p className="video_bottom_text">Best Automotive Events</p>
              </div>

          </div>

          <div className="pagination-container mt-5">
            <ReactPaginate
              previousLabel="<<"
              nextLabel=">>"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Blogs;
