import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import {  ImageHolder, pdcar1, productDetail } from "../../constant";

function ProductImageRightSlider(props) {
  const {ParamData} = props;
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const DummyData = [
    {
      id: 1,
      img: ImageHolder,
    },
    {
      id: 2,
      img: ImageHolder,
    },
    {
      id: 3,
      img: ImageHolder,
    },
  ];

  console.log("img",ParamData)

  return (
    <>
    {
      ParamData?.images.length > 0 ? (
        <div className="product-slide row">
        <div className="col-md-8">
          <Slider asNavFor={nav2} ref={slider1} arrows={false}>
            {ParamData?.images?.map((item, index) => {
                console.log(item);
                return (
                  <div className="product-images mainimage-container" key={index}>
                    <figure>
                      <img
                        src={`${ImageUrl?.car_listing}/${item?.image}`}
                        alt=""
                        className="image-fluid image-width main-img"
                      />
                    </figure>
                  </div>
                );
              })}
          </Slider>
        </div>
     
        <div className="col-md-4">
        <Slider
        asNavFor={nav1}
        ref={slider2}
        slidesToShow={ParamData?.images?.length}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
        className="slick-custom-right"
      >
        {ParamData?.images?.map((item, index) => {
          console.log(item);
          return (
              <>
                {
                  index > 3 ? (null) : (
                    <div className="inner-image-container" key={index}>
                    <figure>
                    <img
                        src={`${ImageUrl?.car_listing}/${item?.image}`}
                        alt=""
                        className="image-fluid image-width right-side"
                      />
                    </figure>
                  </div>
                  )
                }
              </>
          );
        })}
      </Slider>
        </div>
     
    </div>
      ) : (
        <div className="product-slide row">
        <div className="col-md-8">
        <Slider asNavFor={nav2} ref={slider1} arrows={false}>
        {DummyData?.map((item, index) => {
          console.log(item);
          return (
            <div className="product-images mainimage-container" key={index}>
              <figure>
                <img
                  src={item?.img}
                  alt=""
                  className="image-fluid image-width main-img"
                />
              </figure>
            </div>
          );
        })}
      </Slider>
        </div>
     
        <div className="col-md-4">
        <Slider
        asNavFor={nav1}
        ref={slider2}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
        className="slick-custom-right"
      >
        {DummyData?.map((item, index) => {
          console.log(item);
          return (
              
            <div className="inner-image-container" key={index}>
              <figure>
              <img
                  src={item?.img}
                  alt=""
                  className="image-fluid image-width right-side"
                />
              </figure>
            </div>
          );
        })}
      </Slider>
        </div>
     
    </div>
      )
    }
    </>
  );
}

export default ProductImageRightSlider;
