import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Merchandise from "../pages/Merchandise/Merchandise";
import CarShow from "../pages/CarShows/CarShow";
import Customize from "../pages/Customize/Customize";
import CarProduct from "../pages/CarProduct/CarProduct";
import ListAds from "../pages/Ads/ListAds";
import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ForgotPasswordOtp from "../pages/Auth/ForgotPasswordOtp";
import NewPassword from "../pages/Auth/NewPassword";
import Profile from "../pages/Profile/Profile";
import MyOrders from "../pages/Orders/MyOrders";
import MyOffers from "../pages/MyOffer/MyOffers";
import PostAds from "../pages/Ads/PostAds";
import MyAds from "../pages/Ads/MyAds";
import Wishlist from "../pages/Wishlist/Wishlist";
import MyEvents from "../pages/Events/MyEvents";
import TermCondition from "../pages/SitePages/TermCondition";
import PrivacyPolicy from "../pages/SitePages/PrivacyPolicy";
import Contact from "../pages/Contact/Contact";
import ProductDetails from "../pages/CarProduct/ProductDetails";
import ContactSeller from "../pages/Seller/ContactSeller";
import Cart from "../pages/Cart/Cart";
import AutoLoanCalculation from "../pages/Research/AutoLoanCalculation";
import OurVideos from "../pages/OurVideo/OurVideos";
import SponsorsPage from "../pages/Sponsor/SponsorsPage";
import LeaderBoard from "../pages/LeaderBoard/LeaderBoard";
import MerchandiseProducts from "../pages/Merchandise/MerchandiseProducts";
import MerchandiseProductDetails from "../pages/Merchandise/MerchandiseProductDetails";
import CheckOut from "../pages/Cart/CheckOut";
import EventQuries from "../pages/Events/EventQuries";
import Chat from "../pages/Chat/Chat";
import TShirtClub from "../pages/TMonthClub/TShirtClub";
import SellerDashboard from "../pages/Seller/SellerDashboard";
import TotalReturn from "../pages/Seller/TotalReturn";
import SellerProfile from "../pages/Seller/SellerProfile";
import PostJobs from "../pages/Jobs/PostJobs";
import Blogs from "../pages/Blogs/Blogs";
import BlogDetails from "../pages/Blogs/BlogDetails";
import SellYourCar from "../pages/CarShows/SellYourCar";
import DownloadGame from "../pages/DownloadGame/DownloadGame";
import PaymentMethod from "../pages/PaymentMethod/PaymentMethod";
import ProtectedRoutes from "./ProtectedRoutes";
import ChangePassword from "../pages/Profile/ChangePassword";
import AdsDetails from "../pages/Ads/AdsDetails";
import InstantOfferDetails from "../pages/InstantOffer/InstantOfferDetails";
import InstantOffer from "../pages/InstantOffer/InstantOffer";
import CreateEvents from "../pages/Events/CreateEvents";
import WishlistTshirt from "../pages/Wishlist/WishlistTshirt";
import Brands from "../pages/Brands/Brands";
import NotFound from "../components/404/NotFound";
import TermConditionSale from "../pages/SitePages/TermConditionSale";

function PublicRoutes(props) {
  const { unityContext } = props;
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/brands/:id" element={<Brands />} />
          <Route path="merchandise" element={<Merchandise />} />
          <Route path="car-show" element={<CarShow />} />
          {/* <Route
            path="customize"
            element={<Customize unityContext={unityContext} />}
          /> */}
          <Route path="instant-offer" element={<InstantOffer />} />
          <Route
            path="instant-offer-details/:Id"
            element={<InstantOfferDetails />}
          />
          <Route path="all-cars" element={<CarProduct />} />
         

          <Route path="product-details/:Id" element={<ProductDetails />} />
          <Route path="contact-seller" element={<ContactSeller />} />
          <Route path="loan-calculator" element={<AutoLoanCalculation />} />
          {/* <Route path="our-videos" element={<OurVideos />} /> */}

          <Route
            path="merchandise-products"
            element={<MerchandiseProducts />}
          />
          <Route
            path="merchandise-product-details/:Id"
            element={<MerchandiseProductDetails />}
          />

          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password-otp" element={<ForgotPasswordOtp />} />
          <Route path="new-password" element={<NewPassword />} />

         

          <Route path="term-condition" element={<TermCondition />} />
          <Route path="term-condition-off-sale" element={<TermConditionSale />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="contact-us" element={<Contact />} />
          
          {/* <Route path="sponsors" element={<SponsorsPage />} /> */}
          {/* <Route path="leader-board" element={<LeaderBoard />} /> */}

          <Route path="cart" element={<Cart />} />
         
          {/* <Route path="t-shirt-club" element={<TShirtClub />} /> */}

          {/* <Route path="seller-dashboard" element={<SellerDashboard />} /> */}
          {/* <Route path="seller-profile" element={<SellerProfile />} /> */}
          {/* <Route path="total-return" element={<TotalReturn />} /> */}

          {/* <Route path="post-jobs" element={<PostJobs />} /> */}
          <Route path="video" element={<Blogs />} />
          <Route path="video-details/:Id" element={<BlogDetails />} />

          {/* <Route path="sell-your-car" element={<SellYourCar />} /> */}

          <Route path="download-game" element={<DownloadGame />} />

          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="profile" element={<Profile />} />
            <Route path="payment-method" element={<PaymentMethod />} />
            <Route path="my-orders" element={<MyOrders />} />
            <Route path="my-offers" element={<MyOffers />} />
            <Route path="post-ads" element={<PostAds />} />
            <Route path="my-ads" element={<MyAds />} />
            <Route path="my-ads/ads-details/:Id" element={<AdsDetails />} />
            <Route path="favourite" element={<Wishlist />} />
            <Route path="favourite-tshirt" element={<WishlistTshirt />} />
            <Route path="my-events" element={<MyEvents />} />
            {/* <Route path="create-events" element={<CreateEvents />} /> */}

            {/* <Route path="event-quries" element={<EventQuries />} /> */}
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="list-your-ads" element={<ListAds />} />
            <Route path="checkout" element={<CheckOut />} />
            <Route path="chat/:Id" element={<Chat />} />
            <Route path="chat" element={<Chat />} />

          </Route>

          <Route path="/*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
