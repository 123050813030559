import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { ImageHolder, notFound, offer1, offer2 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import { GetMyOffers } from "../../network/Network";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function MyOffers() {
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [myOffer, setMyOffer] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  // Get my Offers
  useEffect(() => {
    setSpinLoad(true);
    GetMyOffers(currentPage, Token)
      .then((res) => {
        console.log(res);
        setMyOffer(res?.data?.response?.data?.data);
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
              {/* <p className="subtitle text-white">Profile</p> */}
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div class="profile_strip">
                    <div class="heading_wrapper">
                      <h5>My Offers</h5>
                    </div>
                  </div>
                  {spinLoad ? (
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  ) : (
                    <div class="table-responsive">
                      {myOffer?.length > 0 ? (
                        <table class="table offers_table">
                          <tr class="topbar">
                            <th class="sno">S.NO</th>
                            <th class="product">PRODUCT</th>
                            <th class="offers">Offer</th>
                            {/* <th class="offer-status">Offer STATUS</th> */}
                          </tr>
                          {myOffer?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td class="sno">{index + 1}</td>
                                <td class="product">
                                  <div class="order_product myOffer">
                                    <div class="img_box">
                                      <Link
                                        to={`/instant-offer-details/${item?.id}`}
                                        state={{ data: item?.car_post }}
                                      >
                                        <figure>
                                          {/* <figure><img src={ImageHolder} alt="" /></figure> */}
                                          {item?.car_post?.images.length > 0 ? (
                                            <img
                                              src={`${ImageUrl?.car_listing}/${item?.car_post?.images[0]?.image}`}
                                              className="car-img image img-fluid"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src={ImageHolder}
                                              className="car-img image img-fluid"
                                              alt=""
                                            />
                                          )}
                                        </figure>
                                      </Link>
                                    </div>
                                    <div class="order_content">
                                      <h5 class="order-name">
                                        {item?.car_post?.name}
                                      </h5>
                                      <p class="offer_type">
                                        Offer Type:{" "}
                                        <span class="type">{item?.type}</span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td class="offers">£ {item?.offer}</td>
                                {/* <td class="offer-status">Not Accepted</td> */}
                              </tr>
                            );
                          })}
                        </table>
                      ) : (
                        <>
                          <div className="notFound-container">
                            <img
                              src={notFound}
                              alt="not found"
                              className="image-fluid"
                            />
                            <p className="mt-4">No Record Found</p>
                          </div>
                        </>
                      )}
                     
                    </div>
                    
                  )}
                   <div className="pagination-container mt-5">
                        <ReactPaginate
                          previousLabel="<<"
                          nextLabel=">>"
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Sec End Here --> */}
      <Footer />
    </>
  );
}

export default MyOffers;
