import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { chatTemp, online, search, user } from "../../constant";
import "../../assets/css/Chat.css";
import { ramdomImage } from "../../constant/ConstantFunction";
import ChatUserSideBar from "../../components/Chat/ChatUserSideBar";
import AllMessages from "../../components/Chat/AllMessages";
import { useLocation } from "react-router-dom";
import {
  GetChatUserList,
  PostSelectedUserMessage,
  SendMessage,
} from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { pusher } from "../../index";
import {
  AllChatData,
  ClearCounterData,
  CounterData,
  setNewMessageData,
} from "../../redux/actions/AuthActions";
import Skeleton from "react-loading-skeleton";

function Chat() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const MessageRedux = useSelector((state) => state.AuthReducer.messages);
  const location = useLocation();
  const ParamData = location?.state?.data;
  const [allChatUser, setAllChatUser] = useState([]);
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [chatId, setChatId] = useState();
  const [selectChatPerson, setSelectChatPerson] = useState(null);
  const [userLoader, setUserLoader] = useState(false);
  const messagesEndRef = useRef(null);
  const [searchUser, setSearchUser] = useState("");

  const UserListHandler = (e) => {
    e.preventDefault();
    setUserLoader(true);
    GetChatUserList(searchUser, Token)
      .then((res) => {
        setAllChatUser(res?.data?.response?.data);
        setUserLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setUserLoader(false);
      });
  };

  useEffect(() => {
    setUserLoader(true);
    GetChatUserList(searchUser, Token)
      .then((res) => {
        setAllChatUser(res?.data?.response?.data);
        setUserLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setUserLoader(false);
      });
  }, []);

  const SelectedUserMesaagesHandler = () => {
    setChatId(selectChatPerson?.id);
    setSelectChatPerson(selectChatPerson);
    let data = {
      chat_id: selectChatPerson?.id ? selectChatPerson?.id : ParamData?.id,
    };
    // console.log(selectChatPerson?.id);
    // console.log(ParamData?.id);
    // console.log("nt", data);

    PostSelectedUserMessage(data, Token)
      .then((res) => {
        console.log("msg all", res);
        // profileMessagesData?.slice(index, 1);
        dispatch(AllChatData(res?.data?.response?.data.reverse()));
        setAllMessages(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    SelectedUserMesaagesHandler();
  }, [selectChatPerson]);

  const SendMessageHandler = (e) => {
    e.preventDefault();
    // if (e.key === 'Enter') {
    let data = {
      chat_id: selectChatPerson?.id ? selectChatPerson?.id : ParamData?.id,
      receiver_id:
        userData?.id == selectChatPerson?.sender?.id &&
        selectChatPerson.receiver?.id
          ? selectChatPerson?.receiver?.id
          : selectChatPerson?.sender?.id
          ? selectChatPerson?.sender?.id
          : userData?.id == ParamData?.sender?.id
          ? ParamData?.receiver?.id
          : ParamData?.sender?.id,
      message: message,
      type: "message",
      // sharable_id: ,
      // offer: ,
    };
    SendMessage(data, Token)
      .then((res) => {
        dispatch(setNewMessageData(res?.data?.response?.data));
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
        setMessage("");
      });
    // }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      SendMessageHandler(e);
    }
  };

  const channel = pusher.subscribe(`message.${userData?.id}`);
  useEffect(() => {
    channel.bind("App\\Events\\MessageEvent", (data) => {
      if (
        data?.msg &&
        (data?.msg?.chat_id === userData?.id) == selectChatPerson?.sender?.id &&
        selectChatPerson.receiver?.id
          ? selectChatPerson?.receiver?.id
          : selectChatPerson?.sender?.id
          ? selectChatPerson?.sender?.id
          : userData?.id == ParamData?.sender?.id
          ? ParamData?.receiver?.id
          : ParamData?.sender?.id
      ) {
        setAllMessages((prevMsg) => [data?.msg, ...prevMsg]);
        dispatch(setNewMessageData(data?.msg));
      }
    });
  }, []);

  useEffect(() => {
    dispatch(ClearCounterData());
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [MessageRedux, selectChatPerson]);

  return (
    <>
      <Header />
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Chat Sec Start Here --> */}
      <section className="chart_sec">
        <div className="container">
          <div className="title_strip">
            <h5>Chat</h5>
          </div>
          <div className="chattings">
            <div className="row">
              <div className="col-md-3">
                <div className="sidebar">
                  <div className="top-bar">
                    <div className="user">
                      <div className="img_box">
                        <figure>
                          <img
                            src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="name">
                        <h4>
                          {userData?.f_name} {userData?.l_name}
                        </h4>
                      </div>
                    </div>
                    <div className="search_box">
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                          />
                          <button
                            className="btn"
                            onClick={(e) => UserListHandler(e)}
                          >
                            <img src={search} />
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="filter-option">
                      <div className="form-group">
                        <select className="form-control">
                          <option>Recent Chats</option>
                          <option>Newly Chats</option>
                          <option>All Chats</option>
                        </select>
                      </div>
                    </div>
                    <div className="onlines_users">
                      {userLoader ? (
                        <>
                          <Skeleton count={5} />
                          <Skeleton count={5} />
                          <Skeleton count={5} />
                        </>
                      ) : (
                        <>
                          {allChatUser?.map((item, index) => {
                            console.log("kjkjkjk", item);
                            return (
                              <>
                                {userData?.id == item?.receiver?.id ? (
                                  <ChatUserSideBar
                                    item={item?.sender}
                                    allItem={item}
                                    index={index}
                                    setSelectChatPerson={setSelectChatPerson}
                                    // SelectedUserMesaagesHandler={SelectedUserMesaagesHandler}
                                  />
                                ) : (
                                  <ChatUserSideBar
                                    item={item?.receiver}
                                    allItem={item}
                                    index={index}
                                    setSelectChatPerson={setSelectChatPerson}
                                    // SelectedUserMesaagesHandler={SelectedUserMesaagesHandler}
                                  />
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                {selectChatPerson || ParamData ? (
                  <>
                    <div className="chating_user_detail">
                      <div className="online_chats">
                        <div className="img_box">
                          <figure>
                            <img
                              src={`${ImageUrl?.customer_image_url}/${
                                userData?.id == selectChatPerson?.sender?.id &&
                                selectChatPerson.receiver?.id
                                  ? selectChatPerson?.receiver?.image
                                  : selectChatPerson?.sender?.id
                                  ? selectChatPerson?.sender?.image
                                  : userData?.id == ParamData?.sender?.id
                                  ? ParamData?.receiver?.image
                                  : ParamData?.sender?.image
                                // selectChatPerson
                                //   ? selectChatPerson?.sender?.image
                                //   : ParamData?.sender?.image
                              }`}
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="contentDv">
                          <div className="name">
                            <h4 className="user_name">
                              {userData?.id == selectChatPerson?.sender?.id &&
                              selectChatPerson.receiver?.id
                                ? selectChatPerson?.receiver?.f_name
                                : selectChatPerson?.sender?.id
                                ? selectChatPerson?.sender?.f_name
                                : userData?.id == ParamData?.sender?.id
                                ? ParamData?.receiver?.f_name
                                : ParamData?.sender?.f_name}{" "}
                              {userData?.id == selectChatPerson?.sender?.id &&
                              selectChatPerson.receiver?.id
                                ? selectChatPerson?.receiver?.l_name
                                : selectChatPerson?.sender?.id
                                ? selectChatPerson?.sender?.l_name
                                : userData?.id == ParamData?.sender?.id
                                ? ParamData?.receiver?.l_name
                                : ParamData?.sender?.l_name}
                              {/* {selectChatPerson?.sender?.id != userData?.id
                                ? selectChatPerson?.sender?.f_name : selectChatPerson?.receiver?.id != userData?.id ? selectChatPerson?.receiver?.f_name
                                : ParamData?.sender?.id != userData?.id ? ParamData?.sender?.f_name : ParamData?.receiver?.f_name } */}{" "}
                            </h4>
                            <p className="status">Online</p>
                          </div>
                        </div>
                      </div>
                      <AllMessages
                        allMessages={allMessages}
                        MessageRedux={MessageRedux}
                        messagesEndRef={messagesEndRef}
                      />
                      <div className="bottom_bar">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type something"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeypress}
                            // onKeyDown={(e)=>handleKeypress(e)}
                            // onKeyDown={(e)=>SendMessageHandler(e)}
                          />
                        </div>
                        <div className="button-group">
                          <button
                            className="btn send_btn"
                            onClick={(e) => SendMessageHandler(e)}
                          >
                            <i
                              className="fa fa-location-arrow"
                              aria-hidden="true"
                            ></i>
                          </button>
                          {/* <button className="btn more_option">+</button> */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="notFound-table">Please select user</p>
                    <div>
                      <img
                        src={chatTemp}
                        alt=""
                        className="image-fluid image-width"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Chat Sec End Here --> */}

      <Footer />
    </>
  );
}

export default Chat;
