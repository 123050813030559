import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { carshow1, ImageHolder } from '../../constant'

function MonthTopClickCard(props) {
    const {item,index} = props;
    const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  return (
   <>

                <div className="carShow wow animate__animated animate__bounceInLeft">
                <Link to={`/product-details/${item?.user_post?.id}`} state={{data: item?.user_post }} 
>
                    <div className="img-box">
                        <figure>
                            {item?.user_post?.images.length > 0 ? (
                                 <img src={`${ImageUrl?.car_listing}/${item?.user_post?.images[0]?.image}`} alt="" />
                            ) : (
                                <img src={ImageHolder} alt="" />
                            )}
                        </figure>
                    </div>
                    <div className="contentDv">
                        <h4>{item?.user_post?.car_make?.title} - {item?.user_post?.model?.title} </h4>
                        <p>
                            Phone No.{" "}{item?.user_post?.phone_no}
                        </p>
                        <p>Year{" "}{item?.user_post?.year}</p>
                        <div className="price">
                            <p>${item?.user_post?.price}</p>
                        </div>
                    </div>
                    </Link>
                </div>
   </>
  )
}

export default MonthTopClickCard