import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
  banner,
  carShowBanner,
  eventDetails,
  notFound,
  plybtn,
} from "../../constant";
import "../../assets/css/Events.css";
import { GetAllEvents, PostEventReservation } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AllEvents } from "../../redux/actions/AuthActions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import MapChart from "../../components/Map/MapChart";
import { SpinnerCircular } from "spinners-react";
import EventFormModal from "../../components/Modal/EventFormModal";

function CarShow() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const EventDatRedux = useSelector((state) => state.AuthReducer.eventData);
  const eventPageData = useSelector((state) => state.AuthReducer.pagesData);
  const [spinLoad, setSpinLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectState, setSelectState] = useState("");
  const [eventData, setEventData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);


  const [propData, setPropData] = useState();
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  const [zip, setZip] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [address, setAddress] = useState();
  const [sendCord, setSendCord] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const EventReservation = (e, item) => {
    setLoading(true);
    e.preventDefault();
    let data = {
      event_id: item?.id,
      status: item?.status === 1 ? 0 : 1,
    };
    PostEventReservation(data, Token)
      .then((res) => {
        setLoading(false);
        toast.success("Successfully Reserve");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response?.data?.errors);
        toast?.error(
          err?.response?.data?.errors == "Record Already Exits"
            ? "Event Already Reserved"
            : err?.response?.data?.errors
        );
      });
  };

  // Get Events
  useEffect(() => {
    setSpinLoad(true);
    GetAllEvents(selectState, currentPage)
      .then((res) => {
        setEventData(res?.data?.response?.data?.data);
        //    dispatch(AllEvents(res?.data?.response?.data?.data))
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        setSpinLoad(false);
      });
  }, [currentPage, selectState]);

  const ListEventMoadl = (e) =>{
    e.preventDefault()
    setIsOpenModal(true)
    // if(Token){
    //   setIsOpenModal(true)
    // }
    // else{
    //   toast.error("Please Login")
    // }
  }




  return (
    <>
      <Header />
      {/* <!-- banner start here --> */}
      <section className="Slidebanner">
        <div className="banner">
          <div className="slides">
            <div className="img-box">
              <figure>
                <img src={carShowBanner} alt="123" />
              </figure>
            </div>
            <div className="ply-btn">
              <figure>
                <a href="#">
                  <img src={plybtn} alt="123" />
                </a>
              </figure>
            </div>
            <div className="title">
              <h2>
                Lamborghini
                <br /> Showcase
              </h2>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner ends here --> */}

      {/* <!-- event list bg starts here --> */}
      <section className="event-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="list-you-event text-center text-white">
              {eventPageData?.map((item, index) => {
                return (
                  <>
                    {item?.type === "event_page" ? (
                      <>
                        <p
                          dangerouslySetInnerHTML={{ __html: item?.value }}
                          key={index}
                        />
                      </>
                    ) : null}
                  </>
                );
              })}
                {/* <h2>
                  lIST ANY AUTOMOTIVE RIGHT HERE ON THE CAR <br /> SHOW HERO
                  CALENDAR - COMPLETELY FOR FREE!
                </h2>
                <p>
                  JUST FILL OUT THE FORM TO GET YOUR CAR SHOW, AUTO RALLY, SWAP
                  MEET, OR RACE NIGHT INTO OUR DATABASE.
                </p> */}
                <div className="list-mdal-btn">
                  <p onClick={(e)=>ListEventMoadl(e)}>List Your Event</p>
                </div>
                
              </div>
              {/* <div className="event-partners pt-5">
                <div className="partners-name">
                  <h3 className="cool-link">Car Show</h3>
                </div>
                <div className="partners-name">
                  <h3 className="cool-link">Races</h3>
                </div>
                <div className="partners-name">
                  <h3 className="cool-link">Rallies</h3>
                </div>
                <div className="partners-name">
                  <h3 className="cool-link">Auctions</h3>
                </div>
                <div className="partners-name">
                  <h3 className="cool-link">Meets</h3>
                </div>
              </div> */}
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      {/* <!-- event list bg starts here --> */}

      {/* <!-- Events section start here --> */}
      <section className="events">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="event-content text-center pb-2">
                <h2>FIND YOUR EVENT - CLICK ON A STATE</h2>
              </div>
              <MapChart
                setSelectState={setSelectState}
                selectState={selectState}
              />
            </div>
            {selectState ? (
              <p className="state-p">
                Selected State : {""}
                {selectState}
              </p>
            ) : (
              <p className="state-p">Please Select Your State</p>
            )}
          </div>

          <h2 className="eventlist">EVENT LISTINGS</h2>

          <div className="reset-cont">
            <button className="eventlist reset-btn" 
              onClick={()=>setSelectState("")}
            >Reset</button>
          </div>

          {spinLoad ? (
            <div className="loader-container">
              <SpinnerCircular size="80px" color="#d60012" />
            </div>
          ) : (
            <>
              {eventData?.length > 0 ? (
                <>
                  {eventData?.map((item, index) => {
                    return (
                      <div className="row align-items-center mt-5" key={index}>
                        <div className="col-md-5">
                          <div className="event-details-img">
                            <img
                              src={
                                item?.icon_full_url
                              }
                              className="img-fluid w-100"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="contentDv">
                            <div className="title">
                              <h3>{item?.title}</h3>
                            </div>
                            <div className="details">
                              <p>
                                Location: {""}
                                {item?.address}
                              </p>
                              <p>
                                State: {""}
                                {item?.state}
                              </p>
                              <p>
                                Contact: {""}
                                {item?.contact}
                              </p>
                              <p>
                                Link: {""}
                                {item?.link ? (
                                  <a href={item?.link} target="_blank">
                                    {item?.link}
                                  </a>
                                ) : (
                                  "---"
                                )}
                              </p>
                              <p>
                                Date:{" "}
                                {moment(item?.date).format("MMMM Do YYYY")}
                                {/* {moment(item?.date,"MMMM Do YYYY").format('LL')} */}
                              </p>
                              <p>
                                Time:{" "}
                                {moment(item?.time, "hh:mm:ss a").format("LT")}
                              </p>
                              {/* {moment(item?.time,"hh:mm:ss").format()} */}
                            </div>
                            <div className="description">
                              <h5>Discription:</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              />
                            </div>
                            {/* <button className="btn"
                                        onClick={(e)=>EventReservation(e,item)}
                                    >Make Reservation</button> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="notFound-container">
                  <img src={notFound} alt="not found" className="image-fluid" />
                  <p className="mt-4">No Record Found</p>
                </div>
              )}
            </>
          )}

          <div className="pagination-container mt-5">
            <ReactPaginate
              previousLabel="<<"
              nextLabel=">>"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </section>
      {/* <!-- Events section start here --> */}

      <Footer />
      <EventFormModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
      />
    </>
  );
}

export default CarShow;
