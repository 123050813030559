import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import SideBar from '../../components/SideBar/SideBar';
import { plus, uploadImg } from '../../constant';
import { ramdomImage } from '../../constant/ConstantFunction';


function PostAds() {
  return (
   <>
    <Header />
<section className="universal-Banner cart-banner">
    <div className="container">
        <div className="un-ban">
            <div className="heading">
                <h1 className="text-white">John Doe</h1>
                <p className="subtitle text-white">Profile</p>
            </div>
            <div className="banner-img">
                <img
                    src={`${ramdomImage("John Doe")}`}
                        className="image-width"
                        style={{ borderRadius: "50%" }}
                />
                {/* <img src="img/profile-img.png" alt="" />  */}
            </div>
        </div>
    </div>
</section>

<section className="profile_sec">
    <div className="container">
        <div className="row">
            <div className="col-lg-3">
               <SideBar />
            </div>
            <div className="col-lg-9">
                <div className="tab-content" >
                <div className="tab-pane fade show active" >
                        <div className="profile_strip">
                            <div className="heading_wrapper">
                                <h5>Post an Ads</h5>
                            </div>
                          
                        </div>
                        <div className="car_informations">
                            <div className="title_wrapper">
                                <h4>1. Car Information</h4>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="Condition">Condition</label>
                                            <select id="Condition" className="form-control">
                                                <option selected="">Select Condition</option>
                                                <option>New</option>
                                                <option>Used</option>
                                                <option>Manufacturer Certified</option>
                                                <option>Third Party Certified</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="Make">Make</label>
                                            {/* <!-- <select id="Make" className="form-control">
                                                <option selected>Make</option>
                                                <option>ACURA</option>
                                                <option>AUDI</option>
                                                <option>BMW</option>
                                                <option>CADILLAC</option>
                                                <option>CHEVROLET</option>
                                            </select> --> */}
                                            <select className="countries form-control" id="Make">
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="Style">Style</label>
                                            <select id="Style" className="form-control">
                                                <option selected>Style</option>
                                                <option>USA Muscle</option>
                                                <option>classNameic</option>
                                                <option>Jeep Trucks</option>
                                                <option>Hot Rod Custom</option>
                                                <option>Exotic Euro</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="Model">Model</label>
                                            {/* <!-- <select id="Model" className="form-control">
                                                <option selected>Model</option>
                                                <option>NSX / TLX Type S</option>
                                                <option>TLX / ILX</option>
                                                <option>A5 coupe</option>
                                                <option>A5 cabriolet</option>
                                                <option>RS 5 coupe</option>
                                            </select> --> */}
                                            <div className="option">
                                                <select className="FERRARI form-control">

                                                </select>
                                                <select className="FORD form-control">

                                                </select>
                                                <select className="GMC form-control">

                                                </select>
                                                <select className="BMW form-control">

                                                </select>
                                                <select className="OTHERS form-control">

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="btype">B.Type</label>
                                            <select id="btype" className="form-control">
                                                <option>Cars</option>
                                                <option>SUV</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="year">Year</label>
                                            <input 
                                                type="text" 
                                                placeholder="Year" 
                                                className="form-control" id="year"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="mileage">Mileage</label>
                                            <input placeholder="2000km" id="mileage" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="dtype">Drive Type</label>
                                            <select id="dtype" className="form-control">
                                                <option selected>Select Drive Type</option>
                                                <option>AWD</option>
                                                <option>FWD</option>
                                                <option>RWD</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="transmission">Transmission</label>
                                            <select id="transmission" className="form-control">
                                                <option selected>Select Transmission</option>
                                                <option>Automatic</option>
                                                <option>Mannual</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="fuelType">Fuel Type</label>
                                            <select id="fuelType" className="form-control">
                                                <option selected>Fuel Type</option>
                                                <option>Petrol</option>
                                                <option>Hybrid</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="Color">Color</label>
                                            <input 
                                                type="color" 
                                                className="form-control" 
                                                id="Color"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="featuresDv">
                                    <div className="title">
                                        <h4>Features</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h5>Exterior Features</h5>
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox" 
                                                    id="keyless" 
                                                    className="forom-control"
                                                />
                                                <label for="keyless">Keyless Enrty</label>
                                            </div>
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox" 
                                                    id="wheels" 
                                                    className="forom-control"
                                                />
                                                <label for="wheels">Premium Wheels</label>
                                            </div>
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox" 
                                                    id="rockrack" 
                                                    className="forom-control"
                                                />
                                                <label for="rockrack">Roof Rack</label>
                                            </div>
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox" 
                                                    id="hitch" 
                                                    className="forom-control"
                                                />
                                                <label for="hitch">Trailer Hitch</label>
                                            </div>
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox" 
                                                    id="frontcamera" 
                                                    className="forom-control"
                                                />
                                                <label for="frontcamera">Front Camera</label>
                                            </div>
                                            <div className="form-group">
                                                <input 
                                                    type="checkbox" id="keyless2" className="forom-control" 
                                                />
                                                <label for="keyless2">Keyless Enrty</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="power_mirror" className="forom-control" />
                                                <label for="power_mirror">Power Mirrors</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="rearseat" className="forom-control" />
                                                <label for="rearseat">Rear Seat Entertainment</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="rarecamera" className="forom-control" />
                                                <label for="rarecamera">Rear Camera</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="usbcabel" className="forom-control" />
                                                <label for="usbcabel">USB and Auxiliary Cable</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <h5>Technology Features</h5>
                                            <div className="form-group">
                                                <input type="checkbox" id="cruise_control" className="forom-control" />
                                                <label for="cruise_control">Adaptive Cruise Control</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="android" className="forom-control" />
                                                <label for="android">Android Auto</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="applecar" className="forom-control" />
                                                <label for="applecar">Apple Car Play</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="automatic-parking" className="forom-control" />
                                                <label for="automatic-parking">Automatic Parking</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="bluetooth" className="forom-control" />
                                                <label for="bluetooth">Bluetooth, Hands-Free</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="cruise" className="forom-control" />
                                                <label for="cruise">Cruise Control</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="dvdplayer" className="forom-control" />
                                                <label for="dvdplayer">DVD Player</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="keyless_start" className="forom-control" />
                                                <label for="keyless_start">Keyless Start</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="navigation" className="forom-control" />
                                                <label for="navigation">Navigation</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="portable_audio" className="forom-control" />
                                                <label for="portable_audio">Portable Audio Connection</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <h5 className="op">Technology Features</h5>
                                            <div className="form-group">
                                                <input type="checkbox" id="paudio" className="forom-control" />
                                                <label for="paudio">Premium Audio</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="rain" className="forom-control" />
                                                <label for="rain">Rain Sensing Wispers</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="remote_start" className="forom-control" />
                                                <label for="remote_start">Remote Engine Start</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="sradio" className="forom-control" />
                                                <label for="sradio">Satellite Radio</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="sterring_wheel" className="forom-control" />
                                                <label for="sterring_wheel">Steering Wheel Controls</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="wifihotspot" className="forom-control" />
                                                <label for="wifihotspot">Wifi Hotspot</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <h5>Safty Features</h5>
                                            <div className="form-group">
                                                <input type="checkbox" id="backup_camera" className="forom-control" />
                                                <label for="backup_camera">Backup Camera</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="blind_spot" className="forom-control" />
                                                <label for="blind_spot">Blind Spot Monitor</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="brake_assist" className="forom-control" />
                                                <label for="brake_assist">Brake Assist</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="lane_warning" className="forom-control" />
                                                <label for="lane_warning">Lane Departure Warning</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="sercurity_system" className="forom-control" />
                                                <label for="sercurity_system">Security System</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <h5>Interior Features</h5>
                                            <div className="form-group">
                                                <input type="checkbox" id="heated_seat" className="forom-control" />
                                                <label for="heated_seat">Heated Seats</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="leather_seat" className="forom-control" />
                                                <label for="leather_seat">Leather Seats</label>
                                            </div>
                                            <div className="form-group">
                                                <input type="checkbox" id="sun_roof" className="forom-control" />
                                                <label for="sun_roof">Sunroof</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="upload_photos_box">
                                    <div className="title">
                                        <h5>Upload Photos</h5>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="files_flex">
                                                <div className="imgBox">
                                                    <figure><img src={uploadImg} alt="" /></figure>
                                                </div>
                                                <div className="custom-file">
                                                    <button type="button"> <img className="mr-2" src={plus} alt="" />
                                                        Add
                                                        Photos</button><br />
                                                    <span className="note">(Max limit 5MB per image)</span>
                                                    <input type="file" className="custom-file-input" id="attachments"
                                                        multiple onchange="javascript:updateList()" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="importants_points">
                                                <li><i className="fa fa-check-circle" aria-hidden="true"></i> Add at least 8
                                                    pictures</li>
                                                <li><i className="fa fa-check-circle" aria-hidden="true"></i> Add clear
                                                    front, back and Interior pictures</li>
                                                <li><i className="fa fa-check-circle" aria-hidden="true"></i> Photos should
                                                    be in "jpeg, jpg, png" Format only.</li>
                                            </ul>
                                        </div>
                                        <ul id="fileList" className="file-list"></ul>
                                    </div>
                                </div>
                                <div className="ada_price">
                                    <div className="title_wrapper">
                                        <h4>2. Price</h4>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="price">Price</label>
                                                <input id="price" type="number" className="form-control" placeholder="200" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="instant_offer">Instant Offer</label>
                                                <input id="instant_offer" type="number" className="form-control"
                                                    placeholder="100" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group forcheck">
                                                <input type="checkbox" id="checkbx" className="form-control" />
                                                <label for="checkbx">Lorem Ipsum</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="contact_btn btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact_informations">
                                    <div className="title_wrapper">
                                        <h4>3. Contact Information</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="user_id">User ID</label>
                                                <input id="user_id" type="text" className="form-control"
                                                    placeholder="Ipsum" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="phone">Phone</label>
                                                <input id="phone" type="tel" className="form-control"
                                                    placeholder="+1 234 567 890" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="email">Email</label>
                                                <input id="email" type="email" className="form-control"
                                                    placeholder="name@email.com" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="address">Address</label>
                                                <input id="address" type="text" className="form-control"
                                                    placeholder="Lorem Ipsum dolar sit amet" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="contact_btn btn">Post</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>

    <Footer />
   </>
  )
}

export default PostAds