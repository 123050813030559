import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import '../../assets/css/Forgot.css'

function ForgotPasswordOtp() {
    const Navigate = useNavigate()
    const Location =  useLocation()
    return (
   <>
    <Header />
    <section className="security-code py-5">
    <div className="container py-5">
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="forget-pass-security-code-form-content text-center w-75 mx-auto">
                    <h2 className="pb-2">Forgot password</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout.</p>
                </div>
                <div className="forget-pass-secursity-code-form pt-4">
                    <form action="">
                        <div className="form-group text-center">
                            <label className="pb-2">A code has been sent to your email</label>
                            <ul className="security-code-fields">
                                <li><input type="number" className="form-control" /></li>
                                <li><input type="number" className="form-control" /></li>
                                <li><input type="number" className="form-control" /></li>
                                <li><input type="number" className="form-control" /></li>
                            </ul>
                        </div>
                        <div className="form-group pt-4">
                            <input 
                                type="submit" 
                                className="form-control offset-3 form-submit col-sm-6" id="Nextsubmit"
                                aria-describedby="emailHelp" value="Next"
                                onClick={()=>Navigate('/new-password')}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
    </div>
</section>
    <Footer />
   </>
  )
}

export default ForgotPasswordOtp