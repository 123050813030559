import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { editAds, ImageHolder, notFound, offer1, offer2 } from "../../constant";
import { ramdomImage } from "../../constant/ConstantFunction";
import {
  DeleteWishList,
  GetAllFavourite,
  GetWishList,
} from "../../network/Network";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import {
  RemoveWishListData,
  WishListData,
} from "../../redux/actions/AuthActions";
import { AddToCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";

function WishlistTshirt() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [tshirtData, setTshirtData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  // Get tshirt WishList
  useEffect(() => {
    setSpinLoad(true);
    GetWishList(currentPage, Token)
      .then((res) => {
        console.log(res);
        setSpinLoad(false);
        setTshirtData(res?.data?.data);
        // dispatch(WishListData(res?.data));
        // const total = res?.data?.data?.wishlist?.total;
        // const limit = res?.data?.data?.wishlist?.per_page;
        // setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteWishListHandler = (id, index) => {
    console.log(id);
    setSpinLoad(true);
    DeleteWishList(id, Token)
      .then((res) => {
        console.log(res?.data?.data);
        setSpinLoad(false);
        setTshirtData((preval)=>preval.filter(val=>val?.product?.id != id));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  };

//   const AddToCartHandler = (item) => {
//     let checkItemAlreadyExist = allStates.filter((val) => val?.id === item?.id);
//     if (checkItemAlreadyExist?.length > 0) {
//       alert("Item Already Exist in Cart!!!");
//     } else {
//       let colorData = JSON.parse(paramData?.colors);
//       let data = {
//         id: item?.product?.id,
//         price: item?.product?.unit_price,
//         quantity: 1,
//         color: color ? color : colorData,
//         size: sizeData,
//         productitem: item,

//         let data = {
//             id: item?.product?.id,
//             price: item?.product?.unit_price,
//             quantity: 1,
//             color: colorData[0],
//             productitem: item?.product,
//           };
//       };
//       dispatch(AddToCart(data));
//       toast.success("Product Successfully Add ");
//     }
//   };


  return (
    <>
      <Header />
      {/* <!-- Universal Banner Start Here --> */}
      <section className="universal-Banner cart-banner">
        <div className="container">
          <div className="un-ban">
            <div className="heading">
              <h1 className="text-white">
                {userData?.f_name} {userData?.l_name}
              </h1>
              {/* <p className="subtitle text-white">Profile</p> */}
            </div>
            <div className="banner-img">
              {userData?.image == "def.png" ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.f_name}{""}${userData?.l_name}`
                  )}`}
                  className="image-width"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                  className="image-width"
                  style={{ height: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Universal Banner End Here --> */}

      <section className="profile_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBar />
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  <div className="profile_strip">
                    <div className="heading_wrapper">
                      <h5>Favourite T-shirts</h5>
                    </div>
                  </div>

                  {spinLoad ? (
                    <div className="loader-container">
                      <SpinnerCircular size="80px" color="#d60012" />
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        {tshirtData?.length > 0 ? (
                          <>
                            <table className="table wishlist_table">
                              <tr className="topbar">
                                <th className="sno">S.NO</th>
                                <th className="product">PRODUCT</th>
                                <th className="offers">Price</th>
                                {/* <th className="add_cart">Add to Cart</th> */}
                                <th className="add_cart">Remove</th>
                              </tr>

                              {tshirtData?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="sno">{index + 1}</td>
                                    <td className="product">
                                      <div className="order_product">
                                        <div className="img_box">
                                        <Link to={`/merchandise-product-details/${item?.product?.id}`}>
                                            <figure>
                                              {item?.product?.thumbnail ? (
                                                <img
                                                  src={`${ImageUrl?.product_thumbnail_url}/${item?.product?.thumbnail}`}
                                                  alt=""
                                                />
                                              ) : (
                                                <img src={ImageHolder} alt="" />
                                              )}
                                            </figure>
                                          </Link>
                                        </div>
                                        <div className="order_content">
                                          <h5 className="order-name">
                                            {item?.product?.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="offers">
                                      ${item?.product?.unit_price}
                                    </td>
                                    {/* <td className="add_cart">
                                      <a href="#" className="cart_btn">
                                        Add to Cart
                                      </a>
                                    </td> */}
                                    <td className="add_cart">
                                      <div
                                        className="deleteBtn"
                                        onClick={() =>
                                          DeleteWishListHandler(
                                            item?.product?.id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}

                              {/* <tr>
                                    <td className="sno">2</td>
                                    <td className="product">
                                        <div className="order_product">
                                            <div className="img_box">
                                                <figure><img src={offer2} alt="" /></figure>
                                            </div>
                                            <div className="order_content">
                                                <h5 className="order-name">Black Shirt</h5>
                                                <p className="order_type">Offer Type: <span className="type">Instant
                                                        Offer</span></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="offers">£ 60</td>
                                    <td className="add_cart">
                                        <a href="#" className="cart_btn">Add to Cart</a>
                                    </td>
                                </tr> */}
                            </table>
                          </>
                        ) : (
                          <div className="notFound-container">
                              <img
                                src={notFound}
                                alt="not found"
                                className="image-fluid"
                              />
                              <p className="mt-4">No Record Found</p>
                            </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="pagination-container mt-5">
                    <ReactPaginate
                      previousLabel="<<"
                      nextLabel=">>"
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default WishlistTshirt;
