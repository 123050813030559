import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { garage2, lines, merch, merchant1, merchantNew,cs } from "../../constant";

function GarageBanner(props) {
  const { productsData } = props;
  const Navigate = useNavigate();

  return (
    <>
      <section className="GarageTee py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 my-auto">
              <div className="GarageTee-content wow animate__animated animate__zoomIn">
                <div className="logoDv">
                  <figure>
                    <img className="img-fluid" src={merchant1} alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="heading">
                <h5>NEW DESIGN AVAILABLE HERE</h5>
              </div>
              <img src={cs} className="img-fluid comming-img" alt="" />
              <div className="button-group">
                {/* <Link to="/merchandise-products"> */}
                <Link
                  to={`/merchandise-product-details/${productsData[0]?.id}`}
                >
                  T-Shirt info
                  <img class="img-fluid" src={lines} alt="" />
                </Link>
              </div>
              <h5>T-SHIRT DESIGNS CHANGE MONTHLY</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GarageBanner;
