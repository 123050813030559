import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

function EditCarPostModal(props) {
  const { 
      setIsOpenModal, isOpenModal, selectedData, setSelectedData,
      phone, setPhone, address, setAddress, description, setDescription, title, setTitle,EditPostCarListHandler,
      loading, setLoading,
 } = props;
  const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
  // console.log(selectedDasta);
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(false);
        }}
        className="custom-modal modal-width orderView-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="modal-close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 style={{ textAlign: "center" }}>Edit Vehicle Post</h2>
        <div className="row edit-post">
          <div className="col-md-12 mt-2">
            <div className="form-group">
              <label for="phone">Title</label>
              <input
                id="phone"
                type="text"
                className="form-control"
                placeholder="Title"
                style={{ width: "100%" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 mt-2">
            <div className="form-group">
              <label for="phone">Description</label>
              <textarea
                id="desc"
                type="text"
                className="form-control"
                placeholder="Description"
                style={{ width: "100%", height: "150px" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="form-group">
              <label for="phone">Address</label>
              <input
                id="phone"
                type="text"
                className="form-control"
                placeholder="Address"
                style={{ width: "100%" }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="form-group">
              <label for="phone">Phone</label>
              <input
                id="phone"
                type="number"
                className="form-control"
                placeholder="+1 234 567 890"
                style={{ width: "100%" }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 mt-2">
              <button className="btn save-btn" 
                onClick={(e)=>EditPostCarListHandler(e)}
                disabled={loading}
              >
                {loading ? "Loading.."  : "Save" }
              </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditCarPostModal;
