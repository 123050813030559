import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ImageHolder } from "../../constant";

function AdsImageSlider(props) {
    const ImageUrl = useSelector((state) => state.AuthReducer.imageUrl);
    const {ParamData} = props
    console.log(ParamData)
  const settings = {
    // arrows: false,
    // dots: false,
    // infinite: true,
    // fade: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: false,
    // autoplaySpeed: 3000,
    // nextArrow: false,
    // prevArrow: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const BannerData = [
    {
      id: 1,
      img: ImageHolder,
    },
    {
      id: 2,
      img: ImageHolder,
    },
    {
      id: 3,
      img: ImageHolder,
    },
  ];
  return (
    <>
      <div className="addBanner-container mt-2 mb-4">
            {
                ParamData?.images.length > 0 ? (
                    <>
                        <Slider {...settings}>
                        {ParamData?.images?.map((item, index) => {
                            return (
                            <div className="banner-slider" key={index}>
                                <img src={`${ImageUrl?.car_listing}/${item?.image}`} className="image-fluid image-width ad-img" alt="" />
                            </div>
                        );
                        })}
                        </Slider>
                    </>
                ) : (
                    <>
                        <Slider {...settings}>
                        {BannerData?.map((item, index) => {
                            return (
                            <div className="banner-slider-add" key={index}>
                                <img src={item?.img} className="image-fluid image-width ad-img" alt="" />
                            </div>
                        );
                        })}
                         </Slider>
                    </>
                )
            }
         

       

      </div>
    </>
  );
}

export default AdsImageSlider;
